import { User } from '@/projectApi/AccountManager/Users/common'
import { VolumeDiscountPolicy } from '@/projectApi/TransactionHandler/Discounts/volume/policies/common'

export type Assignment = {
  id?: number
  name?: string
  active: boolean
  usuario?: {
    id: number
    fullName: string
  }
}

export type ModalsAssignmentsState = {
  activationModalVisible: boolean
  updatingAssignment: boolean
  currentAssignment: Assignment
  discounts: VolumeDiscountPolicy[] | null
  selectedDiscount: { label: string; value: number } | null
  fetchingDiscounts: boolean
  fetchingUser: boolean
  user: User | null
  modalVisible: boolean
  userId: string
  assigningUserDiscount: boolean
  assignUserDiscountModalVisible: boolean
  error: string
}

export enum ModalsAssignmentsTypes {
  SET_ACTIVATION_MODAL_VISIBILITY = 'DISCOUNTS/POLICIES/SET_ACTIVATION_MODAL_VISIBILITY',
  SET_CURRENT_ASSIGNMENT = 'DISCOUNTS/POLICIES/SET_CURRENT_ASSIGNMENT'
}
export interface SetActivationModalVisibility {
  type: typeof ModalsAssignmentsTypes.SET_ACTIVATION_MODAL_VISIBILITY
  payload: { activationModalVisible: boolean }
}

export interface SetCurrentAssignment {
  type: typeof ModalsAssignmentsTypes.SET_CURRENT_ASSIGNMENT
  payload: { currentAssignment: Assignment }
}

type AssignmentsActions = SetActivationModalVisibility | SetCurrentAssignment

export enum UpdateAssignmentType {
  UPDATE_ASSIGNMENT_REQUEST = 'DISCOUNTS/ASSIGNMENTS/UPDATE_ASSIGNMENT_REQUEST',
  UPDATE_ASSIGNMENT_SUCCESS = 'DISCOUNTS/ASSIGNMENTS/UPDATE_ASSIGNMENT_SUCCESS',
  UPDATE_ASSIGNMENT_FAILURE = 'DISCOUNTS/ASSIGNMENTS/UPDATE_ASSIGNMENT_FAILURE'
}

export interface UpdateAssignmentRequest {
  type: typeof UpdateAssignmentType.UPDATE_ASSIGNMENT_REQUEST
}

export interface UpdateAssignmentSuccess {
  type: typeof UpdateAssignmentType.UPDATE_ASSIGNMENT_SUCCESS
}

export interface UpdateAssignmentFailure {
  type: typeof UpdateAssignmentType.UPDATE_ASSIGNMENT_FAILURE
}

type UpdateAssignmentActions = UpdateAssignmentRequest | UpdateAssignmentSuccess | UpdateAssignmentFailure

export enum ModalAssignUserDiscountTypes {
  SET_MODAL_ASSIGN_USER_VISIBILITY = 'DISCOUNTS/ASSIGNMENTS/SET_MODAL_ASSIGN_USER_VISIBILITY',
  SET_USER_ID = 'DISCOUNTS/ASSIGNMENTS/SET_USER_ID',
  SET_DISCOUNT = 'DISCOUNTS/ASSIGNMENTS/SET_DISCOUNT'
}
export interface SetModalAssignUserVisibility {
  type: typeof ModalAssignUserDiscountTypes.SET_MODAL_ASSIGN_USER_VISIBILITY
  payload: { modalVisible: boolean }
}

export interface SetUserId {
  type: typeof ModalAssignUserDiscountTypes.SET_USER_ID
  payload: { userId: string }
}

export interface SetSelectedDiscount {
  type: typeof ModalAssignUserDiscountTypes.SET_DISCOUNT
  payload: { selectedDiscount: { label: string; value: number } }
}

type ModalAssignUserActions = SetModalAssignUserVisibility | SetUserId | SetSelectedDiscount

export enum FetchingDiscountsType {
  FETCHING_DISCOUNTS_REQUEST = 'DISCOUNTS/ASSIGNMENTS/MODAL/ASSIGN_DISCOUNTS_REQUEST',
  FETCHING_DISCOUNTS_SUCCESS = 'DISCOUNTS/ASSIGNMENTS/MODAL/ASSIGN_DISCOUNTS_SUCCESS',
  FETCHING_DISCOUNTS_FAILURE = 'DISCOUNTS/ASSIGNMENTS/MODAL/ASSIGN_DISCOUNTS_FAILURE'
}

export interface FetchingDiscountsRequest {
  type: typeof FetchingDiscountsType.FETCHING_DISCOUNTS_REQUEST
}

export interface FetchingDiscountsSuccess {
  type: typeof FetchingDiscountsType.FETCHING_DISCOUNTS_SUCCESS
  payload: {
    discounts: VolumeDiscountPolicy[] | null
  }
}

export interface FetchingDiscountsFailure {
  type: typeof FetchingDiscountsType.FETCHING_DISCOUNTS_FAILURE
}

type FetchingDiscountsActions = FetchingDiscountsRequest | FetchingDiscountsSuccess | FetchingDiscountsFailure

export enum FetchingUserType {
  FETCHING_USER_REQUEST = 'DISCOUNTS/ASSIGNMENTS/MODAL/FETCHING_USER_REQUEST',
  FETCHING_USER_SUCCESS = 'DISCOUNTS/ASSIGNMENTS/MODAL/FETCHING_USER_SUCCESS',
  FETCHING_USER_FAILURE = 'DISCOUNTS/ASSIGNMENTS/MODAL/FETCHING_USER_FAILURE'
}

export interface FetchingUserRequest {
  type: typeof FetchingUserType.FETCHING_USER_REQUEST
}

export interface FetchingUserSuccess {
  type: typeof FetchingUserType.FETCHING_USER_SUCCESS
  payload: {
    user: User
  }
}

export interface FetchingUserFailure {
  type: typeof FetchingUserType.FETCHING_USER_FAILURE
}

type FetchingUserActions = FetchingUserRequest | FetchingUserSuccess | FetchingUserFailure

export enum AssignUserDiscountTypes {
  ASSIGN_USER_DISCOUNT_REQUEST = 'DISCOUNTS/ASSIGNMENTS/MODAL/ASSIGN_USER_DISCOUNT_REQUEST',
  ASSIGN_USER_DISCOUNT_SUCCESS = 'DISCOUNTS/ASSIGNMENTS/MODAL/ASSIGN_USER_DISCOUNT_SUCCESS',
  ASSIGN_USER_DISCOUNT_FAILURE = 'DISCOUNTS/ASSIGNMENTS/MODAL/ASSIGN_USER_DISCOUNT_FAILURE'
}

export interface AssignUserDiscountRequest {
  type: typeof AssignUserDiscountTypes.ASSIGN_USER_DISCOUNT_REQUEST
}

export interface AssignUserDiscountSuccess {
  type: typeof AssignUserDiscountTypes.ASSIGN_USER_DISCOUNT_SUCCESS
}

export interface AssignUserDiscountFailure {
  type: typeof AssignUserDiscountTypes.ASSIGN_USER_DISCOUNT_FAILURE
}

type AssignUserDiscountActions = AssignUserDiscountRequest | AssignUserDiscountSuccess | AssignUserDiscountFailure

export type ModalsAssignmentsActions =
  | AssignmentsActions
  | UpdateAssignmentActions
  | ModalAssignUserActions
  | FetchingDiscountsActions
  | FetchingUserActions
  | AssignUserDiscountActions
