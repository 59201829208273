import { AxiosError } from 'axios'
import { apiPrivate } from '../../api'
import { URL_BASE_USERS } from '../../endpoints'

export function resetPassword(userId: string): Promise<void> {
  return apiPrivate
    .put(`${URL_BASE_USERS}/reset-password/${userId}`)
    .then(() => {})
    .catch((err: AxiosError) => {
      throw err
    })
}
