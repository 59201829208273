import React from 'react'
import { Modal } from 'antd'
import { Assignment } from '@/sections/Discounts/types/volume/assignments/modals'
import { useLocalization } from '@/packages/localization'

interface ActivationModalProps {
  currentAssignment: Assignment
  modalVisible: boolean
  disabledUpdateButton: boolean
  setModalVisibility: ({ activationModalVisible }: { activationModalVisible: boolean }) => void
  handleUpdateAssignment: () => void
}

const ActivationModal: React.FC<ActivationModalProps> = ({
  currentAssignment,
  modalVisible,
  disabledUpdateButton,
  setModalVisibility,
  handleUpdateAssignment
}) => {
  const { strings } = useLocalization()

  const genericStrings = strings.generic
  const modalStrings = strings.Discounts.Volume.Assignments.modal

  const title = `${
    currentAssignment.active ? genericStrings.deactivate : genericStrings.activate
  } ${modalStrings.activationTitle.toLowerCase()}`
  const okText = currentAssignment.active ? genericStrings.deactivate : genericStrings.activate

  const userInfo = `${currentAssignment.usuario?.id} - ${currentAssignment.usuario?.fullName}`
  const volumeDiscount = currentAssignment.name ?? '-'
  const toStateMessage = currentAssignment.active
    ? genericStrings.deactivate.toLowerCase()
    : genericStrings.activate.toLowerCase()

  return (
    <Modal
      title={title}
      okText={okText}
      okButtonProps={{ disabled: disabledUpdateButton }}
      onOk={handleUpdateAssignment}
      cancelText={genericStrings.cancel}
      visible={modalVisible}
      onCancel={() => setModalVisibility({ activationModalVisible: false })}>
      <p>{modalStrings.activationMessage({ toState: toStateMessage, userInfo, volumeDiscount })}</p>
    </Modal>
  )
}

export default ActivationModal
