import { API } from '@/projectApi'
import {
  GetVolumeDiscountPoliciesRequest,
  GetVolumeDiscountPoliciesSuccess,
  GetVolumeDiscountPoliciesTypes,
  PoliciesCategoryFilter,
  PoliciesSearchFilter
} from '@/sections/Discounts/types/volume/policies/table'
import { AppThunk } from '@/store'
import { handleErrorsWithAction } from '@/utils/HandleErrors'
import { Pagination, searchFiltersToParams } from '@/utils/searchFilterUtils'

const volumeDiscountPoliciesTableActionsCreator = {
  getDiscounts({
    pagination,
    searchFilters,
    categoryFilter
  }: {
    pagination: Pagination
    searchFilters?: PoliciesSearchFilter[]
    categoryFilter?: PoliciesCategoryFilter
  }): AppThunk {
    return async (dispatch) => {
      const request: GetVolumeDiscountPoliciesRequest = {
        type: GetVolumeDiscountPoliciesTypes.GET_VOLUME_DISCOUNT_POLICIES_REQUEST,
        payload: {
          pageSize: pagination.pageSize,
          newPage: pagination.page,
          searchFilters,
          categoryFilter
        }
      }
      dispatch(request)

      const isValidSearchFilter = categoryFilter?.isActive[0]

      try {
        const { volumeDiscountPolicies, total } = await API.TransactionHandler.Discounts.VolumeDiscounts.policies.list({
          limit: pagination.pageSize,
          offset: (pagination.page - 1) * pagination.pageSize,
          withItems: true,
          orderByColumn: 'id',
          direction: 'desc',
          isActive: isValidSearchFilter,
          ...searchFiltersToParams(searchFilters)
        })
        const success: GetVolumeDiscountPoliciesSuccess = {
          type: GetVolumeDiscountPoliciesTypes.GET_VOLUME_DISCOUNT_POLICIES_SUCCESS,
          payload: {
            volumeDiscountPolicies,
            total
          }
        }
        dispatch(success)
      } catch (error) {
        handleErrorsWithAction(error, GetVolumeDiscountPoliciesTypes.GET_VOLUME_DISCOUNT_POLICIES_FAILURE, dispatch)
      }
    }
  }
}

export default volumeDiscountPoliciesTableActionsCreator
