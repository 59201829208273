import { apiPrivate } from '@/api'
import { URL_BASE_TRANSACTIONS } from '@/endpoints'
import { AxiosResponse } from 'axios'
import camelcaseKeys from 'camelcase-keys'
import snakecaseKeys from 'snakecase-keys'
import { APIResponse, VolumeDiscountAssignment } from './common'

interface Response {
  volumeDiscountAssignments: VolumeDiscountAssignment[]
  total: number
}

interface ListParams {
  id?: number
  limit?: number
  offset?: number
  direction?: string
  orderByColumn?: string
  count?: boolean
  withItems?: boolean
  policyId?: number
  userId?: number
}

export function list({ direction, count = true, policyId, ...restParams }: Partial<ListParams>): Promise<Response> {
  const formattedParams = {
    count,
    dir: direction,
    ...snakecaseKeys({ ...restParams, volumeDiscountPolicyId: policyId })
  }

  return apiPrivate
    .get(`${URL_BASE_TRANSACTIONS}/user-volume-discounts`, { params: formattedParams })
    .then((response: AxiosResponse<APIResponse>) => {
      const discountAssignmentsFormatted = camelcaseKeys(response.data.content.user_volume_discounts ?? [], {
        deep: true
      }).map((assignment) => ({
        ...assignment,
        discountValues: {
          ...assignment.discountValues,
          volumeDiscountPolicy: {
            ...assignment.discountValues.volumeDiscountPolicy,
            volumeDiscountItems: assignment.discountValues.volumeDiscountPolicy?.volumeDiscountItems ?? []
          }
        }
      }))

      return {
        volumeDiscountAssignments: discountAssignmentsFormatted,
        total: response.data.content.total
      }
    })
    .catch((error) => {
      throw error
    })
}
