import { Reducer } from 'redux'
import {
  AssignUserDiscountTypes,
  FetchingDiscountsType,
  FetchingUserType,
  ModalAssignUserDiscountTypes,
  ModalsAssignmentsActions,
  ModalsAssignmentsState,
  ModalsAssignmentsTypes,
  UpdateAssignmentType
} from '@/sections/Discounts/types/volume/assignments/modals'

const initialState: ModalsAssignmentsState = {
  activationModalVisible: false,
  updatingAssignment: false,
  currentAssignment: {
    id: undefined,
    name: undefined,
    active: false
  },
  discounts: null,
  selectedDiscount: null,
  fetchingDiscounts: false,
  fetchingUser: false,
  user: null,
  modalVisible: false,
  userId: '',
  assigningUserDiscount: false,
  assignUserDiscountModalVisible: false,
  error: ''
}

const volumeDiscountModalsAssignmentsReducer: Reducer<ModalsAssignmentsState, ModalsAssignmentsActions> = (
  state = initialState,
  action
): ModalsAssignmentsState => {
  switch (action.type) {
    case ModalsAssignmentsTypes.SET_ACTIVATION_MODAL_VISIBILITY: {
      const { activationModalVisible } = action.payload

      return activationModalVisible
        ? {
            ...state,
            activationModalVisible
          }
        : initialState
    }
    case ModalsAssignmentsTypes.SET_CURRENT_ASSIGNMENT:
      return {
        ...state,
        currentAssignment: action.payload.currentAssignment
      }
    case UpdateAssignmentType.UPDATE_ASSIGNMENT_REQUEST:
      return {
        ...state,
        updatingAssignment: true
      }
    case UpdateAssignmentType.UPDATE_ASSIGNMENT_SUCCESS:
      return initialState
    case UpdateAssignmentType.UPDATE_ASSIGNMENT_FAILURE:
      return {
        ...state,
        updatingAssignment: false
      }
    case ModalAssignUserDiscountTypes.SET_MODAL_ASSIGN_USER_VISIBILITY: {
      const { modalVisible } = action.payload

      return modalVisible
        ? {
            ...state,
            assignUserDiscountModalVisible: modalVisible
          }
        : initialState
    }
    case FetchingDiscountsType.FETCHING_DISCOUNTS_REQUEST:
      return {
        ...state,
        fetchingDiscounts: true
      }
    case FetchingDiscountsType.FETCHING_DISCOUNTS_SUCCESS:
      return {
        ...state,
        fetchingDiscounts: false,
        discounts: action.payload.discounts
      }
    case FetchingDiscountsType.FETCHING_DISCOUNTS_FAILURE:
      return {
        ...state,
        fetchingDiscounts: false
      }
    case FetchingUserType.FETCHING_USER_REQUEST:
      return {
        ...state,
        fetchingUser: true
      }
    case FetchingUserType.FETCHING_USER_SUCCESS:
      return {
        ...state,
        fetchingUser: false,
        user: action.payload.user,
        error: action.payload.user ? '' : 'No se encontró usuario'
      }
    case FetchingUserType.FETCHING_USER_FAILURE:
      return {
        ...state,
        fetchingUser: false,
        error: 'Hubo un error al realizar la búsqueda del usuario'
      }

    case ModalAssignUserDiscountTypes.SET_USER_ID:
      return {
        ...state,
        userId: action.payload.userId
      }

    case ModalAssignUserDiscountTypes.SET_DISCOUNT:
      return {
        ...state,
        selectedDiscount: action.payload.selectedDiscount
      }

    case AssignUserDiscountTypes.ASSIGN_USER_DISCOUNT_REQUEST:
      return {
        ...state,
        assigningUserDiscount: true
      }
    case AssignUserDiscountTypes.ASSIGN_USER_DISCOUNT_SUCCESS:
      return initialState
    case AssignUserDiscountTypes.ASSIGN_USER_DISCOUNT_FAILURE:
      return {
        ...state,
        assigningUserDiscount: false
      }
    default:
      return state
  }
}

export default volumeDiscountModalsAssignmentsReducer
