export const SpanishArgentina = {
  currency: {
    symbol: '$',
    decimal: ',',
    thousand: '.',
    showCents: false
  },
  general: {
    Button: {
      cancel: 'Cancelar',
      save: 'Guardar'
    },
    months: {
      january: 'Enero',
      february: 'Febrero',
      march: 'Marzo',
      april: 'Abril',
      may: 'Mayo',
      june: 'Junio',
      july: 'Julio',
      august: 'Agosto',
      september: 'Septiembre',
      october: 'Octubre',
      november: 'Noviembre',
      december: 'Diciembre'
    }
  },
  generic: {
    continue: 'Continuar',
    next: 'Siguiente',
    add: 'Agregar',
    more: 'Más',
    filter: 'Filtrar',
    skip: 'Omitir',
    id: 'ID',
    update: 'Actualizar',
    forms: {
      required: 'Campo requerido',
      reset: 'Resetear',
      search: 'Buscar'
    },
    measure: {
      height: 'Alto',
      width: 'Ancho',
      length: 'Largo',
      weight: 'Peso'
    },
    searchs: {
      clientId: 'ID Cliente'
    },
    cancel: 'Cancelar',
    confirm: 'Confirmar',
    save: 'Guardar',
    edit: 'Editar',
    delete: 'Eliminar',
    close: 'Cerrar',
    search: 'Buscar',
    back: 'Volver',
    details: 'Detalles',
    reset: 'Resetear',
    accept: 'Aceptar',
    name: 'Nombre',
    lastName: 'Apellido',
    email: 'Email',
    status: 'Estado',
    discount: 'Descuento',
    country: 'País',
    active: 'Activo',
    inactive: 'Inactivo',
    activate: 'Activar',
    deactivate: 'Desactivar',
    client: 'Cliente',
    assign: 'Asignar'
  },
  Component: {
    UploadPhoto: {
      inputPlaceholder: 'Ningún archivo seleccionado',
      btnFile: 'Elegir archivo'
    },
    UploadFiles: {
      dropzoneHeader: 'Importación de ',
      dropzoneText: 'Arrastra el archivo o haz click aquí'
    }
  },
  Corporate: {
    Objects: {
      deleteObject: {
        title: 'Eliminar objeto',
        reason: 'Razón',
        reasonPlaceHolder: 'Seleccionar una razón',
        explanation: 'Explicación',
        cancel: 'Cancelar',
        confirm: 'Eliminar'
      }
    }
  },
  Users: {
    CU: {
      Modal: {
        Title: {
          createMode: 'Agregar CBU/CVU',
          editMode: 'Editar CBU/CVU'
        },
        infoGroup: 'CBU/CVU',
        markAsMain: 'Marcar como CBU/CVU principal'
      }
    }
  },
  ProductsTable: {
    button: {
      tooltip: 'Este botón automatiza la creación de un producto'
    }
  },
  ProductCatalog: {
    inputAutocomplete: {
      placeholder: 'ej: iPhone12, Apple, ...',
      tooltip: 'Cerrar sugerencias'
    },
    attributes: {
      category: 'Categoría'
    },
    title: {
      main: 'Explorá nuestro',
      span: 'catálogo de productos'
    },
    subtitle: 'Buscá por nombre, código universal, o código de catálogo de Mercado Libre.'
  },
  ValidationViewScreen: {
    infoBanner: {
      title: 'Detalle de información',
      fields: {
        depositId: 'ID Ingreso',
        clientId: 'ID Cliente',
        clientName: 'Nombre',
        clientLastName: 'Apellido',
        operationDate: 'F. operación',
        serviceDate: 'F. servicio',
        remittance: 'Remito',
        openRemittance: 'Ver remito',
        fullAddress: 'Dirección completa',
        copyFullAddress: 'Copiar dirección completa'
      }
    },

    transportExtras: {
      title: 'Extras transporte',
      extraKms: 'Kms extra',
      extraTransport: 'Extras de transporte/embalaje',
      warningTooltip: 'El descuento no puede ser mayor al valor de transporte cotizado',
      assistants: 'Asistentes de carga/descarga'
    },
    extraServices: {
      title: 'Servicios extra',
      search: 'Buscar por ID o nombre',
      emptyState: 'No se encontraron objetos cargados',
      table: {
        columns: {
          objectId: 'ID Objeto',
          productName: 'Nombre producto',
          stairs: 'Escaleras',
          packaging: 'Embalaje',
          disassembly: 'Desarmado'
        },
        rows: {
          deleted: 'Eliminado'
        },
        error: 'Hubo un error buscando la información'
      }
    },
    modal: {
      warning: 'Recordá que los datos ingresados no van a poder ser modificados.',
      buttons: {
        cancel: 'Cancelar',
        confirm: 'Entendido',
        apply: 'Aplicar'
      },
      discount: {
        title: 'Actualizar código de descuento',
        placeholder: 'Código de descuento',
        verify: 'Verificar validez',
        validCode: '¡Código válido!'
      }
    },
    overviewCard: {
      header: 'Valor final',
      summaryHeader: 'Resumen',
      fields: {
        objects: {
          title: 'Objetos'
        },
        proportionalBilling: {
          title: 'Abono proporcional',
          period: (startDate: string, endDate: string) => `Del ${startDate} al ${endDate}`,
          emptyState: 'El abono aún no ha sido calculado.\n Intente de nuevo más tarde.',
          discountDisclaimer: 'Esta cifra no toma en cuenta descuentos.'
        },
        additionalCosts: {
          title: 'Costos adicionales',
          subFields: {
            quotedTransport: 'Transporte cotizado',
            tollCost: 'Peaje',
            transport: 'Transporte',
            extraKms: 'Kms extra',
            extraTransport: 'Extras de transporte/embalaje',
            assistants: 'Asistentes de carga/descarga',
            packaging: 'Embalaje',
            stairs: 'Escaleras',
            disassembly: 'Desarmado',
            assembly: 'Armado',
            minCost: 'Tiempo min. almacenado'
          }
        },
        total: {
          title: 'Total ingreso'
        },
        discounts: {
          title: 'Código de descuento',
          delete: 'Eliminar código de descuento',
          add: 'Agregar código de descuento'
        },
        confirm: {
          tooltip: 'Para confirmar un ingreso, se debe recalcular el abono y estar procesado.'
        }
      }
    }
  },
  CatalogScreen: {
    title: 'Explorá nuestro',
    titleSpan: 'catálogo de productos',
    subtitle: 'Buscá por nombre, código universal, o código de catálogo de Mercado Libre.'
  },
  RemovalFlow: {
    OperationDate: {
      title: 'Fecha y Hora de Colecta',
      returnDay: 'Día de devolución',
      time: 'Horario',
      timeInterval: 'Franja Horaria',
      timeIntervalInfo:
        'El día previo a la devolución, nos debemos comunicar con el cliente para ofrecerle una franja horaria más acotada.'
    },
    DetailView: {
      title: 'Total devolución'
    }
  },
  Discounts: {
    Volume: {
      Policies: {
        table: {
          durationInDays: 'Vigencia',
          minM3: 'M³ Min'
        },
        actionBar: {
          usersRedirect: 'Usuarios alcanzados',
          create: 'Crear categoría'
        },
        modal: {
          title: 'Crear categoría de descuento por volumen',
          submit: 'Crear',
          fields: {
            name: {
              label: 'Nombre de la categoría',
              placeholder: 'Ej: "Descuento por volumen"'
            },
            duration: {
              label: 'Duración (días)',
              placeholder: 'Ej: 30'
            },
            isEnabled: {
              label: 'Habilitado'
            }
          },
          activationMessage: (toState: string) =>
            `¿Estás seguro de que deseas ${toState} esta categoría de descuentos?`,
          errors: {
            nameRequired: 'El nombre de la categoría es requerido',
            nameMaxLength: (max: number) => `El nombre de la categoría no puede superar los ${max} caracteres`,
            nameMinLength: (min: number) => `El nombre de la categoría debe tener al menos ${min} caracteres`,
            durationRequired: 'La duración de la categoría es requerida',
            durationPositive: 'La duración de la categoría debe ser un número positivo',
            durationInteger: 'La duración de la categoría debe ser un número entero',
            updateError: 'No se pudo actualizar la categoría de descuentos'
          }
        },
        itemModal: {
          title: 'Añadir descuento',
          submit: 'Añadir',
          cancel: 'Cancelar',
          fields: {
            minRange: {
              label: 'Rango mínimo',
              placeholder: 'Ej: 3 (m3)'
            },
            discountPercentage: {
              label: 'Porcentaje de descuento',
              placeholder: 'Ej: 30 (%)'
            }
          },
          errors: {
            minRangeRequired: 'El rango mínimo del descuento es requerido',
            minRangePositive: 'El rango mínimo del descuento debe ser un número positivo y mayor a 0',
            discountPercentageRequired: 'El porcentaje de descuento es requerida',
            discountPercentagePositive: 'El porcentaje de descuento debe ser un número positivo y mayor a 0',
            discountPercentageInteger: 'El porcentaje de descuento debe ser un número entero',
            discountPercentageMax: (max: number) => `El porcentaje de descuento no puede ser mayor a ${max}`
          }
        }
      },
      Assignments: {
        table: {
          discountDescription: 'Descripción del descuento',
          rangeM3: 'Rango de m³'
        },
        actionBar: {
          assign: 'Asignar descuento',
          policiesRedirect: 'Volver a descuentos'
        },
        modal: {
          activationTitle: 'Descuento de usuario',
          activationMessage: ({
            toState,
            userInfo,
            volumeDiscount
          }: {
            toState: string
            userInfo: string
            volumeDiscount: string
          }) =>
            `¿Estás seguro de que deseas ${toState} este descuento por volumen (${volumeDiscount}) al usuario ${userInfo}?`,
          assignUserDiscountTitle: 'Asignar descuento a usuario',
          discountsRange: 'Banda de precios',
          discountsRangePlaceholder: 'Seleccioná una categoría de descuento',
          inputUser: 'Ingrese el ID del usuario al cual quiere asignarle un descuento',
          userData: 'Datos del usuario',
          errors: {
            updateError: 'No se pudo actualizar los descuentos del usuario'
          }
        }
      }
    }
  },
  Billings: {
    modalImportTxt: {
      title: 'Importar cobros'
    },
    tableActions: {
      exportPayments: 'Exportar pagos',
      billPeriod: 'Facturar abonos'
    },
    DiscountModal: {
      title: 'Descuentos en abonos',
      okButton: 'Crear nuevo descuento',
      cancelButton: 'Cancelar',
      userInfo: {
        loading: 'Cargando información del usuario...'
      },
      currentDiscounts: {
        title: (detail: string) => `Descuentos actuales (${detail})`,
        emptyDiscounts: 'No hay descuentos aplicados',
        startDate: 'Fecha de inicio',
        endDate: 'Fecha de fin'
      },
      createDiscount: {
        title: 'Crear nuevo descuento',
        types: {
          percentage: 'Porcentaje',
          amount: 'Monto'
        },
        discountType: 'Tipo de descuento',
        discountValue: 'Valor del descuento',
        discountReason: 'Motivo del descuento',
        startDate: 'Inicio del descuento',
        endDate: 'Fin del descuento',
        placeholders: {
          percentageInput: 'Ingrese porcentaje',
          amountInput: 'Ingrese monto',
          startDatePicker: 'Fecha de inicio',
          endDatePicker: 'Fecha de fin',
          selectReason: 'Seleccioná un motivo'
        }
      },
      deleteModal: {
        title: 'Eliminar descuento',
        confirmation: '¿Está seguro de que quiere eliminar este descuento?'
      }
    },
    Table: {
      actions: {
        discounts: 'Descuentos',
        details: 'Ver detalle',
        downloadPdf: 'Ver factura',
        recalculate: 'Recalcular',
        authorization: 'Facturar',
        enableClient: 'Habilitar cliente',
        excludeClient: 'Excluir cliente',
        sendReminder: 'Enviar Recordatorio de Deuda',
        sendBill: 'Enviar factura',
        cancelSubscription: 'Anular abono',
        downloadNC: 'Descargar nota de crédito'
      }
    }
  },
  dashboardViewScreen: {
    header: {
      title: 'Bienvenido a SpaceGuru',
      dateRangeOptions: {
        title: 'Fecha de filtrado',
        selectRange: 'Seleccione un rango de fechas'
      },
      metrics: {
        m3Title: 'Metros³',
        quotedM3Card: 'm³ cotizados',
        quotedM3Description:
          'Volumen cotizado en metros cúbicos que se estimaron durante la cotización inicial para el almacenamiento. Este valor se utiliza para presupuestar y planificar el espacio necesario.',
        realM3Card: 'm³ reales',
        realM3Description:
          'Volumen real en metros cúbicos ocupado por los objetos una vez almacenados. Este valor refleja el espacio físico utilizado y puede diferir del volumen cotizado inicialmente.',
        leadsTitle: 'Leads',
        leadsCard: 'Leads Pactados',
        leadsDescription:
          'Leads que han confirmado su interés y han acordado avanzar con un Retiro Pactado (RP). Estos leads han mostrado un compromiso inicial, pero aún no han completado la transacción. Se toma en cuenta la fecha de lead ganado',
        rpTitle: "Rp's",
        totalRPCard: "Rp's totales",
        totalRPDescription:
          'Este valor toma en cuenta desde la fecha de creación del ingreso y refleja el total de Retiros Pactados (RP) gestionados, incluyendo tanto los que han sido completados como aquellos cancelados.',
        winRPCard: "Rp's ganados",
        winRPDescription:
          'El cliente realiza un pago inicial y la oportunidad se convierte exitosamente en estado "Convertido"',
        cancelledRPCard: "Rp's cancelados",
        cancelledRPDescription:
          'Cliente que confirma un retiro pactado, realiza un pago inicial, pero por algún motivo el retiro no se concreta. Los objetos del cliente no ingresan al depósito',
        depositsTitle: 'Ingresos',
        totalDepositsCard: 'Ingresos totales',
        totalDepositsDescription:
          'Este valor toma en cuenta desde la fecha de creación del ingreso y refleja el total de Retiros Pactados (RP) gestionados, incluyendo tanto los que han sido completados como aquellos cancelados.',
        validatedDepositCard: 'Ingresos validados',
        validatedDepositDescription:
          'Ingresos que han sido confirmados y validados, garantizando que los objetos han sido almacenados en el depósito.',
        pendingDepositCard: 'Ingresos pendientes',
        pendingDepositDescription:
          'Ingresos que están en proceso de validación y aún no han sido confirmados. Estos ingresos están sujetos a revisión y confirmación final.'
      }
    },
    states: {
      title: 'Métricas',
      emptyState: 'No se encontraron métricas',
      fetchingError: 'Hubo un error buscando la información'
    },
    annual: {
      title: 'Tendencia anual',
      emptyState: 'No se encontraron métricas anuales',
      fetchingError: 'Hubo un error buscando la información',
      m3: 'Metros cúbicos',
      m3Win: 'm³ ganados',
      m3Removals: 'm³ devueltos',
      clients: 'Clientes',
      newUsers: 'Nuevos usuarios con ingresos',
      clientsRemovals: 'Usuarios con devoluciones totales'
    },
    removals: {
      title: 'Devoluciones',
      total: 'Totales',
      totalDescription:
        'Cantidad total de devoluciones procesadas, que incluye todas las solicitudes completadas de devolución de objetos.',
      m3: 'Por metros cúbicos',
      m3Description:
        'Volumen total en metros cúbicos de los objetos devueltos. Este valor indica la cantidad de espacio liberado en el depósito tras la devolución',
      clients: 'Total por cliente',
      clientsDescription:
        'Este valor representa la cantidad de devoluciones de clientes que han solicitado retirar todos sus objetos.'
    }
  },
  ComplaintsViewScreen: {
    title: 'Reclamos',
    table: {
      actions: {
        update: 'Ver reclamo'
      },
      columns: {
        id: 'ID',
        user: 'Usuario',
        operation: 'Operación',
        type: 'Tipo',
        reason: 'Motivo',
        status: 'Estado',
        date: 'Fecha',
        agent: 'Agente',
        priority: 'Prioridad'
      },
      emptyState: 'No se encontraron reclamos',
      error: 'Hubo un error buscando la información'
    },

    filters: {
      search: 'Buscar por ID o nombre',
      status: 'Estado',
      type: 'Tipo',
      reason: 'Motivo',
      agent: 'Agente',
      priority: 'Prioridad',
      date: 'Fecha'
    },
    createComplaintModal: {
      title: 'Crear reclamo',
      fields: {
        clientGroupTitle: 'Datos cliente',
        clientTitle: 'Cliente',
        searchClientPlaceholder: 'Buscar cliente por ID',
        informationGroupTitle: 'Información del reclamo',
        operationIdTitle: 'ID de la operación',
        operationIdPlaceholder: 'Ingrese ID de la operación',
        typeTitle: 'Tipo de reclamo',
        typePlaceholder: 'Seleccione tipo de reclamo',
        reasonTitle: 'Motivo del reclamo',
        descriptionTitle: 'Detalles del reclamo',
        reasonPlaceholder: 'Seleccione motivo del reclamo',
        descriptionPlaceholder: 'Ingrese detalle del reclamo',
        requiredFieldDetails: '*Campo obligatorio - máximo 300 caracteres',
        evidence: 'Agregar evidencia',
        objectsGroupTitle: 'Objetos del reclamo',
        objectsPlaceholder: 'Buscar objeto por ID',
        user: 'Usuario',
        priorityGroupTitle: 'Prioridad y Asignación',
        agentTitle: 'Agente asignado',
        agentPlaceholder: 'Seleccione agente',
        priorityTitle: 'Prioridad del reclamo',
        priorityPlaceholder: 'Seleccione prioridad',
        objectTitle: 'ID del objeto'
      },
      buttons: {
        addObject: 'Agregar objetos'
      }
    },
    updateView: {
      title: 'Actualizar reclamo',
      complaintDetails: {
        title: 'Detalles del reclamo',
        agentTitle: 'Asignar responsable'
      },
      loadingComplaint: 'Cargando reclamo...',
      emptyState: 'No se encontró el reclamo',
      clientDetail: {
        title: 'Datos cliente',
        clientName: 'ID y Nombre del cliente',
        creationDateTitle: 'Fecha de creación del reclamo',
        complaintId: 'ID del Reclamo',
        creator: 'Creador',
        daysOpen: 'Días abierto',
        emptyPhotos: 'No se encontró evidencia asociada al reclamo',
        emptyObjects: 'No se encontraron objetos asociados al reclamo',
        objectId: 'ID del objeto',
        objectName: 'Nombre del objeto',
        evidence: 'Evidencias'
      },
      history: {
        title: 'Historial del reclamo',
        emptyHistory: 'Por el momento éste reclamo no tiene historial',
        updateDateTitle: 'Fecha cambio',
        userCreator: 'Usuario que realizó el cambio',
        assignedUser: 'Agente asignado',
        status: 'Estado del reclamo'
      },
      evidenceModal: {
        title: 'Ver evidencia'
      },
      buttons: {
        close: 'Cerrar reclamo'
      }
    },
    resolveModal: {
      title: 'Resolver reclamo',
      confirmation: '¿Estás seguro que deseas resolver el reclamo?',
      warning:
        'Por favor, recuerda que al cerrar el reclamo, se enviará un correo electrónico al usuario para notificarle sobre la resolución del mismo. Además, te pedimos que dejes un comentario a continuación sobre cómo se resolvió el reclamo.',
      fields: {
        detailsTitleGroup: 'Información del reclamo',
        createdAt: 'Fecha de creación del reclamo',
        statusTitle: 'Resolución del reclamo',
        statusPlaceholder: 'Seleccionar tipo de resolución',
        complaintId: 'ID del Reclamo',
        creator: 'Creador',
        openDays: 'Días abierto',
        reason: 'Motivo del reclamo',
        type: 'Tipo de reclamo',
        operationId: 'ID de operación',
        resolutionTitleGroup: 'Motivo de resolución'
      }
    }
  }
}
