import React, { useCallback, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory, useParams } from 'react-router'
import { bindActionCreators } from 'redux'
import { useEvents } from '../../../utils/eventEmitter'
import { Events } from '../../../utils/eventEmitter/events'
import DepositValidationViewActionCreators from '../actionCreators/validationView'
import ValidationView, { ValidationViewProps } from '../components/validationView'
import RemittanceModalActionCreators from '../../RemittanceModal/actionCreators'
import EditObjectActionCreators from '../../Objects/actions/edit'
import RemittanceModalContainer from '../../RemittanceModal/container'
import EditObjectModal from '../../Objects/containers/edit'

interface ParamTypes {
  depositId: number
}

const useParsedParams = (): ParamTypes => {
  const { depositId } = useParams<{ depositId: string }>()

  return { depositId: parseInt(depositId) }
}

const ValidationViewContainer = () => {
  const {
    operationCountryCode,
    objects,
    quotedTransportCost,
    baseInfo,
    extraPrices,
    loading,
    loadingDepositOperation,
    headerToggles,
    headerCounters,
    validating,
    error,
    discountCode,
    discountId,
    provisionalBilling,
    fetchedRecalculation,
    discount
  } = useSelector((root) => root.DepositsValidation.validationView)
  const countries = useSelector((root) => root.CountrySelector.countries)

  const history = useHistory()

  const dispatch = useDispatch()

  const {
    populateView,
    validateOperation,
    setTransportExtraCount,
    setTransportExtraPrice,
    setFetchedRecalculation,
    toggleTransportExtra,
    toggleObjectExtra,
    setObjectFloors,
    clearValidationViewState,
    recalculateOperation,
    getBillingByTransaction,
    getDepositOperationById,
    updateDiscount,
    setDiscountCode,
    fetchDiscount,
    handleClearDiscount,
    setOpenModalChangeDiscount,
    validateDiscount
  } = bindActionCreators(DepositValidationViewActionCreators, dispatch)

  const { depositId } = useParsedParams()
  useEvents(Events.Deposits.DEPOSIT_VALIDATED, () => {
    history.push('/depositsValidation/')
  })
  useEvents(Events.Objects.OBJECT_EDITED, () => {
    setFetchedRecalculation(false)
    getDepositOperationById({ depositId })
  })
  useEvents(Events.Deposits.DEPOSIT_UPDATED, () => {
    getDepositOperationById({ depositId })
  })

  useEvents(Events.Deposits.DEPOSIT_VALIDATED_DISCOUNT, () => {
    fetchDiscount(discount.updateDiscountCode)
  })

  const { selectObjectByID } = bindActionCreators(EditObjectActionCreators, dispatch)
  const openRemittanceModal = bindActionCreators(RemittanceModalActionCreators.setOpen, dispatch)

  useEffect(() => {
    if (!depositId) return

    populateView({ depositId })

    return () => {
      clearValidationViewState()
    }
  }, [depositId])

  const handleValidateOperation = async () => {
    await validateOperation({
      depositId: baseInfo.id,
      objects,
      kmsExtra: extraPrices.kmsExtra.enabled ? extraPrices.kmsExtra.count : 0,
      kmsExtraPrice: extraPrices.kmsExtra.enabled ? extraPrices.kmsExtra.price : 0,
      shipmentAssistantCount: extraPrices.assistants.enabled ? extraPrices.assistants.count : 0,
      shipmentAssistantPrice: extraPrices.assistants.enabled ? extraPrices.assistants.price : 0,
      transportExtra: extraPrices.transport.enabled ? extraPrices.transport.price : 0
    })
  }

  const handleOpenRemittanceModal = (transactionId: number) => {
    openRemittanceModal(true, transactionId)
  }

  const handleRecalculate = async () => {
    const transactionId = baseInfo.transactionId
    const userId = baseInfo.user.id
    await recalculateOperation({ transactionId, userId })
    populateView({ depositId, fromRecalculation: true })
  }

  const getBillingByTransactionCb = useCallback(
    () => getBillingByTransaction(baseInfo.transactionId),
    [baseInfo.transactionId, getBillingByTransaction]
  )

  const handleDeleteDiscount = async () => {
    if (!discountId) return
    updateDiscount({ operationId: baseInfo.id, discountId: 0 })
  }

  const handleApplyNewDescount = () => {
    handleClearDiscount()
    setOpenModalChangeDiscount(false)
    updateDiscount({ operationId: baseInfo.id, discountId: discount.discountId })
  }

  const handleValidateDiscount = () => {
    validateDiscount(discount.updateDiscountCode)
  }

  const props: ValidationViewProps = {
    countries,
    operationCountryCode,
    objects,
    quotedTransportCost,
    baseInfo,
    extraPrices,
    loading,
    loadingDepositOperation,
    error,
    headerToggles,
    headerCounters,
    validating,
    discountCode,
    provisionalBilling,
    fetchedRecalculation,
    openRemittanceModal: handleOpenRemittanceModal,
    validateOperation: handleValidateOperation,
    toggleTransportExtra,
    setTransportExtraCount,
    setTransportExtraPrice,
    toggleObjectExtra,
    setObjectFloors,
    handleRecalculate,
    editObject: selectObjectByID,
    getBillingByTransaction: getBillingByTransactionCb,
    handleDeleteDiscount,
    fetchDiscount,
    setDiscountCode,
    discount,
    handleClearDiscount,
    handleApplyNewDescount,
    setOpenModalChangeDiscount,
    handleValidateDiscount
  }

  return (
    <>
      <RemittanceModalContainer />
      <EditObjectModal />
      <ValidationView {...props} />
    </>
  )
}

export default ValidationViewContainer
