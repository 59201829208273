import { API } from '@/projectApi'
import { Params as CreatePolicyParams } from '@/projectApi/TransactionHandler/Discounts/volume/policies/create'
import { Params as UpdatePolicyParams } from '@/projectApi/TransactionHandler/Discounts/volume/policies/update'
import {
  CreateDiscountPolicyType,
  CreatePolicyRequest,
  ModalsPoliciesTypes,
  Policy,
  SetActivationModalVisibility,
  SetCurrentPolicy,
  SetModalVisibility,
  SetPolicyDuration,
  SetPolicyName,
  SetPolicyState,
  UpdateDiscountPolicyType,
  UpdatePolicyRequest
} from '@/sections/Discounts/types/volume/policies/modals'
import { AppThunk } from '@/store'
import { handleErrorsWithAction } from '@/utils/HandleErrors'
import { setToastErrorUpdate, setToastLoading, setToastSuccessUpdate } from '@/utils/notifications'

const volumeDiscountPoliciesModalsActionsCreator = {
  setModalVisibility({ modalVisible }: { modalVisible: boolean }): SetModalVisibility {
    return {
      type: ModalsPoliciesTypes.SET_MODAL_VISIBILITY,
      payload: { modalVisible }
    }
  },

  setActivationModalVisibility({
    activationModalVisible
  }: {
    activationModalVisible: boolean
  }): SetActivationModalVisibility {
    return {
      type: ModalsPoliciesTypes.SET_ACTIVATION_MODAL_VISIBILITY,
      payload: { activationModalVisible }
    }
  },

  setPolicyName({ policyName }: { policyName: string }): SetPolicyName {
    return {
      type: ModalsPoliciesTypes.SET_POLICY_NAME,
      payload: { policyName }
    }
  },

  setPolicyState({ policyState }: { policyState: boolean }): SetPolicyState {
    return {
      type: ModalsPoliciesTypes.SET_POLICY_STATE,
      payload: { policyState }
    }
  },

  setPolicyDuration({ policyDuration }: { policyDuration: number }): SetPolicyDuration {
    return {
      type: ModalsPoliciesTypes.SET_POLICY_DURATION,
      payload: { policyDuration }
    }
  },

  setCurrentPolicy({ currentPolicy }: { currentPolicy: Policy }): SetCurrentPolicy {
    return {
      type: ModalsPoliciesTypes.SET_CURRENT_POLICY,
      payload: { currentPolicy }
    }
  },

  createPolicy(params: CreatePolicyParams, successCallback?: () => void): AppThunk {
    return async (dispatch) => {
      const request: CreatePolicyRequest = {
        type: CreateDiscountPolicyType.CREATE_POLICY_REQUEST
      }
      const toastId = setToastLoading('Creando política de descuento, por favor espere...')

      dispatch(request)

      try {
        await API.TransactionHandler.Discounts.VolumeDiscounts.policies.create(params)

        dispatch({
          type: CreateDiscountPolicyType.CREATE_POLICY_SUCCESS
        })
        setToastSuccessUpdate(toastId, { render: 'Categoría de descuento creada correctamente.' })
        successCallback?.()
      } catch (error) {
        setToastErrorUpdate(toastId, {
          render:
            handleErrorsWithAction(error, CreateDiscountPolicyType.CREATE_POLICY_FAILURE, dispatch) ||
            'Error al crear la categoría de descuento'
        })
      }
    }
  },

  updatePolicy(params: UpdatePolicyParams, successCallback?: () => void): AppThunk {
    return async (dispatch) => {
      const request: UpdatePolicyRequest = {
        type: UpdateDiscountPolicyType.UPDATE_POLICY_REQUEST
      }
      const toastId = setToastLoading('Actualizando categoría de descuento, por favor espere...')

      dispatch(request)

      try {
        await API.TransactionHandler.Discounts.VolumeDiscounts.policies.update(params)

        dispatch({
          type: UpdateDiscountPolicyType.UPDATE_POLICY_SUCCESS
        })
        setToastSuccessUpdate(toastId, { render: 'Categoría de descuento actualizada correctamente.' })
        successCallback?.()
      } catch (error) {
        setToastErrorUpdate(toastId, {
          render:
            handleErrorsWithAction(error, UpdateDiscountPolicyType.UPDATE_POLICY_FAILURE, dispatch) ||
            'Error al actualizar la categoría de descuento'
        })
      }
    }
  }
}

export default volumeDiscountPoliciesModalsActionsCreator
