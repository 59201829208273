import React, { useEffect } from 'react'
import { VolumeDiscountsTable } from '../../../components/volume/policies/Table'
import { bindActionCreators } from 'redux'
import { useDispatch, useSelector } from 'react-redux'
import volumeDiscountPoliciesTableActionsCreator from '../../../actions/volume/policies/table'
import { Pagination } from '@/utils/searchFilterUtils'
import { MenuAction } from '@/components/actionMenu/baseMenu'
import { VolumeDiscountPolicy } from '@/projectApi/TransactionHandler/Discounts/volume/policies/common'
import { Icon } from 'antd'
import policyItemsActionsCreator from '@/sections/Discounts/actions/volume/policies/items'
import volumeDiscountPoliciesModalsActionsCreator from '@/sections/Discounts/actions/volume/policies/modals'
import { useLocalization } from '@/packages/localization'
import {
  PoliciesCategoryFilter,
  PoliciesSearchFilter,
  PoliciesSearchKey
} from '@/sections/Discounts/types/volume/policies/table'

const VolumeDiscountPoliciesTableContainer = () => {
  const tableState = useSelector((state) => state.Discount.volumeDiscountPoliciesTableReducer)
  const { strings } = useLocalization()

  const dispatch = useDispatch()
  const { getDiscounts } = bindActionCreators(volumeDiscountPoliciesTableActionsCreator, dispatch)
  const { setCurrentPolicy, setActivationModalVisibility } = bindActionCreators(
    volumeDiscountPoliciesModalsActionsCreator,
    dispatch
  )
  const { setModalItemVisibility, setCurrentPolicyId } = bindActionCreators(policyItemsActionsCreator, dispatch)

  const emptyPagination = { ...tableState.pagination, page: 1 }

  const handleGetVolumeDiscounts = async (newParams: {
    pagination?: Pagination
    searchFilters?: PoliciesSearchFilter[]
    categoryFilter?: PoliciesCategoryFilter
  }) => {
    getDiscounts({
      pagination: newParams.pagination || tableState.pagination,
      searchFilters: newParams.searchFilters || tableState.searchFilters,
      categoryFilter: newParams.categoryFilter || tableState.categoryFilter
    })
  }

  useEffect(() => {
    handleGetVolumeDiscounts({})
  }, [])

  const handlePageChange = (newPage: number) => {
    handleGetVolumeDiscounts({ pagination: { ...tableState.pagination, page: newPage } })
  }

  const handleSearch = (key: PoliciesSearchKey, newValue: string) => {
    const newSearchFilters = tableState.searchFilters.map((filter) =>
      filter.key === key
        ? {
            ...filter,
            text: newValue
          }
        : filter
    )
    handleGetVolumeDiscounts({ searchFilters: newSearchFilters, pagination: emptyPagination })
  }

  const handleCategoryFilter = (newCategoryFilter: PoliciesCategoryFilter) => {
    handleGetVolumeDiscounts({ categoryFilter: newCategoryFilter, pagination: emptyPagination })
  }

  const menuActions: MenuAction<VolumeDiscountPolicy>[] = [
    {
      label: 'Añadir descuento',
      icon: <Icon type="plus" />,
      onClick: (policy) => {
        setModalItemVisibility({ modalItemVisible: true })
        setCurrentPolicyId(policy.id)
      },
      disabled: (policy) => !policy?.active
    },
    {
      label: (policy) => (policy?.active ? strings.generic.deactivate : strings.generic.activate),
      icon: (policy) => <Icon type={policy?.active ? 'stop' : 'check'} />,
      onClick: (policy) => {
        setActivationModalVisibility({ activationModalVisible: true })
        setCurrentPolicy({
          currentPolicy: {
            id: policy.id,
            name: policy.name,
            active: policy.active,
            durationInDays: policy.durationInDays
          }
        })
      }
    }
  ]

  const tableProps = {
    volumeDiscountPolicies: tableState.volumeDiscountPolicies,
    fetchingDiscounts: tableState.fetchingDiscounts,
    pagination: {
      currentPage: tableState.pagination.page,
      pageSize: tableState.pagination.pageSize,
      total: tableState.pagination.total,
      onPageChange: handlePageChange
    },
    actions: menuActions,
    searchFilters: tableState.searchFilters,
    categoryFilter: tableState.categoryFilter,
    handleSearch,
    handleCategoryFilter
  }

  return <VolumeDiscountsTable {...tableProps} />
}

export default VolumeDiscountPoliciesTableContainer
