import { API } from '@/projectApi'
import { AppThunk } from '@/store'
import { handleErrorsWithAction } from '@/utils/HandleErrors'
import {
  CreatePolicyItemRequest,
  CreatePolicyItemType,
  ModalPolicyItemTypes,
  SetModalItemVisibility
} from '@/sections/Discounts/types/volume/policies/items'
import { setToastErrorUpdate, setToastLoading, setToastSuccessUpdate } from '@/utils/notifications'
import { Params as CreatePolicyItemParams } from '@/projectApi/TransactionHandler/Discounts/volume/policies/createItem'

const policyItemsActionsCreator = {
  setModalItemVisibility({ modalItemVisible }: { modalItemVisible: boolean }): SetModalItemVisibility {
    return {
      type: ModalPolicyItemTypes.SET_MODAL_ITEM_VISIBILITY,
      payload: { modalItemVisible }
    }
  },

  setMinRangeValue(minRange: number) {
    return {
      type: ModalPolicyItemTypes.SET_MIN_RANGE_VALUE,
      payload: { minRange }
    }
  },

  setDiscountPercentageValue(discountPercentage: number) {
    return {
      type: ModalPolicyItemTypes.SET_DISCOUNT_PERCENTAGE_VALUE,
      payload: { discountPercentage }
    }
  },

  setCurrentPolicyId(currentPolicyId: number) {
    return {
      type: ModalPolicyItemTypes.SET_CURRENT_POLICY_ID,
      payload: { currentPolicyId }
    }
  },

  createPolicyItem(params: CreatePolicyItemParams, successCallback?: () => void): AppThunk {
    return async (dispatch) => {
      const request: CreatePolicyItemRequest = {
        type: CreatePolicyItemType.CREATE_POLICY_ITEM_REQUEST
      }

      const toastId = setToastLoading('Creando ítem de categoría de descuento, por favor espere...')

      dispatch(request)

      try {
        await API.TransactionHandler.Discounts.VolumeDiscounts.policies.createItem(params)

        dispatch({
          type: CreatePolicyItemType.CREATE_POLICY_ITEM_SUCCESS
        })
        setToastSuccessUpdate(toastId, { render: 'Ítem de categoría de descuento creado correctamente.' })
        successCallback?.()
      } catch (error) {
        setToastErrorUpdate(toastId, {
          render:
            handleErrorsWithAction(error, CreatePolicyItemType.CREATE_POLICY_ITEM_FAILURE, dispatch) ||
            'Error al crear el ítem de categoría de descuento'
        })
      }
    }
  }
}

export default policyItemsActionsCreator
