import { Donation } from '../../../projectApi/Sales/DonationRequest/common'
import { DonationsListSortKey } from '../../../projectApi/Sales/DonationRequest/list'
import { CategoryFilter, DateRangeFilter, Pagination, SearchFilter, Sort } from '../../../utils/searchFilterUtils'

export type DonationsTableSearchKey = 'userId' | 'id' | 'objectId' | 'requestId'
export type DonationsSearchFilter = SearchFilter<DonationsTableSearchKey>

export type DonationDateRangeFilter = DateRangeFilter<DonationTableDateRangeKey>

export type DonationsTableSort = Sort<DonationsListSortKey>

export type DonationsTableCategoryFilterKey = 'status'
export type DonationsTableCategoryFilter = CategoryFilter<DonationsTableCategoryFilterKey>

export type DonationsListStateType = {
  fetchingDonations: boolean
  sendingCSV: boolean
  donations: Donation[]
  pagination: Pagination
  sort: DonationsTableSort
  searchFilters: DonationsSearchFilter[]
  categoryFilter: DonationsTableCategoryFilter
  dateRangeFilters: DonationDateRangeFilter[]
  showDemoUsers: boolean
}

export enum DonationTableDateRangeKey {
  CREATED_AT = 'createdAt',
  UPDATED_AT = 'updatedAt'
}

export enum GetDonationsListTypes {
  GET_DONATION_REQUEST = 'DONATION/GET_DONATION_REQUEST',
  GET_DONATION_SUCCESS = 'DONATION/GET_DONATION_SUCCESS',
  GET_DONATION_FAILURE = 'DONATION/GET_DONATION_FAILURE'
}

export interface GetDonationListRequest {
  type: GetDonationsListTypes.GET_DONATION_REQUEST
  payload: {
    pageSize: number
    newPage: number
    searchFilters?: DonationsSearchFilter[]
    categoryFilter?: DonationsTableCategoryFilter
    sort: DonationsTableSort
    dateRangeFilters?: DonationDateRangeFilter[]
    showDemoUsers?: boolean
  }
}

export interface GetDonationListSuccess {
  type: GetDonationsListTypes.GET_DONATION_SUCCESS
  payload: {
    donations: Donation[]
    total: number
  }
}

export interface GetDonationListFailure {
  type: GetDonationsListTypes.GET_DONATION_FAILURE
  payload: {
    error: string
  }
}

type GetDonationActions = GetDonationListRequest | GetDonationListSuccess | GetDonationListFailure

export enum SendDonationsCSVTypes {
  SEND_DONATIONS_CSV_REQUEST = 'DONATION/SEND_DONATIONS_CSV_REQUEST',
  SEND_DONATIONS_CSV_SUCCESS = 'DONATION/SEND_DONATIONS_CSV_SUCCESS',
  SEND_DONATIONS_CSV_FAILURE = 'DONATION/SEND_DONATIONS_CSV_FAILURE'
}

export interface SendDonationsCSVRequest {
  type: SendDonationsCSVTypes.SEND_DONATIONS_CSV_REQUEST
}

export interface SendDonationsCSVSuccess {
  type: SendDonationsCSVTypes.SEND_DONATIONS_CSV_SUCCESS
}

export interface SendDonationsCSVFailure {
  type: SendDonationsCSVTypes.SEND_DONATIONS_CSV_FAILURE
  payload: {
    error: string
  }
}

type SendDonationsCSVActions = SendDonationsCSVRequest | SendDonationsCSVSuccess | SendDonationsCSVFailure

export const RESET_FILTERS = 'DONATION/RESET_FILTERS'

export interface ResetFilters {
  type: typeof RESET_FILTERS
}

export enum RefreshTableDataTypes {
  REFRESH_TABLE_DATA = 'DONATION/REFRESH_TABLE_DATA'
}

export interface RefreshTableDataRequest {
  type: typeof RefreshTableDataTypes.REFRESH_TABLE_DATA
}

export type RefreshTableDataActions = RefreshTableDataRequest

export type DonationListActions = GetDonationActions | SendDonationsCSVActions | ResetFilters | RefreshTableDataActions
