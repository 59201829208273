import { ChangeAPI } from './Change'
import { CountriesAPI } from './Countries'
import { UsersAPI } from './Users'
import { RolesAPI } from './Roles'
import { AddressesAPI } from './Addresses'
import { SatelliteAPI } from './Satellite'
import { EmailAPI } from './Email'
import { login } from './login'
import { userExclusionReasons } from './userExclusionReasons'
import { ReportsAPI } from './Reports'
import { resetPassword } from './resetPassword'

export const AccountManagerAPI = {
  Countries: CountriesAPI,
  Users: UsersAPI,
  Change: ChangeAPI,
  Roles: RolesAPI,
  Addresses: AddressesAPI,
  Satellite: SatelliteAPI,
  Email: EmailAPI,
  Reports: ReportsAPI,
  resetPassword,
  userExclusionReasons,
  login
}
