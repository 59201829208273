import { VolumeDiscountAssignment } from '@/projectApi/TransactionHandler/Discounts/volume/assignments/common'
import { Pagination, SearchFilter } from '@/utils/searchFilterUtils'

export type DiscountAssignmentsSearchKey = 'id' | 'userId' | 'policyId'
export type DiscountAssignmentsSearchFilter = SearchFilter<DiscountAssignmentsSearchKey>

export type VolumeDiscountAssignmentsState = {
  volumeDiscountAssignments: VolumeDiscountAssignment[]
  fetchingDiscounts: boolean
  pagination: Pagination
  searchFilters: DiscountAssignmentsSearchFilter[]
}

export enum GetVolumeDiscountAssignmentsTypes {
  GET_VOLUME_DISCOUNT_ASSIGNMENTS_REQUEST = 'GET_VOLUME_DISCOUNT_ASSIGNMENTS_REQUEST',
  GET_VOLUME_DISCOUNT_ASSIGNMENTS_SUCCESS = 'GET_VOLUME_DISCOUNT_ASSIGNMENTS_SUCCESS',
  GET_VOLUME_DISCOUNT_ASSIGNMENTS_FAILURE = 'GET_VOLUME_DISCOUNT_ASSIGNMENTS_FAILURE'
}

export interface GetVolumeDiscountAssignmentsRequest {
  type: typeof GetVolumeDiscountAssignmentsTypes.GET_VOLUME_DISCOUNT_ASSIGNMENTS_REQUEST
  payload: {
    pageSize: number
    newPage: number
    searchFilters?: DiscountAssignmentsSearchFilter[]
  }
}

export interface GetVolumeDiscountAssignmentsSuccess {
  type: typeof GetVolumeDiscountAssignmentsTypes.GET_VOLUME_DISCOUNT_ASSIGNMENTS_SUCCESS
  payload: {
    volumeDiscountAssignments: VolumeDiscountAssignment[]
    total: number
  }
}

export interface GetVolumeDiscountAssignmentsFailure {
  type: typeof GetVolumeDiscountAssignmentsTypes.GET_VOLUME_DISCOUNT_ASSIGNMENTS_FAILURE
}

type GetVolumeDiscountAssignmentsActions =
  | GetVolumeDiscountAssignmentsRequest
  | GetVolumeDiscountAssignmentsSuccess
  | GetVolumeDiscountAssignmentsFailure

export type VolumeDiscountAssignmentsActions = GetVolumeDiscountAssignmentsActions
