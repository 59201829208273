import { combineReducers } from 'redux'
import discount from './discount'
import newEdit from './newEditDiscount'
import deleteDiscount from './deleteDiscount'
import discountDatesReducer from './discountDates'
import createDiscountDatesReducer from './ABMDiscountDates'
import discountDaysReducer from './discountDays'
import createDiscountDaysReducer from './ABMDiscountDays'
import volumeDiscountPoliciesTableReducer from './volume/policies/table'
import volumeDiscountAssignmentsTableReducer from './volume/assignments/table'
import volumeDiscountModalsAssignmentsReducer from './volume/assignments/modals'
import volumeDiscountPoliciesModalsReducer from './volume/policies/modals'
import policyItemsReducer from './volume/policies/items'

const rootReducer = combineReducers({
  discount,
  newEdit,
  deleteDiscount,
  discountDatesReducer,
  createDiscountDatesReducer,
  discountDaysReducer,
  createDiscountDaysReducer,
  volumeDiscountPoliciesTableReducer,
  volumeDiscountAssignmentsTableReducer,
  volumeDiscountModalsAssignmentsReducer,
  volumeDiscountPoliciesModalsReducer,
  policyItemsReducer
})

export default rootReducer
