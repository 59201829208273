import React, { useMemo } from 'react'
import { DynamicTable } from '../../../components/DynamicTable'
import { Column } from '../../../components/DynamicTable/types/types'
import ClientIDLinkComponent from '../../../components/ClientIDLink/ClientIDLink'
import { CARDS_FILTERS, CLIENT_ROLE_ID, TC_FILTERS } from './constants'
import moment from 'moment'
import { User } from './entities'
import { getFilterProps, getSearchProps, getSortProps, parseDate } from '../../../components/DynamicTable/utils/utils'
import { LinkIcon } from '../../../icons'
import { accountTypes } from '../Users.constants'
import FlagCountry from '../../../components/CountrySelector/component/FlagCountry'
import { getCurrentCountry } from '../../../components/CountrySelector/utils/utils'
import ChangeEmailModal from '../containers/changeEmailModal'
import { Tooltip } from 'antd'
import { TableActionBar } from '../../../components/TableActionBar'
import { CopyButton } from '../../../components/CopyButton'
import { getRangePickerProps } from '../../../components/DynamicTable/utils'
import { UsersTableDateRangeKey, UsersTableProps } from '../types/usersTable'
import MetricsUsers from './usersMetrics'
import styles from './UsersTable.module.scss'
import ResetPasswordModal from '../containers/resetPasswordModal'

const UsersTable: React.FC<UsersTableProps> = ({
  handleSort,
  handleSearch,
  users,
  loading,
  searchFilters,
  pagination,
  actions,
  sorter,
  countries,
  categoryFilter,
  cleanFilters,
  getUsersFromStore,
  setCreateModalOpen,
  openTermsPanel,
  handleFilter,
  onSendCSV,
  roles,
  loadingCSV,
  dateRangeFilters,
  handleDateRangePiker,
  usersMetrics,
  fetchingMetrics
}) => {
  const formatDate = (d: string) => moment(d).format('L LT')

  const rolesFilters = useMemo(() => {
    const notTeamFilter = new Set([CLIENT_ROLE_ID])
    const teamFilterValue: number[] = []

    const commonRolesFilter = roles.map((role) => {
      if (!notTeamFilter.has(role.id)) teamFilterValue.push(role.id)
      return {
        value: role.id,
        text: role.description
      }
    })

    return [...commonRolesFilter, { value: teamFilterValue.join(), text: 'Equipo' }]
  }, [roles])

  const columns: Column<User>[] = [
    {
      key: 'Country',
      label: 'País',
      renderDataCell: ({ Country }) => {
        const flag = getCurrentCountry(countries, Country, 'code')

        if (!flag) return null

        return <FlagCountry country={flag} />
      }
    },
    {
      key: 'RealID',
      label: 'ID',
      tooltip: 'ID Usuario',
      search: getSearchProps('id', 'ID', handleSearch, searchFilters),
      renderDataCell: ({ RealID }) => (
        <ClientIDLinkComponent id={RealID} noPadding={true}>
          {RealID}
        </ClientIDLinkComponent>
      ),
      sort: getSortProps('RealID', sorter, handleSort)
    },
    {
      key: 'Name',
      label: 'Nombre',
      minWidth: '130px',
      renderDataCell: ({ DisableDate, Name }) => {
        if (DisableDate)
          return (
            <div>
              <Tooltip placement="top" title={'Usuario deshabilitado'}>
                <span style={{ color: 'red' }}>{Name}</span>
              </Tooltip>
            </div>
          )

        return Name
      },
      search: getSearchProps('name', 'Nombre', handleSearch, searchFilters),
      sort: getSortProps('Name', sorter, handleSort)
    },
    {
      key: 'LastName',
      label: 'Apellido',
      minWidth: '130px',
      renderDataCell: ({ DisableDate, LastName }) => {
        if (DisableDate) return <span style={{ color: 'red' }}>{LastName}</span>

        return LastName
      },
      search: getSearchProps('lastName', 'Apellido', handleSearch, searchFilters),
      sort: getSortProps('LastName', sorter, handleSort)
    },
    {
      alignment: 'left',
      key: 'CreationDate',
      label: 'Fecha de creación',
      tooltip: 'Fecha de creación',
      rangePicker: getRangePickerProps(UsersTableDateRangeKey.CREATED_AT, dateRangeFilters, handleDateRangePiker),
      renderDataCell: ({ CreationDate }) => parseDate(CreationDate)
    },
    {
      key: 'Phone',
      label: 'Teléfono',
      minWidth: '150px',
      search: getSearchProps('phone', 'Teléfono', handleSearch, searchFilters)
    },
    {
      key: 'Landline',
      label: 'Teléfono alt.',
      minWidth: '135px',
      search: getSearchProps('Landline', 'Teléfono alt.', handleSearch, searchFilters)
    },
    {
      key: 'Email',
      label: 'Email',
      search: getSearchProps('email', 'Mail', handleSearch, searchFilters),
      renderDataCell: ({ Email }) => {
        return (
          <>
            <span style={{ marginRight: 10 }}>{Email}</span>
            <CopyButton textToCopy={Email} tooltipText="Copiado!" />
          </>
        )
      }
    },
    {
      key: 'HasCbu',
      label: 'CBU/CVU',
      minWidth: '120px',
      filters: getFilterProps(CARDS_FILTERS, categoryFilter, 'has_cbu', handleFilter),
      renderDataCell: ({ HasCbu }) => {
        return HasCbu ? 'Sí' : 'No'
      }
    },
    {
      key: 'Cards',
      label: 'Tarjeta',
      renderDataCell: ({ Cards }) => {
        return Cards.length > 0 ? 'Sí' : 'No'
      },
      filters: getFilterProps(CARDS_FILTERS, categoryFilter, 'has_card', handleFilter)
    },
    {
      key: 'AcceptedTerms',
      label: 'TyC',
      renderDataCell: ({ AcceptedTerms, RealID, Name, LastName, Country }) => {
        return AcceptedTerms === '' ? (
          <span>
            No
            <i
              style={{ marginLeft: '4px', cursor: 'pointer' }}
              onClick={() => openTermsPanel(RealID, Name, LastName, Country)}>
              <LinkIcon />
            </i>
          </span>
        ) : (
          <span title={formatDate(AcceptedTerms)}>Sí</span>
        )
      },
      filters: getFilterProps(TC_FILTERS, categoryFilter, 'accepted', handleFilter)
    },
    {
      key: 'Role',
      label: 'Rol',
      renderDataCell: ({ Role }) => `${Role.Description}`,
      filters: getFilterProps(rolesFilters, categoryFilter, 'role', handleFilter)
    },
    {
      key: 'AccountType',
      label: 'Tipo de cuenta',
      minWidth: '80px',
      renderDataCell: ({ AccountType }) => {
        const leadQualification = accountTypes.find((qf) => qf.value === AccountType)
        return `${leadQualification?.label}`
      }
    }
  ]

  const onCleanFilters = () => {
    cleanFilters()
    getUsersFromStore()
  }

  return (
    <>
      <MetricsUsers fetchingMetrics={fetchingMetrics} metrics={usersMetrics} />
      <TableActionBar hideBorder>
        <TableActionBar.Wrapper>
          <TableActionBar.ButtonAction toolTip variant="csv" disabled={loadingCSV} onClickButton={onSendCSV} />
          <TableActionBar.ButtonAction variant="cleanFilters" onClickButton={onCleanFilters} />
        </TableActionBar.Wrapper>
        <TableActionBar.ButtonAction titleButton={'Crear Usuario'} onClickButton={() => setCreateModalOpen(true)} />
      </TableActionBar>
      <DynamicTable
        columns={columns}
        loading={loading}
        rows={users}
        keyExtractor={(entity) => entity.RealID}
        pagination={pagination}
        actions={actions}
        customClassNames={{
          table: styles.customTableProps
        }}
      />
      <ChangeEmailModal />
      <ResetPasswordModal />
    </>
  )
}

export default UsersTable
