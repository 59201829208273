import React from 'react'
import { Button, Input, Modal } from 'antd'
import styles from './validationView.module.scss'
import { ValidationViewDiscount } from '../types/validationView'
import { useLocalization } from '@/packages/localization'

type ModalChangeDiscountProps = {
  setOpenModalChangeDiscount?: (open: boolean) => void
  discount?: ValidationViewDiscount
  setDiscountCode?: (discount: { updateDiscountCode: string }) => void
  fetchDiscount?: (discountCode: string) => void
  handleClearDiscount?: () => void
  handleApplyNewDescount?: () => void
  handleValidateDiscount?: () => void
}

const ModalChangeDiscount = ({
  setOpenModalChangeDiscount,
  discount,
  setDiscountCode,
  fetchDiscount,
  handleClearDiscount,
  handleApplyNewDescount,
  handleValidateDiscount
}: ModalChangeDiscountProps) => {
  const { strings } = useLocalization()
  const ValidationViewStrings = strings.ValidationViewScreen

  const handleCancelModalDiscount = () => {
    setOpenModalChangeDiscount?.(false)
    handleClearDiscount?.()
  }
  return (
    <Modal
      centered
      closable={false}
      visible={discount?.openModal}
      style={{ maxWidth: 500 }}
      onOk={() => handleApplyNewDescount?.()}
      onCancel={handleCancelModalDiscount}
      footer={[
        <Button key="back" onClick={handleCancelModalDiscount}>
          {ValidationViewStrings.modal.buttons.cancel}
        </Button>,
        <Button key="submit" disabled={!discount?.isValid} type="primary" onClick={() => handleApplyNewDescount?.()}>
          {ValidationViewStrings.modal.buttons.apply}
        </Button>
      ]}>
      <div className={styles.discountContainer}>
        <p className={styles.inputTitle}>{strings.ValidationViewScreen.modal.discount.title}</p>
        <div className={styles.discountInputContainer}>
          <Input
            placeholder={strings.ValidationViewScreen.modal.discount.placeholder}
            disabled={!!discount?.discountId && !!discount?.updateDiscountCode && discount?.isValid}
            value={discount?.updateDiscountCode}
            onChange={(e) => setDiscountCode?.({ updateDiscountCode: e.target.value })}
            onPressEnter={() => fetchDiscount?.(discount?.updateDiscountCode || '')}
          />
          <Button
            className={styles.discountButton}
            type="primary"
            disabled={!discount?.updateDiscountCode || discount?.isValid}
            onClick={() => {
              handleValidateDiscount?.()
            }}>
            {strings.ValidationViewScreen.modal.discount.verify}
          </Button>
        </div>

        {discount?.errorFetch && <div className={styles.error}>{discount?.errorFetch}</div>}
        {discount?.isValid && (
          <div className={styles.errorContainer}>
            <span className={styles.valid}>{strings.ValidationViewScreen.modal.discount.validCode}</span>
          </div>
        )}
      </div>
    </Modal>
  )
}

export default ModalChangeDiscount
