import { apiPrivate } from '@/api'
import { URL_BASE_TRANSACTIONS } from '@/endpoints'
import snakecaseKeys from 'snakecase-keys'
import { APIResponseGetAndUpdate } from './common'

export type Params = {
  name: string
  active: boolean
  durationInDays: number
}

export function create(params: Params): Promise<void> {
  const paramsSnake = snakecaseKeys(params)

  return apiPrivate
    .post<APIResponseGetAndUpdate>(`${URL_BASE_TRANSACTIONS}/volume-discount-policies`, paramsSnake)
    .then(() => {})
    .catch((error) => {
      throw error
    })
}
