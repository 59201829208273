const routes = {
  '/': 'Home',
  '/login': 'Login',
  '/users': 'Usuarios',
  '/requests': 'Solicitudes',
  '/salesRequest': 'Solicitud de ventas',
  '/agents': 'Agentes',
  '/origins': 'Orígenes',
  '/objects': 'Objetos',
  '/objectChanges': 'Cambios del objeto',
  '/corporative/products': 'Productos',
  '/products': 'Productos',
  '/attributes': 'Atributos',
  '/newAttribute': 'Crear atributo',
  '/categories': 'Categorías',
  '/flags': 'Flags de configuración',
  '/transactions': 'Pedidos',
  '/trips': 'Viajes',
  '/register_objects': 'Registrar objetos',
  '/photograph_objects': 'Fotografiar objetos',
  '/movements': 'Movimientos',
  '/faq_categories': 'Categorías de FAQ',
  '/faq_questions': 'Preguntas y respuestas',
  '/dashboard': 'Inicio',
  '/payments': 'Reglas de cobro',
  '/payments_history': 'Actividad de cobranza',
  '/deposits': 'Ingresos',
  '/removalsValidation': 'Devoluciones a validar',
  '/depositsValidation': 'Ingresos a validar',
  '/removals': 'Devoluciones',
  '/newDeposit': 'Nuevo retiro',
  '/newRemoval': 'Nueva devolución',
  '/leads': 'Leads',
  '/proposals': 'Cotizaciones',
  '/newProposal': 'Nuevo ingreso',
  '/proposalDetail': 'Detalles de una cotización',
  '/billing': 'Facturación',
  '/billingByClient': 'Facturación / Por cliente',
  '/discount': 'Descuentos',
  '/address': 'Direcciones',
  '/services': 'Tipos de servicios',
  '/discounts': 'Descuentos',
  '/volumeDiscounts': 'Descuentos por volumen',
  '/discountDates': 'Descuentos para fechas específicas',
  '/discountDays': 'Calendario de descuentos',
  '/locations': 'Ubicaciones',
  '/corporative/locations': 'Ubicaciones',
  '/storageDeposits': 'Depósitos',
  '/corporative/deposits': 'Depósitos',
  '/pendingUsers': 'Invitaciones',
  '/corporateUsers': 'Usuarios Corporativos',
  '/pickings': 'Búsquedas',
  '/priceBands': 'Franjas de precios',
  '/billing_payment': 'Pagos',
  '/billings_payments_list': 'Facturación',
  '/billing_payment_process': '',
  '/corporative/objects': 'Objetos',
  '/subscriptions': 'Abonos',
  '/providers': 'Proveedores',
  '/shippers': 'Transportistas',
  '/operation_days': 'Operaciones según días de la semana',
  '/operation_dates': 'Operaciones según fechas específicas',
  '/corporative/objectChanges': 'Historial de cambios del objeto',
  '/vehicles': 'Vehículos',
  '/donations': 'Solicitud de donaciones',
  '/development': 'Desarrollo',
  '/product/catalogue': 'Catálogo de productos',
  '/complaints': 'Reclamos'
}
export default routes
