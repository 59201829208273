import React from 'react'
import VolumeDiscountPoliciesAMModal from '@/sections/Discounts/components/volume/policies/CreationModal'
import { useDispatch, useSelector } from 'react-redux'
import volumeDiscountPoliciesABMActionsCreator from '@/sections/Discounts/actions/volume/policies/modals'
import { bindActionCreators } from 'redux'
import { Policy } from '@/sections/Discounts/types/volume/policies/modals'
import volumeDiscountPoliciesTableActionsCreator from '@/sections/Discounts/actions/volume/policies/table'
import ActivationModal from '@/sections/Discounts/components/volume/policies/ActivationModal'
import { sendToastNotificationInfo } from '@/utils/notifications'
import { useLocalization } from '@/packages/localization'

const VolumeDiscountPoliciesModalsContainer = () => {
  const ModalsState = useSelector((state) => state.Discount.volumeDiscountPoliciesModalsReducer)
  const tableState = useSelector((state) => state.Discount.volumeDiscountPoliciesTableReducer)
  const { strings } = useLocalization()

  const dispatch = useDispatch()

  const { setModalVisibility, setPolicyName, setPolicyState, setPolicyDuration, createPolicy, updatePolicy } =
    bindActionCreators(volumeDiscountPoliciesABMActionsCreator, dispatch)

  const { getDiscounts } = bindActionCreators(volumeDiscountPoliciesTableActionsCreator, dispatch)

  const handleCreatePolicy = (policy: Policy) => {
    const { name, durationInDays, active } = policy

    if (!name || !durationInDays) return

    const refetchTable = () =>
      getDiscounts({
        pagination: tableState.pagination
      })

    createPolicy({ name, active, durationInDays }, refetchTable)
  }

  const handleUpdatePolicy = () => {
    const { id, active } = ModalsState.currentPolicy

    if (!id) return sendToastNotificationInfo(strings.Discounts.Volume.Policies.modal.errors.updateError)

    const refetchTable = () =>
      getDiscounts({
        pagination: tableState.pagination
      })

    updatePolicy({ active: !active, policyId: id }, refetchTable)
  }

  const disabledCreateButton = ModalsState.creatingPolicy
  const disabledUpdateButton = ModalsState.updatingPolicy

  const creationModalProps = {
    currentPolicy: ModalsState.currentPolicy,
    modalVisible: ModalsState.modalVisible,
    disabledCreateButton,
    setModalVisibility,
    setPolicyName,
    setPolicyState,
    setPolicyDuration,
    handleCreatePolicy
  }

  const activationModalProps = {
    currentPolicy: ModalsState.currentPolicy,
    modalVisible: ModalsState.activationModalVisible,
    disabledUpdateButton,
    setModalVisibility,
    handleUpdatePolicy
  }

  return (
    <>
      {ModalsState.modalVisible ? <VolumeDiscountPoliciesAMModal {...creationModalProps} /> : null}
      {ModalsState.activationModalVisible ? <ActivationModal {...activationModalProps} /> : null}
    </>
  )
}

export default VolumeDiscountPoliciesModalsContainer
