import React from 'react'
import { Modal } from 'antd'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import styles from './CreationModal.module.scss'
import { Policy } from '@/sections/Discounts/types/volume/policies/modals'
import { useLocalization } from '@/packages/localization'

interface VolumeDiscountPoliciesCreationModalProps {
  currentPolicy: Policy
  modalVisible: boolean
  disabledCreateButton: boolean
  setModalVisibility: ({ modalVisible }: { modalVisible: boolean }) => void
  setPolicyName: ({ policyName }: { policyName: string }) => void
  setPolicyState: ({ policyState }: { policyState: boolean }) => void
  setPolicyDuration: ({ policyDuration }: { policyDuration: number }) => void
  handleCreatePolicy: (policy: Policy) => void
}

const VolumeDiscountPoliciesCreationModal: React.FC<VolumeDiscountPoliciesCreationModalProps> = ({
  currentPolicy,
  modalVisible,
  disabledCreateButton,
  setModalVisibility,
  setPolicyName,
  setPolicyState,
  setPolicyDuration,
  handleCreatePolicy
}) => {
  const { strings } = useLocalization()

  const modalStrings = strings.Discounts.Volume.Policies.modal

  const minNameLength = 2
  const maxNameLength = 50

  const CategorySchema = Yup.object().shape({
    name: Yup.string()
      .min(minNameLength, modalStrings.errors.nameMinLength(minNameLength))
      .max(maxNameLength, modalStrings.errors.nameMaxLength(maxNameLength))
      .required(modalStrings.errors.nameRequired),
    durationInDays: Yup.number()
      .required(modalStrings.errors.durationRequired)
      .positive(modalStrings.errors.durationPositive)
      .integer(modalStrings.errors.durationInteger),
    active: Yup.boolean()
  })

  const modalTitle = modalStrings.title
  const submitButton = modalStrings.submit

  const { values, errors, touched, submitForm, handleChange, setFieldTouched } = useFormik({
    initialValues: {
      name: currentPolicy.name,
      durationInDays: currentPolicy.durationInDays,
      active: currentPolicy.active
    },
    validationSchema: CategorySchema,
    onSubmit: (values) => {
      handleCreatePolicy(values)
    }
  })

  return (
    <Modal
      title={modalTitle}
      visible={modalVisible}
      okButtonProps={{
        htmlType: 'submit',
        onClick: submitForm,
        disabled: !values.name || !values.durationInDays || Object.keys(errors).length > 0 || disabledCreateButton
      }}
      okText={submitButton}
      cancelText={strings.generic.cancel}
      onCancel={() => {
        setModalVisibility({ modalVisible: false })
      }}>
      <form className={styles.form}>
        <div className={styles.inputContainer}>
          <label htmlFor="name">{modalStrings.fields.name.label}:</label>
          <input
            id="name"
            name="name"
            type="text"
            placeholder={modalStrings.fields.name.placeholder}
            value={values.name}
            onChange={(e) => {
              setFieldTouched('name', true)
              handleChange(e)
              setPolicyName({ policyName: e.target.value })
            }}
          />
          {errors.name && touched.name ? <span className={styles.error}>{errors.name}</span> : null}
        </div>
        <div className={styles.inputContainer}>
          <label htmlFor="durationInDays">{modalStrings.fields.duration.label}:</label>
          <input
            id="durationInDays"
            name="durationInDays"
            type="number"
            placeholder={modalStrings.fields.duration.placeholder}
            value={values.durationInDays}
            onChange={(e) => {
              setFieldTouched('durationInDays', true)
              handleChange(e)
              setPolicyDuration({ policyDuration: parseInt(e.target.value) })
            }}
          />
          {errors.durationInDays && touched.durationInDays ? (
            <span className={styles.error}>{errors.durationInDays}</span>
          ) : null}
        </div>
        <div className={styles.inputCheckbox}>
          <label htmlFor="active">{modalStrings.fields.isEnabled.label}:</label>
          <input
            name="active"
            type="checkbox"
            checked={values.active}
            onChange={(e) => {
              handleChange(e)
              setPolicyState({ policyState: e.target.checked })
            }}
          />
        </div>
      </form>
    </Modal>
  )
}

export default VolumeDiscountPoliciesCreationModal
