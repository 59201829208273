export enum Permissions {
  MostrarTodosLosPaises = 'MostrarTodosLosPaises',
  ModificarObjeto = 'ModificarObjeto',
  VerProductos = 'VerProductosBO',
  VerCategorias = 'VerCategoriasBO',
  VerFlags = 'VerFlagsBO',
  VerTiposDeServicios = 'VerTiposDeServiciosBO',
  VerDescuentos = 'VerDescuentosBO',
  VerDescuentosPorVolumen = 'VisualizarPoliticasDeDescuentosPorVolumen',
  VerCalendarioDeOperaciones = 'ABMDiasDeOperaciones',
  VerOportunidades = 'VerOportunidadesBO',
  VerUsuarios = 'VerUsuariosBO',
  VerCotizaciones = 'VerCotizacionesBO',
  VerAgentes = 'VerAgentesBO',
  VerObjetos = 'VerObjetosBO',
  VerObjetosCorporativos = 'VerObjetosCorporativosBO',
  VerSolicitudDeVentas = 'VerSolicitudDeVentasBO',
  VerSolicitudesGenericas = 'VerSolicitudesGenericas',
  VerIngresos = 'VerIngresosBO',
  VerBusquedas = 'VerBusquedasBO',
  VerDevoluciones = 'VerDevolucionesBO',
  VerRegistrarObjetos = 'VerRegistrarObjetosBO',
  VerFotografiarObjetos = 'VerFotografiarObjetosBO',
  VerValidarIngresos = 'VerValidarIngresosBO',
  VerValidarDevoluciones = 'VerValidarDevolucionesBO',
  VerTransacciones = 'VerTransaccionesBO',
  VerViajes = 'VerViajesBO',
  VerDepositos = 'VerDepositosBO',
  VerDepositosCorp = 'VerDepositos',
  VerTodosLosDepositos = 'VerTodosLosDepositos',
  VerDepositosCorporativos = 'VerDepositosCorporativos',
  VerMovimientos = 'VerMovimientosBO',
  VerFacturacion = 'VerFacturacionBO',
  VerReglas = 'VerReglasBO',
  VerActividad = 'VerActividadBO',
  VerOrigenes = 'VerOrigenesBO',
  VerProductosCorporativosBO = 'VerProductosCorporativosBO',
  VerFranjasDePrecios = 'VerFranjasDePreciosBO',
  EliminarBandaDePrecio = 'EliminarBandaDePrecio',
  CrearBandaDePrecio = 'CrearBandaDePrecio',
  EditarBandaDePrecio = 'EditarBandaDePrecio',
  EditarFechaServicio = 'EditarFechaServicio',
  ConsultarDeudasTerceros = 'ConsultarDeudasTerceros',
  VisualizarPaymentMethodUsuario = 'VisualizarPaymentMethodUsuario',
  CrearDevolucionPropia = 'CrearDevolucionPropia',
  VerUsuariosCuentaCorporativa = 'VerUsuariosCuentaCorporativa',
  PagarDeuda = 'PagarDeuda',
  VisualizarMisInvitaciones = 'VisualizarMisInvitaciones',
  CrearUbicacionAdmin = 'CrearUbicacionAdmin',
  EditarUbicacionAdmin = 'EditarUbicacionAdmin',
  EliminarUbicacionAdmin = 'EliminarUbicacionAdmin',
  GenerarReporteDeAtributos = 'GenerarReporteDeAtributos',
  VerUbicacionesBO = 'VerUbicacionesBO',
  CrearUbicacionCorporativo = 'CrearUbicacionCorporativo',
  EditarUbicacionCorporativo = 'EditarUbicacionCorporativo',
  EliminarUbicacionCorporativo = 'EliminarUbicacionCorporativo',
  SolicitudDeDonacionOperario = 'SolicitudDeDonacionOperario',
  VisualizarProveedores = 'VisualizarProveedores',
  ABMTransportistas = 'ABMTransportistas',
  ABMVehiculos = 'ABMVehiculos',
  ListarReclamos = 'ListarReclamos',
  ComplaintsView = 'ActualizarReclamo'
}
