import { setToastErrorUpdate, setToastLoading, setToastSuccessUpdate } from '@/utils/notifications'
import { API } from '../../../projectApi'
import { AppThunk } from '../../../store'
import { handleErrorsWithAction } from '../../../utils/HandleErrors'
import {
  OpenResetPasswordModalTypes,
  ResetPasswordRequest,
  ResetPasswordSuccess,
  ResetPasswordTypes
} from '../types/resetPassword'

const ResetPasswordModalActionCreators = {
  setOpen:
    (open: boolean, userData?: { id: number; name: string; lastName: string; email: string }): AppThunk =>
    (dispatch) => {
      dispatch({
        type: OpenResetPasswordModalTypes.OPEN_RESET_PASSWORD_MODAL,
        payload: { open, userData }
      })
    },

  resetPassword:
    (userId: string): AppThunk =>
    async (dispatch) => {
      const toast = setToastLoading('Restableciendo contraseña, por favor espere...')
      const request: ResetPasswordRequest = {
        type: ResetPasswordTypes.RESET_PASSWORD_REQUEST
      }

      dispatch(request)

      try {
        await API.AccountManager.resetPassword(userId)
        const success: ResetPasswordSuccess = {
          type: ResetPasswordTypes.RESET_PASSWORD_SUCCESS
        }
        setToastSuccessUpdate(toast, { render: 'Contraseña restablecida con exito!' })
        dispatch(success)
      } catch (error) {
        setToastErrorUpdate(toast, {
          render: handleErrorsWithAction(error, ResetPasswordTypes.RESET_PASSWORD_FAILURE, dispatch)
        })
      }
    }
}

export default ResetPasswordModalActionCreators
