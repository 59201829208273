export type CancelDonationModalStateType = {
  id: string
  name: string
  lastName: string
  isOpen: boolean
  isLoading: boolean
  reasons: { id: number; description: string }[]
  error: string
}

export enum CommonTypes {
  SET_LOADING = 'DONATIONS/CANCEL_DONATION_MODAL/SET_LOADING',
  SET_OPEN = 'DONATIONS/CANCEL_DONATION_MODAL/SET_OPEN',
  CLEAN_STATE = 'DONATIONS/CANCEL_DONATION_MODAL/CLEAN_STATE'
}

export interface SetLoading {
  type: CommonTypes.SET_LOADING
  payload: {
    isLoading: boolean
  }
}

export interface SetOpen {
  type: CommonTypes.SET_OPEN
  payload: {
    isOpen: boolean
    name?: string
    lastName?: string
    id?: string
  }
}

export interface CleanState {
  type: CommonTypes.CLEAN_STATE
}

type CommonListActions = SetLoading | SetOpen | CleanState

export enum CancelDonationModalTypes {
  CANCEL_DONATION_REQUEST = 'DONATIONS/CANCEL_DONATION_MODAL/CANCEL_DONATION_REQUEST',
  CANCEL_DONATION_SUCCESS = 'DONATIONS/CANCEL_DONATION_MODAL/CANCEL_DONATION_SUCCESS',
  CANCEL_DONATION_FAILURE = 'DONATIONS/CANCEL_DONATION_MODAL/CANCEL_DONATION_FAILURE'
}

export interface CancelDonationRequest {
  type: CancelDonationModalTypes.CANCEL_DONATION_REQUEST
}

export interface CancelDonationSuccess {
  type: CancelDonationModalTypes.CANCEL_DONATION_SUCCESS
}

export interface CancelDonationFailure {
  type: CancelDonationModalTypes.CANCEL_DONATION_FAILURE
  payload: {
    error: string
  }
}

export type CancelDonationActions = CancelDonationRequest | CancelDonationSuccess | CancelDonationFailure

export enum ReasonCancelDonationTypes {
  REASONS_CANCEL_DONATION_REQUEST = 'DONATIONS/CANCEL_DONATION_MODAL/REASONS_CANCEL_DONATION_REQUEST',
  REASONS_CANCEL_DONATION_SUCCESS = 'DONATIONS/CANCEL_DONATION_MODAL/REASONS_CANCEL_DONATION_SUCCESS',
  REASONS_CANCEL_DONATION_FAILURE = 'DONATIONS/CANCEL_DONATION_MODAL/REASONS_CANCEL_DONATION_FAILURE'
}

export interface ReasonCancelDonationRequest {
  type: typeof ReasonCancelDonationTypes.REASONS_CANCEL_DONATION_REQUEST
}

export interface ReasonCancelDonationSuccess {
  type: typeof ReasonCancelDonationTypes.REASONS_CANCEL_DONATION_SUCCESS
  payload: {
    reasons: { id: number; description: string }[]
  }
}

export interface ReasonCancelDonationFailure {
  type: typeof ReasonCancelDonationTypes.REASONS_CANCEL_DONATION_FAILURE
  payload: { error: string }
}

export type ReasonCancelDonationAction =
  | ReasonCancelDonationRequest
  | ReasonCancelDonationSuccess
  | ReasonCancelDonationFailure

export type CancelDonationModalActions = CommonListActions | CancelDonationActions | ReasonCancelDonationAction
