import React from 'react'
import { getFilterProps, getSearchProps } from '@/components/DynamicTable/utils'
import { MenuAction } from '@/components/actionMenu/baseMenu'
import { DynamicTable } from '@/components/DynamicTable'
import { Column, Pagination } from '@/components/DynamicTable/types/types'
import { useLocalization } from '@/packages/localization'
import {
  VolumeDiscountItem,
  VolumeDiscountPolicy
} from '@/projectApi/TransactionHandler/Discounts/volume/policies/common'
import {
  PoliciesCategoryFilter,
  PoliciesSearchFilter,
  PoliciesSearchKey
} from '@/sections/Discounts/types/volume/policies/table'

export type VolumeDiscountsTableProps = {
  volumeDiscountPolicies: VolumeDiscountPolicy[]
  fetchingDiscounts: boolean
  pagination: Pagination
  actions: MenuAction<VolumeDiscountPolicy>[]
  searchFilters: PoliciesSearchFilter[]
  categoryFilter: PoliciesCategoryFilter
  handleSearch: (key: PoliciesSearchKey, newValue: string) => void
  handleCategoryFilter: (newCategoryFilter: PoliciesCategoryFilter) => void
}

export const VolumeDiscountsTable: React.FC<VolumeDiscountsTableProps> = ({
  volumeDiscountPolicies,
  fetchingDiscounts,
  pagination,
  actions,
  searchFilters,
  categoryFilter,
  handleSearch,
  handleCategoryFilter
}) => {
  const { strings } = useLocalization()

  const filterOptions = [
    { value: 'true', text: 'Activos' },
    { value: 'false', text: 'Inactivos' }
  ]

  const columns: Column<VolumeDiscountPolicy>[] = [
    {
      key: 'id',
      label: 'ID',
      search: getSearchProps('id', 'ID', handleSearch, searchFilters)
    },
    {
      key: 'name',
      label: strings.generic.name
    },
    {
      key: 'durationInDays',
      label: strings.Discounts.Volume.Policies.table.durationInDays
    },
    {
      key: 'active',
      label: strings.generic.status,
      filters: getFilterProps(filterOptions, categoryFilter, 'isActive', handleCategoryFilter),
      renderDataCell: ({ active }) => (active ? 'Activo' : 'Inactivo')
    }
  ]

  return (
    <DynamicTable
      columns={columns}
      loading={fetchingDiscounts}
      rows={volumeDiscountPolicies}
      keyExtractor={(entity) => entity.id}
      pagination={pagination}
      actions={actions}
      rowExpander={{
        render: (policy) => (
          <VolumeDiscountsItemsTable
            volumeDiscountItems={policy.volumeDiscountItems}
            fetchingDiscounts={fetchingDiscounts}
          />
        ),
        triggerFunctionOnce: false
      }}
    />
  )
}

type VolumeDiscountsItemsTableProps = {
  volumeDiscountItems: VolumeDiscountItem[]
  fetchingDiscounts: boolean
}

const VolumeDiscountsItemsTable: React.FC<VolumeDiscountsItemsTableProps> = ({
  volumeDiscountItems,
  fetchingDiscounts
}) => {
  const { strings } = useLocalization()

  const renderM3Min = (m3Min: number, index: number) => {
    if (index === volumeDiscountItems.length - 1) {
      return `+${m3Min} m3`
    }

    return `${m3Min} a ${volumeDiscountItems[index + 1].m3Min} m³`
  }

  const columns: Column<VolumeDiscountItem>[] = [
    {
      key: 'id',
      label: 'ID'
    },
    {
      key: 'm3Min',
      label: strings.Discounts.Volume.Policies.table.minM3,
      renderDataCell: ({ m3Min }, index) => renderM3Min(m3Min, index)
    },
    {
      key: 'discountPercent',
      label: strings.generic.discount,
      renderDataCell: ({ discountPercentage }) => `${discountPercentage}%`
    }
  ]

  return (
    <DynamicTable
      columns={columns}
      loading={fetchingDiscounts}
      rows={volumeDiscountItems}
      keyExtractor={(entity) => entity.id}
    />
  )
}
