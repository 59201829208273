import snakecaseKeys from 'snakecase-keys'
import { apiPrivate } from '../../../api'
import { URL_BASE_TRANSACTIONS } from '../../../endpoints'
import { Moment } from 'moment'

export interface ExcludeRequest {
  withDays?: boolean
  userId: number
  reasonId?: number
  periodFrom?: Moment
  periodTo?: Moment
  enabled: boolean
}

export function exclude(params: ExcludeRequest): Promise<void> {
  const { periodFrom, periodTo, withDays, ...restOfParams } = params
  const snakeParams = snakecaseKeys({
    ...restOfParams,
    dayFrom: withDays ? periodFrom?.date() : undefined,
    dayTo: withDays ? periodTo?.date() : undefined,
    monthFrom: periodFrom?.isValid() ? periodFrom.month() + 1 : undefined,
    yearFrom: periodFrom?.year(),
    monthTo: periodTo?.isValid() ? periodTo.month() + 1 : undefined,
    yearTo: periodTo?.year()
  })

  return apiPrivate
    .put(`${URL_BASE_TRANSACTIONS}/user-billing-information/exclude`, snakeParams)
    .then(() => {})
    .catch((err) => {
      throw err
    })
}
