import { apiPrivate } from '@/api'
import { URL_BASE_TRANSACTIONS } from '@/endpoints'
import snakecaseKeys from 'snakecase-keys'

export interface Params {
  policyId: number
  m3Min: number
  discountPercentage: number
}

export function createItem({ policyId, ...restParams }: Params): Promise<void> {
  const formattedParams = {
    volume_discount_policy_id: policyId,
    ...snakecaseKeys(restParams)
  }

  return apiPrivate
    .post(`${URL_BASE_TRANSACTIONS}/volume-discount-items`, formattedParams)
    .then(() => {})
    .catch((error) => {
      throw error
    })
}
