import { apiPrivate } from '@/api'
import { URL_BASE_TRANSACTIONS } from '@/endpoints'
import snakecaseKeys from 'snakecase-keys'
import { VolumeDiscountPolicy } from './common'

type AssignedUser = {
  id: number
  name: string
  last_name: string
  country_code: string
  email: string
}

type APIResponse = {
  content: {
    id: number
    volume_discount_policy_id: number
    assigned_at: string
    expires_at: string
    active: boolean
    assigned_user: AssignedUser
    discount_values: {
      volume_discount_policy: VolumeDiscountPolicy
    }
  }
}

export type AssignUserDiscountParams = {
  userId: number
  volumeDiscountPolicyId: number
}

export function assignUserDiscount(params: AssignUserDiscountParams): Promise<APIResponse> {
  const paramsSnake = snakecaseKeys(params)

  return apiPrivate
    .post<APIResponse>(`${URL_BASE_TRANSACTIONS}/user-volume-discounts`, paramsSnake)
    .then((response) => response.data)
    .catch((error) => {
      throw error
    })
}
