export type Policy = {
  id?: number
  name?: string
  active: boolean
  durationInDays?: number
}

export type ABMPoliciesState = {
  modalVisible: boolean
  modalItemVisible: boolean
  activationModalVisible: boolean
  creatingPolicy: boolean
  updatingPolicy: boolean
  creatingPolicyItem: boolean
  currentPolicy: Policy
}

export enum ModalsPoliciesTypes {
  SET_MODAL_VISIBILITY = 'DISCOUNTS/POLICIES/SET_MODAL_VISIBILITY',
  SET_ACTIVATION_MODAL_VISIBILITY = 'DISCOUNTS/POLICIES/SET_ACTIVATION_MODAL_VISIBILITY',
  SET_POLICY_NAME = 'DISCOUNTS/POLICIES/SET_POLICY_NAME',
  SET_POLICY_STATE = 'DISCOUNTS/POLICIES/SET_POLICY_STATE',
  SET_POLICY_DURATION = 'DISCOUNTS/POLICIES/SET_POLICY_DURATION',
  SET_CURRENT_POLICY = 'DISCOUNTS/POLICIES/SET_CURRENT_POLICY'
}
export interface SetModalVisibility {
  type: typeof ModalsPoliciesTypes.SET_MODAL_VISIBILITY
  payload: { modalVisible: boolean }
}

export interface SetActivationModalVisibility {
  type: typeof ModalsPoliciesTypes.SET_ACTIVATION_MODAL_VISIBILITY
  payload: { activationModalVisible: boolean }
}

export interface SetPolicyName {
  type: typeof ModalsPoliciesTypes.SET_POLICY_NAME
  payload: { policyName: string }
}

export interface SetPolicyState {
  type: typeof ModalsPoliciesTypes.SET_POLICY_STATE
  payload: { policyState: boolean }
}

export interface SetPolicyDuration {
  type: typeof ModalsPoliciesTypes.SET_POLICY_DURATION
  payload: { policyDuration: number }
}

export interface SetCurrentPolicy {
  type: typeof ModalsPoliciesTypes.SET_CURRENT_POLICY
  payload: { currentPolicy: Policy }
}

type ModalsPoliciesActions =
  | SetModalVisibility
  | SetPolicyName
  | SetPolicyState
  | SetPolicyDuration
  | SetCurrentPolicy
  | SetActivationModalVisibility

export enum CreateDiscountPolicyType {
  CREATE_POLICY_REQUEST = 'DISCOUNTS/POLICIES/CREATE_POLICY_REQUEST',
  CREATE_POLICY_SUCCESS = 'DISCOUNTS/POLICIES/CREATE_POLICY_SUCCESS',
  CREATE_POLICY_FAILURE = 'DISCOUNTS/POLICIES/CREATE_POLICY_FAILURE'
}

export interface CreatePolicyRequest {
  type: typeof CreateDiscountPolicyType.CREATE_POLICY_REQUEST
}

export interface CreatePolicySuccess {
  type: typeof CreateDiscountPolicyType.CREATE_POLICY_SUCCESS
}

export interface CreatePolicyFailure {
  type: typeof CreateDiscountPolicyType.CREATE_POLICY_FAILURE
}

type CreatePolicyActions = CreatePolicyRequest | CreatePolicySuccess | CreatePolicyFailure

export enum UpdateDiscountPolicyType {
  UPDATE_POLICY_REQUEST = 'DISCOUNTS/POLICIES/UPDATE_POLICY_REQUEST',
  UPDATE_POLICY_SUCCESS = 'DISCOUNTS/POLICIES/UPDATE_POLICY_SUCCESS',
  UPDATE_POLICY_FAILURE = 'DISCOUNTS/POLICIES/UPDATE_POLICY_FAILURE'
}

export interface UpdatePolicyRequest {
  type: typeof UpdateDiscountPolicyType.UPDATE_POLICY_REQUEST
}

export interface UpdatePolicySuccess {
  type: typeof UpdateDiscountPolicyType.UPDATE_POLICY_SUCCESS
}

export interface UpdatePolicyFailure {
  type: typeof UpdateDiscountPolicyType.UPDATE_POLICY_FAILURE
}

type UpdatePolicyActions = UpdatePolicyRequest | UpdatePolicySuccess | UpdatePolicyFailure

export type ABMPoliciesActions = ModalsPoliciesActions | CreatePolicyActions | UpdatePolicyActions
