import React from 'react'
import { TableActionBar } from '@/components/TableActionBar'
import { useLocalization } from '@/packages/localization'
import { useDispatch } from 'react-redux'
import { bindActionCreators } from 'redux'
import volumeDiscountPoliciesABMActionsCreator from '@/sections/Discounts/actions/volume/policies/modals'
import volumeDiscountPoliciesTableActionsCreator from '@/sections/Discounts/actions/volume/policies/table'
import { initialStatePolicies } from '@/sections/Discounts/reducers/volume/policies/table'
import { useHistory } from 'react-router'

export const TableActionBarContainer = () => {
  const history = useHistory()
  const dispatch = useDispatch()
  const { strings } = useLocalization()

  const { setModalVisibility } = bindActionCreators(volumeDiscountPoliciesABMActionsCreator, dispatch)
  const { getDiscounts } = bindActionCreators(volumeDiscountPoliciesTableActionsCreator, dispatch)

  const handleResetFilters = () => {
    getDiscounts({
      pagination: initialStatePolicies.pagination,
      searchFilters: initialStatePolicies.searchFilters,
      categoryFilter: initialStatePolicies.categoryFilter
    })
  }

  return (
    <TableActionBar customStyles={{ padding: 12 }}>
      <TableActionBar.ButtonAction onClickButton={handleResetFilters} variant="cleanFilters" />

      <TableActionBar.Wrapper>
        <TableActionBar.ButtonAction
          customIcon={<></>}
          titleButton={strings.Discounts.Volume.Policies.actionBar.usersRedirect}
          onClickButton={() => history.push('/volumeDiscountsAssignments')}
        />
        <TableActionBar.ButtonAction
          titleButton={strings.Discounts.Volume.Policies.actionBar.create}
          onClickButton={() => setModalVisibility({ modalVisible: true })}
        />
      </TableActionBar.Wrapper>
    </TableActionBar>
  )
}
