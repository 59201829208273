import React from 'react'
import { Modal } from 'antd'
import * as Yup from 'yup'
import { useLocalization } from '@/packages/localization'
import { useFormik } from 'formik'
import styles from './CreationModal.module.scss'
import { PolicyItem } from '@/sections/Discounts/types/volume/policies/items'

export interface PolicyItemModalProps {
  modalItemVisible: boolean
  disabledActionButton: boolean
  currentPolicyItem: PolicyItem
  setModalItemVisibility: ({ modalItemVisible }: { modalItemVisible: boolean }) => void
  setMinRangeValue: (minRange: number) => void
  setDiscountPercentageValue: (discountPercentage: number) => void
  handleConfirmPolicyItem: (policy: PolicyItem) => void
}

const PolicyItemModal: React.FC<PolicyItemModalProps> = ({
  modalItemVisible,
  disabledActionButton,
  currentPolicyItem,
  setModalItemVisibility,
  setMinRangeValue,
  setDiscountPercentageValue,
  handleConfirmPolicyItem
}) => {
  const { strings } = useLocalization()

  const sectionStrings = strings.Discounts.Volume.Policies.itemModal
  const maxDiscountPercentage = 100

  const ItemSchema = Yup.object().shape({
    minRange: Yup.number()
      .required(sectionStrings.errors.minRangeRequired)
      .positive(sectionStrings.errors.minRangePositive),
    discountPercentage: Yup.number()
      .required(sectionStrings.errors.discountPercentageRequired)
      .positive(sectionStrings.errors.discountPercentagePositive)
      .integer(sectionStrings.errors.discountPercentageInteger)
      .max(maxDiscountPercentage, sectionStrings.errors.discountPercentageMax(maxDiscountPercentage))
  })

  const { values, errors, touched, submitForm, handleChange, setFieldTouched } = useFormik({
    initialValues: {
      minRange: currentPolicyItem.minRange,
      discountPercentage: currentPolicyItem.discountPercentage
    },
    validationSchema: ItemSchema,
    onSubmit: (values) => {
      handleConfirmPolicyItem(values)
    }
  })

  return (
    <Modal
      title={'Añadir descuento'}
      okButtonProps={{
        htmlType: 'submit',
        onClick: submitForm,
        disabled:
          !values.minRange || !values.discountPercentage || Object.keys(errors).length > 0 || disabledActionButton
      }}
      okText={strings.Discounts.Volume.Policies.itemModal.submit}
      cancelText={strings.Discounts.Volume.Policies.itemModal.cancel}
      visible={modalItemVisible}
      onCancel={() => setModalItemVisibility({ modalItemVisible: false })}>
      <form className={styles.form}>
        <div className={styles.inputContainer}>
          <label htmlFor="name">{sectionStrings.fields.minRange.label}:</label>
          <input
            id="minRange"
            name="minRange"
            type="number"
            min={0 || undefined}
            placeholder={sectionStrings.fields.minRange.placeholder}
            value={values.minRange}
            onChange={(e) => {
              setFieldTouched('minRange', true)
              handleChange(e)
              setMinRangeValue(Number(e.target.value))
            }}
          />
          <span className={styles.fieldTip}>Minimo valor de almacenaje pretendido para entrar en el rango</span>
          {errors.minRange && touched.minRange ? <span className={styles.error}>{errors.minRange}</span> : null}
        </div>
        <div className={styles.inputContainer}>
          <label htmlFor="durationInDays">{sectionStrings.fields.discountPercentage.label}:</label>
          <input
            id="discountPercentage"
            name="discountPercentage"
            type="number"
            min={0}
            placeholder={sectionStrings.fields.discountPercentage.placeholder}
            value={values.discountPercentage}
            onChange={(e) => {
              setFieldTouched('discountPercentage', true)
              handleChange(e)
              setDiscountPercentageValue(parseInt(e.target.value))
            }}
          />
          {errors.discountPercentage && touched.discountPercentage ? (
            <span className={styles.error}>{errors.discountPercentage}</span>
          ) : null}
        </div>
      </form>
    </Modal>
  )
}

export default PolicyItemModal
