import { Reducer } from 'redux'
import {
  ABMPoliciesActions,
  ABMPoliciesState,
  ModalsPoliciesTypes,
  CreateDiscountPolicyType,
  UpdateDiscountPolicyType
} from '@/sections/Discounts/types/volume/policies/modals'

const initialState: ABMPoliciesState = {
  modalVisible: false,
  modalItemVisible: false,
  activationModalVisible: false,
  creatingPolicy: false,
  updatingPolicy: false,
  creatingPolicyItem: false,
  currentPolicy: {
    name: undefined,
    active: true,
    durationInDays: undefined
  }
}

const VolumeDiscountPoliciesABMReducer: Reducer<ABMPoliciesState, ABMPoliciesActions> = (
  state = initialState,
  action
) => {
  switch (action.type) {
    case ModalsPoliciesTypes.SET_MODAL_VISIBILITY: {
      const { modalVisible } = action.payload

      return modalVisible
        ? {
            ...state,
            modalVisible
          }
        : initialState
    }
    case ModalsPoliciesTypes.SET_ACTIVATION_MODAL_VISIBILITY: {
      const { activationModalVisible } = action.payload

      return activationModalVisible
        ? {
            ...state,
            activationModalVisible
          }
        : initialState
    }
    case ModalsPoliciesTypes.SET_POLICY_NAME:
      return {
        ...state,
        currentPolicy: {
          ...state.currentPolicy,
          name: action.payload.policyName
        }
      }
    case ModalsPoliciesTypes.SET_POLICY_STATE:
      return {
        ...state,
        currentPolicy: {
          ...state.currentPolicy,
          active: action.payload.policyState
        }
      }
    case ModalsPoliciesTypes.SET_POLICY_DURATION:
      return {
        ...state,
        currentPolicy: {
          ...state.currentPolicy,
          durationInDays: action.payload.policyDuration
        }
      }
    case ModalsPoliciesTypes.SET_CURRENT_POLICY:
      return {
        ...state,
        currentPolicy: action.payload.currentPolicy
      }
    case CreateDiscountPolicyType.CREATE_POLICY_REQUEST:
      return {
        ...state,
        creatingPolicy: true
      }
    case CreateDiscountPolicyType.CREATE_POLICY_SUCCESS:
      return initialState
    case CreateDiscountPolicyType.CREATE_POLICY_FAILURE:
      return {
        ...state,
        creatingPolicy: false
      }
    case UpdateDiscountPolicyType.UPDATE_POLICY_REQUEST:
      return {
        ...state,
        updatingPolicy: true
      }
    case UpdateDiscountPolicyType.UPDATE_POLICY_SUCCESS:
      return initialState
    case UpdateDiscountPolicyType.UPDATE_POLICY_FAILURE:
      return {
        ...state,
        updatingPolicy: false
      }
    default:
      return state
  }
}

export default VolumeDiscountPoliciesABMReducer
