import {
  GetVolumeDiscountPoliciesTypes,
  VolumeDiscountPoliciesActions,
  VolumeDiscountPoliciesState
} from '@/sections/Discounts/types/volume/policies/table'
import { Reducer } from 'redux'

export const initialStatePolicies: VolumeDiscountPoliciesState = {
  volumeDiscountPolicies: [],
  fetchingDiscounts: false,
  pagination: {
    pageSize: 10,
    page: 1,
    total: 0
  },
  categoryFilter: { isActive: [] },
  searchFilters: [{ key: 'id', text: '' }]
}

const volumeDiscountPoliciesTableReducer: Reducer<VolumeDiscountPoliciesState, VolumeDiscountPoliciesActions> = (
  state = initialStatePolicies,
  action
) => {
  switch (action.type) {
    case GetVolumeDiscountPoliciesTypes.GET_VOLUME_DISCOUNT_POLICIES_REQUEST:
      return {
        ...state,
        fetchingDiscounts: true,
        pagination: {
          ...state.pagination,
          page: action.payload.newPage,
          pageSize: action.payload.pageSize
        },
        searchFilters: action.payload.searchFilters || initialStatePolicies.searchFilters,
        categoryFilter: action.payload.categoryFilter || initialStatePolicies.categoryFilter
      }
    case GetVolumeDiscountPoliciesTypes.GET_VOLUME_DISCOUNT_POLICIES_SUCCESS:
      return {
        ...state,
        fetchingDiscounts: false,
        volumeDiscountPolicies: action.payload.volumeDiscountPolicies,
        pagination: {
          ...state.pagination,
          total: action.payload.total
        }
      }
    case GetVolumeDiscountPoliciesTypes.GET_VOLUME_DISCOUNT_POLICIES_FAILURE:
      return {
        ...state,
        fetchingDiscounts: false
      }
    default:
      return state
  }
}

export default volumeDiscountPoliciesTableReducer
