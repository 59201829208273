import { handleErrorsWithAction } from './../../../utils/HandleErrors'
import { AppThunk } from '../../../store'
import { setToastErrorUpdate, setToastLoading, setToastSuccessUpdate } from '../../../utils/notifications'
import { API } from '@/projectApi'
import {
  CancelDonationModalTypes,
  CancelDonationRequest,
  CancelDonationSuccess,
  CleanState,
  CommonTypes,
  ReasonCancelDonationRequest,
  ReasonCancelDonationSuccess,
  ReasonCancelDonationTypes,
  SetOpen
} from '../types/cancelDonationModal'
import DonationTableActionsCreator from './donationsTable'

type DataUser = {
  id: string
  name: string
  lastName: string
}

const CancelDonationModalActionCreator = {
  CancelDonation:
    (id: number, reason: { id: number; text: string }): AppThunk =>
    async (dispatch) => {
      const request: CancelDonationRequest = {
        type: CancelDonationModalTypes.CANCEL_DONATION_REQUEST
      }
      const toast = setToastLoading('Cancelando donación...')
      dispatch(request)

      try {
        await API.Sales.DonationRequest.cancel({ id, reason })

        const success: CancelDonationSuccess = {
          type: CancelDonationModalTypes.CANCEL_DONATION_SUCCESS
        }
        dispatch(success)
        setToastSuccessUpdate(toast, { render: 'Donación cancelada.' })
        dispatch(CancelDonationModalActionCreator.setOpenModal(false))
        dispatch(DonationTableActionsCreator.refreshTableData())
      } catch (err) {
        setToastErrorUpdate(toast, {
          render: handleErrorsWithAction(err, CancelDonationModalTypes.CANCEL_DONATION_FAILURE, dispatch)
        })
      }
    },
  setOpenModal: (isOpenModal: boolean, data?: DataUser): SetOpen => ({
    type: CommonTypes.SET_OPEN,
    payload: {
      isOpen: isOpenModal,
      name: data?.name,
      lastName: data?.lastName,
      id: data?.id
    }
  }),
  getReasons: (): AppThunk => async (dispatch) => {
    const request: ReasonCancelDonationRequest = {
      type: ReasonCancelDonationTypes.REASONS_CANCEL_DONATION_REQUEST
    }

    dispatch(request)

    try {
      const response = await API.Sales.reasonsV2({
        action: 'CANCEL',
        count: true,
        entity: 'DONATION_REQUEST'
      })

      const success: ReasonCancelDonationSuccess = {
        type: ReasonCancelDonationTypes.REASONS_CANCEL_DONATION_SUCCESS,
        payload: {
          reasons: response.content.reasons
        }
      }

      dispatch(success)
    } catch (err) {
      handleErrorsWithAction(err, ReasonCancelDonationTypes.REASONS_CANCEL_DONATION_FAILURE, dispatch)
    }
  },
  cleanState: (): CleanState => ({
    type: CommonTypes.CLEAN_STATE
  })
}

export default CancelDonationModalActionCreator
