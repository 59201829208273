import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import volumeDiscountAssignmentsModalsActionsCreator from '@/sections/Discounts/actions/volume/assignments/modals'
import { bindActionCreators } from 'redux'
import volumeDiscountPoliciesTableActionsCreator from '@/sections/Discounts/actions/volume/assignments/table'
import ActivationModal from '@/sections/Discounts/components/volume/assignments/ActivationModal'
import { sendToastNotificationInfo } from '@/utils/notifications'
import { useLocalization } from '@/packages/localization'
import AssignUserDiscountModal from '@/sections/Discounts/components/volume/assignments/AssignUserDiscountModal'

const VolumeDiscountPoliciesModalsContainer = () => {
  const ModalsState = useSelector((state) => state.Discount.volumeDiscountModalsAssignmentsReducer)
  const tableState = useSelector((state) => state.Discount.volumeDiscountPoliciesTableReducer)
  const { strings } = useLocalization()

  const dispatch = useDispatch()

  const {
    setActivationModalVisibility,
    updateAssignment,
    assignUserDiscount,
    fetchDiscounts,
    fetchUserById,
    setDiscount,
    setModalAssignUserVisibility,
    setUserId
  } = bindActionCreators(volumeDiscountAssignmentsModalsActionsCreator, dispatch)

  const { getDiscounts } = bindActionCreators(volumeDiscountPoliciesTableActionsCreator, dispatch)

  const refetchTable = () =>
    getDiscounts({
      pagination: tableState.pagination
    })

  const handleUpdateAssignment = () => {
    const { id, active } = ModalsState.currentAssignment

    if (!id) return sendToastNotificationInfo(strings.Discounts.Volume.Assignments.modal.errors.updateError)

    const refetchTable = () =>
      getDiscounts({
        pagination: tableState.pagination
      })

    updateAssignment({ active: !active, assignmentId: id }, refetchTable)
  }

  const disabledUpdateButton = ModalsState.updatingAssignment

  const activationModalProps = {
    currentAssignment: ModalsState.currentAssignment,
    modalVisible: ModalsState.activationModalVisible,
    disabledUpdateButton,
    setModalVisibility: setActivationModalVisibility,
    handleUpdateAssignment
  }

  const handleAssignUserDiscount = () => {
    assignUserDiscount({ successCallback: refetchTable })
  }

  const assignUserDiscountModalProps = {
    assignUserDiscountModalVisible: ModalsState.assignUserDiscountModalVisible,
    user: ModalsState.user,
    fetchingUser: ModalsState.fetchingUser,
    fetchingDiscount: ModalsState.fetchingDiscounts,
    discounts: ModalsState.discounts,
    selectedDiscount: ModalsState.selectedDiscount,
    userId: ModalsState.userId,
    setModalAssignUserVisibility,
    fetchUserById,
    setUserId,
    fetchDiscounts,
    setDiscount,
    assignUserDiscount: handleAssignUserDiscount,
    error: ModalsState.error
  }

  return (
    <>
      {ModalsState.activationModalVisible ? <ActivationModal {...activationModalProps} /> : null}
      {ModalsState.assignUserDiscountModalVisible ? (
        <AssignUserDiscountModal {...assignUserDiscountModalProps} />
      ) : null}
    </>
  )
}

export default VolumeDiscountPoliciesModalsContainer
