import { VolumeDiscountPolicy } from '@/projectApi/TransactionHandler/Discounts/volume/policies/common'
import { CategoryFilter, Pagination, SearchFilter } from '@/utils/searchFilterUtils'

export type PoliciesSearchKey = 'id'
export type PoliciesSearchFilter = SearchFilter<PoliciesSearchKey>
export type PoliciesCategoryFilter = CategoryFilter<'isActive', string>

export type VolumeDiscountPoliciesState = {
  volumeDiscountPolicies: VolumeDiscountPolicy[]
  fetchingDiscounts: boolean
  pagination: Pagination
  searchFilters: PoliciesSearchFilter[]
  categoryFilter: PoliciesCategoryFilter
}

export enum GetVolumeDiscountPoliciesTypes {
  GET_VOLUME_DISCOUNT_POLICIES_REQUEST = 'GET_VOLUME_DISCOUNT_POLICIES_REQUEST',
  GET_VOLUME_DISCOUNT_POLICIES_SUCCESS = 'GET_VOLUME_DISCOUNT_POLICIES_SUCCESS',
  GET_VOLUME_DISCOUNT_POLICIES_FAILURE = 'GET_VOLUME_DISCOUNT_POLICIES_FAILURE'
}

export interface GetVolumeDiscountPoliciesRequest {
  type: typeof GetVolumeDiscountPoliciesTypes.GET_VOLUME_DISCOUNT_POLICIES_REQUEST
  payload: {
    pageSize: number
    newPage: number
    searchFilters?: PoliciesSearchFilter[]
    categoryFilter?: PoliciesCategoryFilter
  }
}

export interface GetVolumeDiscountPoliciesSuccess {
  type: typeof GetVolumeDiscountPoliciesTypes.GET_VOLUME_DISCOUNT_POLICIES_SUCCESS
  payload: {
    volumeDiscountPolicies: VolumeDiscountPolicy[]
    total: number
  }
}

export interface GetVolumeDiscountPoliciesFailure {
  type: typeof GetVolumeDiscountPoliciesTypes.GET_VOLUME_DISCOUNT_POLICIES_FAILURE
}

type GetVolumeDiscountPoliciesActions =
  | GetVolumeDiscountPoliciesRequest
  | GetVolumeDiscountPoliciesSuccess
  | GetVolumeDiscountPoliciesFailure

export type VolumeDiscountPoliciesActions = GetVolumeDiscountPoliciesActions
