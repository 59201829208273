import { list } from './list'
import { create } from './create'
import { update } from './update'
import { createItem } from './createItem'

export const policies = {
  list,
  create,
  update,
  createItem
}
