export enum DepositsEventsEnum {
  DEPOSIT_VALIDATED = 'DEPOSITS/DEPOSIT_VALIDATED',
  DEPOSIT_CANCELLED = 'DEPOSITS/DEPOSIT_CANCELLED',
  CLEAN_FILTERS = 'DEPOSITS/CLEAN_FILTERS',
  DEPOSIT_UPDATED = 'DEPOSITS/DEPOSIT_UPDATED',
  DEPOSIT_VALIDATED_DISCOUNT = 'DEPOSITS/DEPOSIT_VALIDATED_DISCOUNT'
}

export type DepositsEvents = {
  [DepositsEventsEnum.DEPOSIT_VALIDATED]: () => void
  [DepositsEventsEnum.DEPOSIT_CANCELLED]: () => void
  [DepositsEventsEnum.CLEAN_FILTERS]: () => void
  [DepositsEventsEnum.DEPOSIT_UPDATED]: () => void
  [DepositsEventsEnum.DEPOSIT_VALIDATED_DISCOUNT]: () => void
}
