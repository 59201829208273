import { discount } from './discount'
import { getByCode } from './getByCode'
import { VolumeDiscounts } from './volume'
import { searchDiscounts } from './searchDiscounts'

export const DiscountsAPI = {
  discount,
  getByCode,
  VolumeDiscounts,
  searchDiscounts
}
