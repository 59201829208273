import { Modal } from 'antd'
import React from 'react'
import styles from './changeEmailModal.module.scss'

export type UserData = {
  id: string
  name: string
  lastName: string
  email: string
}

export type ResetPasswordModalProps = {
  open: boolean
  userData: UserData
  loading: boolean
  handleCloseModal: () => void
  handleConfirm: () => void
}

const ResetPasswordModal: React.FC<ResetPasswordModalProps> = ({
  open,
  userData,
  loading,
  handleCloseModal,
  handleConfirm
}) => {
  return (
    <Modal
      width={'814px'}
      destroyOnClose
      okText={'Resetear contraseña'}
      cancelText={'Cancelar'}
      onOk={handleConfirm}
      okButtonProps={{ disabled: loading }}
      centered
      confirmLoading={loading}
      visible={open}
      onCancel={handleCloseModal}
      title="Restablecer contraseña">
      <p className={styles.modalText}>
        ¿Estás seguro de que deseas resetear la contraseña de este usuario?{' '}
        <b>La nueva contraseña será el DNI registrado en el sistema.</b>
      </p>
      <UserCard id={userData?.id} name={userData?.name} lastName={userData?.lastName} email={userData?.email} />
    </Modal>
  )
}

export default ResetPasswordModal

const InfoItem: React.FC<{ data: number | string; label: string }> = ({ data, label }) => (
  <div className={styles.infoItem}>
    <span className={styles.infoItemTitle}>{label}</span>
    {data}
  </div>
)

const UserCard: React.FC<UserData> = ({ id, name, lastName, email }) => (
  <header className={styles.userCard}>
    <h3 className={styles.TitleuserCard}>Datos del usuario:</h3>
    <div className={styles.hero}>
      <InfoItem data={id} label={'ID'} />
      <InfoItem data={name} label={'Nombre'} />
      <InfoItem data={lastName} label={'Apellido'} />
      <InfoItem data={email} label={'Email'} />
    </div>
  </header>
)
