import React, { useEffect, useMemo } from 'react'
import { Button, Input, Modal } from 'antd'
import Select from '@/components/Select'
import styles from './AssignUserDiscountModal.module.scss'
import { InfoCard } from '@/components/InfoCard/InfoCard'
import { User } from '@/projectApi/AccountManager/Users/common'
import { useLocalization } from '@/packages/localization'
import { VolumeDiscountPolicy } from '@/projectApi/TransactionHandler/Discounts/volume/policies/common'

type AssignUserDiscountModalProps = {
  assignUserDiscountModalVisible: boolean
  setModalAssignUserVisibility: ({ modalVisible }: { modalVisible: boolean }) => void
  user: User | null
  fetchUserById: () => void
  fetchingUser: boolean
  fetchingDiscount: boolean
  setUserId: ({ userId }: { userId: string }) => void
  userId: string
  selectedDiscount: { label: string; value: number } | null
  discounts: VolumeDiscountPolicy[] | null
  fetchDiscounts: () => void
  setDiscount: ({ selectedDiscount }: { selectedDiscount: { label: string; value: number } }) => void
  assignUserDiscount: () => void
  error: string
}

const AssignUserDiscountModal = ({
  assignUserDiscountModalVisible,
  setModalAssignUserVisibility,
  user,
  fetchUserById,
  fetchingUser,
  fetchingDiscount,
  setUserId,
  userId,
  selectedDiscount,
  discounts,
  fetchDiscounts,
  setDiscount,
  assignUserDiscount,
  error
}: AssignUserDiscountModalProps) => {
  const buttonSearchUserIdDisabled = false

  useEffect(() => {
    if (assignUserDiscountModalVisible) return fetchDiscounts()
  }, [assignUserDiscountModalVisible])

  const options = useMemo(() => {
    if (!discounts) return [{ value: 0, label: 'No hay descuentos disponibles' }]
    return discounts?.map(({ id, name }) => ({
      value: id,
      label: name
    }))
  }, [discounts])

  const { strings } = useLocalization()

  return (
    <Modal
      width={'max-content'}
      onCancel={() => setModalAssignUserVisibility({ modalVisible: false })}
      title={strings.Discounts.Volume.Assignments.modal.assignUserDiscountTitle}
      visible={assignUserDiscountModalVisible}
      okText={strings.generic.assign}
      cancelText={strings.generic.cancel}
      onOk={assignUserDiscount}
      okButtonProps={{
        className: styles.btnOk
      }}
      cancelButtonProps={{
        className: styles.btnCancel
      }}
      destroyOnClose>
      <div className={styles.container}>
        <label className={styles.label}>
          {strings.Discounts.Volume.Assignments.modal.discountsRange}
          <Select
            disabled={fetchingDiscount}
            className={styles.select}
            selected={selectedDiscount}
            options={options}
            onSelect={(option) => setDiscount({ selectedDiscount: option })}
            placeholder={strings.Discounts.Volume.Assignments.modal.discountsRangePlaceholder}
          />
        </label>
        <div className={styles.containerUser}>
          <label className={styles.labelUser}>
            {strings.Discounts.Volume.Assignments.modal.inputUser}:
            <span className={styles.containerInput}>
              <Input
                disabled={fetchingUser}
                value={userId}
                type="string"
                className={styles.inputUser}
                onChange={(e) => setUserId({ userId: e.target.value })}
              />
              {error && <p className={styles.error}>{error}</p>}
            </span>
          </label>
          <Button className={styles.btnSearch} disabled={buttonSearchUserIdDisabled} onClick={fetchUserById}>
            {strings.generic.search}
          </Button>
        </div>

        {user ? (
          <InfoCard
            name={user.name || '-'}
            id={user.realId}
            title={strings.Discounts.Volume.Assignments.modal.userData}
            lastName={user.lastName || '-'}
            documentId={user.dni}
            classNameTitleCard={styles.titleCard}
          />
        ) : null}
      </div>
    </Modal>
  )
}

export default AssignUserDiscountModal
