import { combineReducers } from 'redux'
import donationsListReducer from './donationsTable'
import donationsViewReducer from './donationsView'
import cancelDonationModalReducer from './cancelDonationModal'

export default combineReducers({
  table: donationsListReducer,
  view: donationsViewReducer,
  cancel: cancelDonationModalReducer
})
