import { CamelCasedPropertiesDeep } from 'type-fest'
import { AxiosResponse } from 'axios'
import { apiPrivate } from '../../../api'
import { URL_BASE_TRANSACTIONS } from '../../../endpoints'
import camelcaseKeys from 'camelcase-keys'

export interface Discount {
  id: number
  code: string
  createdAt: string
  updatedAt: string
  description: string
  publicDescription: string
  transportDiscountPercent: number
  depositDiscountPercent: number
  depositDiscountMonths: number
  generalDiscountPercent: number
  startDate: string
  endDate: string
}

export interface APIResponse {
  results: Discount[]
  paging: {
    total: number
    limit: number
    offset: number
  }
}

interface SearchParams {
  limit?: number
  offset?: number
  order?: 'asc' | 'desc'
  sort?: string
  code?: string
}

export function searchDiscounts(params: SearchParams): Promise<CamelCasedPropertiesDeep<APIResponse>> {
  const formattedParams = {
    order: params.order ?? 'desc',
    column: params.sort ?? 'id',
    limit: params.limit ?? 10,
    offset: params.offset ?? 0,
    ...params
  }

  return apiPrivate
    .get(`${URL_BASE_TRANSACTIONS}/discounts/search`, { params: formattedParams })
    .then((response: AxiosResponse<APIResponse>) => {
      return camelcaseKeys(response.data, { deep: true })
    })
    .catch((error) => {
      throw error
    })
}
