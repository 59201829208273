import { Reducer } from 'redux'
import {
  OpenResetPasswordModalTypes,
  ResetPasswordActions,
  ResetPasswordModalState,
  ResetPasswordTypes
} from '../types/resetPassword'
import { CountryIdCode } from '../../../components/CountrySelector/constants/constants'

const initialState: ResetPasswordModalState = {
  open: false,
  userData: {
    id: '',
    name: '',
    country: CountryIdCode.ALL,
    lastName: '',
    email: ''
  },
  error: '',
  loading: false
}

const ResetPasswordReducer: Reducer<ResetPasswordModalState, ResetPasswordActions> = (
  state = initialState,
  action
): ResetPasswordModalState => {
  switch (action.type) {
    case OpenResetPasswordModalTypes.OPEN_RESET_PASSWORD_MODAL:
      if (!action.payload.open) {
        return initialState
      }
      return { ...state, open: true, userData: action.payload.userData || state.userData }
    case ResetPasswordTypes.RESET_PASSWORD_REQUEST:
      return { ...state, loading: true }
    case ResetPasswordTypes.RESET_PASSWORD_SUCCESS:
      return { ...state, loading: false, open: false }
    case ResetPasswordTypes.RESET_PASSWORD_FAILURE:
      return { ...state, loading: false, error: action.payload.error }
    default:
      return state
  }
}

export default ResetPasswordReducer
