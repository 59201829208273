import { list } from './list'
import { accept } from './accept'
import { donate } from './donate'
import { reject } from './reject'
import { cancelAsSG } from './cancel'

export const APIDonationRequest = {
  list,
  accept,
  donate,
  reject,
  cancel: cancelAsSG
}
