import { apiPrivate } from '@/api'
import { URL_BASE_TRANSACTIONS } from '@/endpoints'
import camelcaseKeys from 'camelcase-keys'
import snakecaseKeys from 'snakecase-keys'
import { APIResponse, VolumeDiscountPolicy } from './common'
import { AxiosResponse } from 'axios'

type Column = 'id'

interface Response {
  volumeDiscountPolicies: VolumeDiscountPolicy[]
  total: number
}

interface ListParams {
  id?: number
  count?: boolean
  limit?: number
  offset?: number
  isActive?: string
  direction?: string
  withItems?: boolean
  orderByColumn?: Column
}

export function list({ direction, count = true, ...restParams }: Partial<ListParams>): Promise<Response> {
  const formattedParams = {
    count,
    dir: direction,
    ...snakecaseKeys(restParams)
  }

  return apiPrivate
    .get<APIResponse>(`${URL_BASE_TRANSACTIONS}/volume-discount-policies`, { params: formattedParams })
    .then((response: AxiosResponse<APIResponse>) => {
      const discountPoliciesFormatted = camelcaseKeys(response.data.content.volume_discount_policies ?? [], {
        deep: true
      }).map((policy) => ({
        ...policy,
        volumeDiscountItems: policy.volumeDiscountItems
          ? policy.volumeDiscountItems.sort((a, b) => a.m3Min - b.m3Min)
          : []
      }))

      return {
        volumeDiscountPolicies: discountPoliciesFormatted,
        total: response.data.content.total
      }
    })
    .catch((error) => {
      throw error
    })
}
