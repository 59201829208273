import { Moment } from 'moment'
import { CamelCasedPropertiesDeep } from 'type-fest'
import { StatusOptions } from '../Sales/SalesRequest/common'

export interface APIAttributeObject {
  ID: number
  CreatedAt: string
  UpdatedAt: string
  DeletedAt: any
  ObjectId: number
  AttributeId: number
  Attribute: APIAttribute
  Value: string
}

export interface APIAttribute {
  ID: number
  CreatedAt: string
  UpdatedAt: string
  DeletedAt: any
  Name: string
  DataType: string
  IsVisible: boolean
  Unit: string
  Countries: APICountry[]
}

export interface APICountry {
  ID: number
  CreatedAt: string
  UpdatedAt: string
  DeletedAt: any
  Label: string
  CountryId: string
  AttributeId: number
  Options: any
}

export interface APIObject {
  Attributes: APIAttributeObject[]
  DeleteAt: null | string
  DeleteDescription: null | string
  Description: string
  DevolutionDate: null
  HeightInCm: number
  ID: number
  IsCorporate: boolean
  IdentificationCodes: APIIdentificationCode[]
  LengthInCm: number
  Location: APILocation
  Name: string
  NeedsAuthorization: boolean
  ObjectDeletedReason: null | { reason: string }
  ObjectSituation: keyof typeof OBJECT_SITUATION | null
  ObjectSituationReason: string | null
  ObjectStatus: APIObjectStatus
  Owner: APIOwner
  Photos: APIPhoto[]
  Product: APIProduct
  ProductType: APIProductType
  RealId: number
  RegistryDate: string
  CreationDate: string
  RentEnabled: boolean
  RentPrice: number
  ServiceTypeId: string
  WeightInGr: number
  WidthInCm: number
}

interface APIIdentificationCode {
  Code: string
  Actual: boolean
}

interface APILocation {
  ID: number
  Description: string
  type: string
  Deposit: APIDeposit
  Code: string
}

interface APIDeposit {
  Name: string
  Address: string
}

interface APIObjectStatus {
  ID: number
  Description: ObjectsStatusKey
}

interface APIOwner {
  CreatedAt: string
  DeletedAt: null
  Email: APIEmail
  ID: string
  LastName: string
  Name: string
  Phone: string
  RealId: number
  UpdatedAt: string
  country_id: string
}

interface APIEmail {
  Email: string
}

interface APIPhoto {
  ID: number
  CreatedAt: string
  UpdatedAt: string
  DeletedDescription: string
  Url: string
  MainPhoto: boolean
  IsInternal: boolean
}

interface APIProduct {
  RealID: number
  ID: string
  WeightInGr: number
  LengthInCm: number
  WidthInCm: number
  HeightInCm: number
  MinVolumeDeviation: number
  MaxVolumeDeviation: number
  FloorByStairsCostInCents: number
  RemovalPackagingCostInCents: number
  AssemblyCostInCents: number
  Deprecated: boolean
  Multiplier: number
  Description: string
  Categories: null
  ProdByCountry: APIProdByCountry
  PhotoMin: number
  EligibleForSale: boolean
}

interface APIProdByCountry {
  Name: string
  CountryId: string
  ProductId: number
}

interface APIProductType {
  ID: number
  Description: string
  Price: APIPrice
}

interface APIPrice {
  ID: number
  Description: string
  Price: number
}

export interface Owner {
  createdAt: null | Moment
  deletedAt: null | Moment
  email: CamelCasedPropertiesDeep<APIEmail>
  id: string
  lastName: string
  name: string
  phone: string
  realId: number
  updatedAt: string
  countryId: string
}

export interface Photo {
  id: number
  createdAt: null | Moment
  updatedAt: null | Moment
  deletedDescription: string
  url: string
  mainPhoto: boolean
  isInternal: boolean
}

export type FormattedObject = {
  attributes: CamelCasedPropertiesDeep<APIAttributeObject>[]
  deletedAt: null | Moment
  deletedDescription: string
  description: string
  devolutionDate: null | Moment
  heightInCm: number
  id: number
  isCorporate: boolean
  identificationCodes: CamelCasedPropertiesDeep<APIIdentificationCode>[]
  lengthInCm: number
  location: CamelCasedPropertiesDeep<APILocation>
  name: string
  needsAuthorization: boolean
  objectDeletedReason: null | { reason: string }
  objectSituation: keyof typeof OBJECT_SITUATION | null
  objectStatus: CamelCasedPropertiesDeep<APIObjectStatus>
  objectSituationReason: string | null
  owner: Owner
  photos: Photo[]
  product: CamelCasedPropertiesDeep<APIProduct>
  productType: CamelCasedPropertiesDeep<APIProductType>
  realId: number
  registryDate: null | Moment
  creationDate: null | Moment
  rentEnabled: boolean
  rentPrice: number
  serviceTypeId: string
  weightInGr: number
  widthInCm: number
  unformattedAPIObject: APIObject
}

export type ObjectChanges = {
  id: number
  realId: number
  ownerId: number
  description: string
  name: string
  user: Owner
  identificationCodes: string
  objectStatus: CamelCasedPropertiesDeep<APIObjectStatus>
  objectSituationReason: string | null
  productId: number
  productType: CamelCasedPropertiesDeep<APIProductType>
  createdAt: null | Moment
  objectStatusId: string
  objectSituation: string
  locationId: string
  serviceTypeId: string
  lengthInCm: number
  heightInCm: number
  weightInGr: number
  widthInCm: number
  source: string
}

export enum ObjectsStatusKey {
  DEPOSITADO = 'Depositado',
  ALQUILADO = 'Alquilado',
  EN_PROPIETARIO = 'En propietario',
  EN_TRANSITO = 'En tránsito',
  A_CATALOGAR = 'A catalogar',
  A_FOTOGRAFIAR = 'A fotografiar',
  A_UBICAR = 'A ubicar',
  CON_ABONO = 'Con abono',
  BUSCANDO = 'Buscando',
  ENCONTRADO = 'Encontrado',
  NO_ENCONTRADO = 'No Encontrado',
  DONADO = 'Donado',
  A_DONAR = 'A Donar',
  ELIMINADO = 'Eliminado'
}

export const OBJECT_SITUATION_SAVED = 'SAVED'

export enum OBJECT_SITUATION {
  IN_OWNER = 'En propietario',
  SAVED = 'Guardado validado',
  SAVED_NOT_VALIDATED = 'Guardado no validado',
  LOCATING = 'A ubicar',
  CATALOGING = 'En clasificación',
  TAKING_PHOTOS = 'Fotografiando',
  REMOVAL_REQUEST = 'En proceso de devolución',
  SALE_PREPARATION = 'En preparación PDV',
  LOST = 'Extraviado',
  FOUND_AFTER_LOST = 'Encontrado',
  UNDER_REPAIR = 'En reparación',
  REPAIRED = 'Reparado',
  DONATION_REQUEST = 'Solicitud de donacion',
  DONATED = 'Donado',
  FORCED_DONATION = 'Donacion forzada',
  SALES_REQUEST = 'En solicitud de venta',
  SALES_REQUEST_R = 'En solicitud de venta R',
  SALES_REQUEST_CP = 'En solicitud de venta CP',
  SALES_PRODUCTION = 'En producción PDV',
  SALE = 'En venta',
  SOLD_OUT = 'Vendido',
  FORCED_SALE = 'Venta forzada',
  RECYCLING_REQUEST = 'Solicitud de reciclado',
  RECYCLED = 'Reciclado',
  FORCED_RECYCLED = 'Reciclado forzado',
  SALE_DEBTOR = 'Venta Deudor',
  SOLD_DEBTOR = 'Vendido Deudor'
}

export const ObjectSituacionsToSell = [OBJECT_SITUATION.SALE_PREPARATION]
export const SaleRequestSituationToSell: string[] = [
  StatusOptions.ACEPTADA_PRC,
  StatusOptions.ACEPTADA_CP_PRC,
  StatusOptions.ACEPTADA_VENTA_EXPRESS,
  StatusOptions.CONTRAPROPUESTA_ACEPTADA_VE
]
export const disableToSell: string[] = [
  StatusOptions.EN_CONTRAPROPUESTA,
  StatusOptions.RECIBIDA,
  StatusOptions.CANCELADA_CLIENTE,
  StatusOptions.EN_DIAGNOSTICO,
  StatusOptions.RECHAZADA_SG,
  StatusOptions.DIAGNOSTICO_FINALIZADO,
  StatusOptions.CONTRAPROPUESTA_RECHAZADA_USER,
  StatusOptions.VENDIDO
]

export const SaleRequestSituationToCancel: string[] = [
  StatusOptions.ACEPTADA_PRC,
  StatusOptions.ACEPTADA_CP_PRC,
  StatusOptions.ACEPTADA_VENTA_EXPRESS
]
