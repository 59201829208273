export type PolicyItem = {
  minRange?: number
  discountPercentage?: number
}

export type PolicyItemState = {
  modalItemVisible: boolean
  creatingPolicyItem: boolean
  currentPolicyId?: number
  currentPolicyItem: PolicyItem
}

export enum ModalPolicyItemTypes {
  SET_MODAL_ITEM_VISIBILITY = 'DISCOUNTS/POLICIES/SET_MODAL_ITEM_VISIBILITY',
  SET_MIN_RANGE_VALUE = 'DISCOUNTS/POLICIES/SET_MIN_RANGE_VALUE',
  SET_DISCOUNT_PERCENTAGE_VALUE = 'DISCOUNTS/POLICIES/SET_DISCOUNT_PERCENTAGE_VALUE',
  SET_CURRENT_POLICY_ID = 'DISCOUNTS/POLICIES/SET_CURRENT_POLICY_ID'
}

export interface SetModalItemVisibility {
  type: typeof ModalPolicyItemTypes.SET_MODAL_ITEM_VISIBILITY
  payload: { modalItemVisible: boolean }
}

export interface SetMinRangeValue {
  type: typeof ModalPolicyItemTypes.SET_MIN_RANGE_VALUE
  payload: { minRange: number }
}

export interface SetDiscountPercentageValue {
  type: typeof ModalPolicyItemTypes.SET_DISCOUNT_PERCENTAGE_VALUE
  payload: { discountPercentage: number }
}

export interface SetCurrentPolicyId {
  type: typeof ModalPolicyItemTypes.SET_CURRENT_POLICY_ID
  payload: { currentPolicyId: number }
}

type ModalsPolicyItemActions =
  | SetModalItemVisibility
  | SetMinRangeValue
  | SetDiscountPercentageValue
  | SetCurrentPolicyId

export enum CreatePolicyItemType {
  CREATE_POLICY_ITEM_REQUEST = 'DISCOUNTS/POLICIES/CREATE_POLICY_ITEM_REQUEST',
  CREATE_POLICY_ITEM_SUCCESS = 'DISCOUNTS/POLICIES/CREATE_POLICY_ITEM_SUCCESS',
  CREATE_POLICY_ITEM_FAILURE = 'DISCOUNTS/POLICIES/CREATE_POLICY_ITEM_FAILURE'
}

export interface CreatePolicyItemRequest {
  type: typeof CreatePolicyItemType.CREATE_POLICY_ITEM_REQUEST
}

export interface CreatePolicyItemSuccess {
  type: typeof CreatePolicyItemType.CREATE_POLICY_ITEM_SUCCESS
}

export interface CreatePolicyItemFailure {
  type: typeof CreatePolicyItemType.CREATE_POLICY_ITEM_FAILURE
}

type CreatePolicyItemActions = CreatePolicyItemRequest | CreatePolicyItemSuccess | CreatePolicyItemFailure

export type PolicyItemActions = ModalsPolicyItemActions | CreatePolicyItemActions
