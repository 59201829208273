import { API } from '@/projectApi'
import { Params as UpdatePolicyParams } from '@/projectApi/TransactionHandler/Discounts/volume/assignments/update'
import {
  ModalsAssignmentsTypes,
  Assignment,
  SetActivationModalVisibility,
  SetCurrentAssignment,
  UpdateAssignmentType,
  UpdateAssignmentRequest,
  ModalAssignUserDiscountTypes,
  SetModalAssignUserVisibility,
  FetchingUserRequest,
  FetchingUserType,
  SetUserId,
  SetSelectedDiscount,
  AssignUserDiscountRequest,
  AssignUserDiscountTypes,
  FetchingDiscountsRequest,
  FetchingDiscountsType,
  FetchingDiscountsSuccess
} from '@/sections/Discounts/types/volume/assignments/modals'
import { AppThunk } from '@/store'
import { handleErrorsWithAction } from '@/utils/HandleErrors'
import { setToastErrorUpdate, setToastLoading, setToastSuccessUpdate } from '@/utils/notifications'

const volumeDiscountAssignmentsModalsActionsCreator = {
  setActivationModalVisibility({
    activationModalVisible
  }: {
    activationModalVisible: boolean
  }): SetActivationModalVisibility {
    return {
      type: ModalsAssignmentsTypes.SET_ACTIVATION_MODAL_VISIBILITY,
      payload: { activationModalVisible }
    }
  },

  setCurrentAssignment({ currentAssignment }: { currentAssignment: Assignment }): SetCurrentAssignment {
    return {
      type: ModalsAssignmentsTypes.SET_CURRENT_ASSIGNMENT,
      payload: { currentAssignment }
    }
  },

  updateAssignment(params: UpdatePolicyParams, successCallback?: () => void): AppThunk {
    return async (dispatch) => {
      const request: UpdateAssignmentRequest = {
        type: UpdateAssignmentType.UPDATE_ASSIGNMENT_REQUEST
      }
      const toastId = setToastLoading('Actualizando los descuentos del usuario, por favor espere...')

      dispatch(request)

      try {
        await API.TransactionHandler.Discounts.VolumeDiscounts.assignments.update(params)

        dispatch({
          type: UpdateAssignmentType.UPDATE_ASSIGNMENT_SUCCESS
        })
        setToastSuccessUpdate(toastId, { render: 'Descuentos del usuario actualizados correctamente.' })
        successCallback?.()
      } catch (error) {
        setToastErrorUpdate(toastId, {
          render:
            handleErrorsWithAction(error, UpdateAssignmentType.UPDATE_ASSIGNMENT_FAILURE, dispatch) ||
            'Error al actualizar los descuentos del usuario'
        })
      }
    }
  },
  setModalAssignUserVisibility({ modalVisible }: { modalVisible: boolean }): SetModalAssignUserVisibility {
    return {
      type: ModalAssignUserDiscountTypes.SET_MODAL_ASSIGN_USER_VISIBILITY,
      payload: { modalVisible }
    }
  },

  fetchUserById(): AppThunk {
    return async (dispatch, getState) => {
      const request: FetchingUserRequest = {
        type: FetchingUserType.FETCHING_USER_REQUEST
      }

      dispatch(request)

      const { userId } = getState().Discount.volumeDiscountModalsAssignmentsReducer

      try {
        const { users } = await API.AccountManager.Users.list({ id: +userId })

        dispatch({
          type: FetchingUserType.FETCHING_USER_SUCCESS,
          payload: { user: users[0] }
        })
      } catch (error) {
        console.error(error)
      }
    }
  },

  setUserId({ userId }: { userId: string }): SetUserId {
    return {
      type: ModalAssignUserDiscountTypes.SET_USER_ID,
      payload: { userId }
    }
  },

  setDiscount({ selectedDiscount }: { selectedDiscount: { label: string; value: number } }): SetSelectedDiscount {
    return {
      type: ModalAssignUserDiscountTypes.SET_DISCOUNT,
      payload: { selectedDiscount }
    }
  },
  assignUserDiscount({ successCallback }: { successCallback?: () => void }): AppThunk {
    return async (dispatch, getState) => {
      const request: AssignUserDiscountRequest = {
        type: AssignUserDiscountTypes.ASSIGN_USER_DISCOUNT_REQUEST
      }
      const toastId = setToastLoading('Asignando descuento a usuario, por favor espere...')

      dispatch(request)

      const { selectedDiscount, user } = getState().Discount.volumeDiscountModalsAssignmentsReducer

      if (!user || !selectedDiscount) return

      try {
        await API.TransactionHandler.Discounts.VolumeDiscounts.assignments.assignUserDiscount({
          userId: user.realId,
          volumeDiscountPolicyId: selectedDiscount.value
        })

        dispatch({
          type: AssignUserDiscountTypes.ASSIGN_USER_DISCOUNT_SUCCESS
        })
        setToastSuccessUpdate(toastId, { render: 'Descuento asignado correctamente al usuario.' })
        successCallback?.()
      } catch (error) {
        setToastErrorUpdate(toastId, {
          render:
            handleErrorsWithAction(error, AssignUserDiscountTypes.ASSIGN_USER_DISCOUNT_FAILURE, dispatch) ||
            'Error al asignar descuento al usuario'
        })
      }
    }
  },
  fetchDiscounts(): AppThunk {
    return async (dispatch) => {
      const request: FetchingDiscountsRequest = {
        type: FetchingDiscountsType.FETCHING_DISCOUNTS_REQUEST
      }
      dispatch(request)

      try {
        const { volumeDiscountPolicies } = await API.TransactionHandler.Discounts.VolumeDiscounts.policies.list({
          withItems: false,
          isActive: 'true'
        })
        const success: FetchingDiscountsSuccess = {
          type: FetchingDiscountsType.FETCHING_DISCOUNTS_SUCCESS,
          payload: {
            discounts: volumeDiscountPolicies
          }
        }
        dispatch(success)
      } catch (error) {
        handleErrorsWithAction(error, FetchingDiscountsType.FETCHING_DISCOUNTS_FAILURE, dispatch)
      }
    }
  }
}

export default volumeDiscountAssignmentsModalsActionsCreator
