import {
  Cancel,
  SelectObject,
  SetDescription,
  SetObjectSituationReason,
  SetLocation,
  SetName,
  SetNeedsAuthorization,
  SetRentEnabled,
  SetRentPrice,
  SetHeight,
  SetWidth,
  SetLength,
  SetObjectSituation,
  SetObjectStatus,
  SetProduct,
  ModifyObjectSuccess,
  GetObjectSituationSuccess,
  GetProductsSuccess,
  SetAttributeValue,
  DeleteAttribute,
  SetAttributeType
} from '../types/edit'

import { handleErrorsWithAction } from '../../../utils/HandleErrors'
import { API } from '../../../projectApi'
import Emitter from '../../../utils/eventEmitter'
import { Events } from '../../../utils/eventEmitter/events'
import { FormattedObject } from '../../../projectApi/ObjectAdministration/common'
import { UpdateBody } from '../../../projectApi/ObjectAdministration/Object/update'
import { AppThunk } from '../../../store'
import { FormattedProduct } from '../../../projectApi/CategoryCreation/Product/common'
import { ObjectSituation } from '../../../projectApi/ObjectAdministration/ObjectSituation/search'
import { StringKeyOf } from 'type-fest'
import { ObjectTypes } from '../types/edit.enum'
import { setToastErrorUpdate, setToastLoading, setToastSuccessUpdate } from '../../../utils/notifications'

let searchTimer: NodeJS.Timeout

const formatObjectResponseToUpdateRequest = (object: FormattedObject): UpdateBody => ({
  name: object.name,
  description: object.description,
  objectSituation: object.objectSituation,
  objectSituationReason: object.objectSituationReason,
  // @ts-ignore
  productId: object.product?.realId,
  weightInGr: object.product?.weightInGr,
  lengthInCm: object.lengthInCm,
  widthInCm: object.widthInCm,
  heightInCm: object.heightInCm,
  deletedDescription: object.deletedDescription,
  rentEnabled: object.rentEnabled,
  rentPrice: object.rentPrice,
  registryDate: object.registryDate?.toISOString(),
  objectStatusId: object.objectStatus?.id,
  locationId: object.location?.id,
  productTypeId: object.productType?.id,
  needsAuthorization: object.needsAuthorization,
  countryId: object.owner?.countryId,
  attributes: object.attributes?.map((attribute) => ({
    id: attribute.id,
    value: attribute.value,
    attributeId: attribute.attributeId,
    deleted: false
  }))
})

const makeObjectDifference = (oldObject: UpdateBody, newObject: UpdateBody): UpdateBody => {
  const objectKeys = Object.keys(oldObject) as StringKeyOf<UpdateBody>[]
  return objectKeys.reduce<UpdateBody>((acc, key) => {
    if (JSON.stringify(newObject[key]) !== JSON.stringify(oldObject[key])) {
      // @ts-ignore
      acc[key] = newObject[key]
    }
    return acc
  }, {})
}

const actions = {
  selectObjectByID:
    (objectID: number): AppThunk =>
    async (dispatch) => {
      const request = {
        type: ObjectTypes.SELECT_OBJECT_BY_ID_REQUEST
      }

      dispatch(request)

      try {
        const { objects } = await API.ObjectAdministration.Object.list({
          id: objectID,
          location: 'true'
        })

        const success = {
          type: ObjectTypes.SELECT_OBJECT_BY_ID_SUCCESS,
          payload: {
            object: formatObjectResponseToUpdateRequest(objects[0]),
            rawObject: objects[0]
          }
        }

        dispatch(success)
      } catch (error) {
        handleErrorsWithAction(error, ObjectTypes.SELECT_OBJECT_BY_ID_FAILURE, dispatch)
      }
    },
  selectObject: (object: FormattedObject): SelectObject => ({
    type: ObjectTypes.SELECT_OBJECT,
    payload: { object: formatObjectResponseToUpdateRequest(object), rawObject: object }
  }),
  cancel: (): Cancel => ({ type: ObjectTypes.CANCEL }),
  setDescription: (description: string): SetDescription => ({
    type: ObjectTypes.SET_DESCRIPTION,
    payload: { description }
  }),
  setObjectSituationReason: (objectSituationReason: string): SetObjectSituationReason => ({
    type: ObjectTypes.SET_OBJECT_SITUATION_REASON,
    payload: { objectSituationReason }
  }),
  setLocation: (location: number): SetLocation => ({
    type: ObjectTypes.SET_LOCATION,
    payload: { location }
  }),
  setName: (name: string): SetName => ({
    type: ObjectTypes.SET_NAME,
    payload: { name }
  }),
  setNeedsAuthorization: (needsAuthorization: boolean): SetNeedsAuthorization => ({
    type: ObjectTypes.SET_NEEDS_AUTHORIZATION,
    payload: { needsAuthorization }
  }),
  setRentEnabled: (rentEnabled: boolean): SetRentEnabled => ({
    type: ObjectTypes.SET_RENT_ENABLED,
    payload: { rentEnabled }
  }),
  setRentPrice: (rentPrice: number): SetRentPrice => ({
    type: ObjectTypes.SET_RENT_PRICE,
    payload: { rentPrice }
  }),
  setProduct: (product: FormattedProduct | null): SetProduct => ({
    type: ObjectTypes.SET_PRODUCT,
    payload: { product }
  }),
  setHeight: (height: number): SetHeight => ({
    type: ObjectTypes.SET_HEIGHT,
    payload: { height }
  }),
  setWidth: (width: number): SetWidth => ({
    type: ObjectTypes.SET_WIDTH,
    payload: { width }
  }),
  setLength: (length: number): SetLength => ({
    type: ObjectTypes.SET_LENGTH,
    payload: { length }
  }),
  setObjectSituation: (objectSituation: string): SetObjectSituation => ({
    type: ObjectTypes.SET_OBJECT_SITUATION,
    payload: { objectSituation }
  }),
  setObjectStatus: (objectStatus: number): SetObjectStatus => ({
    type: ObjectTypes.SET_OBJECT_STATUS_ID,
    payload: { objectStatus }
  }),
  modifyObject: (id: number, oldObject: UpdateBody, newObject: UpdateBody): AppThunk => {
    return function (dispatch) {
      dispatch({ type: ObjectTypes.MODIFY_OBJECT_REQUEST })

      const toastId = setToastLoading('Modificando objeto, por favor espere...')
      const body = makeObjectDifference(oldObject, newObject)

      return API.ObjectAdministration.Object.update(id, body).then(
        (response) => {
          dispatch(actions.modifyObjectSuccess())
          setToastSuccessUpdate(toastId, { render: 'Objeto modificado con éxito' })

          Emitter.emit(Events.Objects.OBJECT_EDITED)
          return true
        },
        (error) => {
          setToastErrorUpdate(toastId, { render: 'Hubo un error al modificar el objeto' })
          handleErrorsWithAction(error, ObjectTypes.MODIFY_OBJECT_FAILURE, dispatch)

          return false
        }
      )
    }
  },
  modifyObjectSuccess: (): ModifyObjectSuccess => ({
    type: ObjectTypes.MODIFY_OBJECT_SUCCESS
  }),

  getObjectSituations: (): AppThunk => {
    return async function (dispatch) {
      dispatch({ type: ObjectTypes.GET_OBJECT_SITUATION_REQUEST })
      try {
        const objectSituations = await API.ObjectAdministration.ObjectSituation.search()
        dispatch(actions.getObjectSituationsSuccess(objectSituations))
      } catch (e) {
        handleErrorsWithAction(e, ObjectTypes.GET_OBJECT_SITUATION_FAILURE, dispatch)
      }
    }
  },
  getObjectSituationsSuccess: (objectSituations: ObjectSituation[]): GetObjectSituationSuccess => ({
    type: ObjectTypes.GET_OBJECT_SITUATION_SUCCESS,
    payload: { objectSituations }
  }),

  getProducts: (description: string, serviceTypeId: string, countryCode: string): AppThunk => {
    return function (dispatch, getState) {
      clearTimeout(searchTimer)
      searchTimer = setTimeout(() => {
        const lastSearchID = new Date().getMilliseconds()

        dispatch({ type: ObjectTypes.GET_PRODUCTS_REQUEST, payload: { lastSearchID } })

        return API.CategoryCreation.Product.listAdmin({
          limit: 50,
          offset: 0,
          name: description,
          serviceTypeId,
          countryCode
        }).then(
          (response) => {
            if (lastSearchID === getState().Objects.edit.lastProductSearchID) {
              dispatch(actions.getProductsSuccess(response.products))
            }
          },
          (error) => {
            handleErrorsWithAction(error, ObjectTypes.GET_PRODUCTS_FAILURE, dispatch)
          }
        )
      }, 250)
    }
  },
  getProductsSuccess: (products: FormattedProduct[]): GetProductsSuccess => ({
    type: ObjectTypes.GET_PRODUCTS_SUCCESS,
    payload: { products }
  }),

  getProductAttributes(productId: number): AppThunk {
    return async (dispatch): Promise<boolean> => {
      dispatch({ type: ObjectTypes.GET_PRODUCT_ATTRIBUTES_REQUEST })

      try {
        const { attributes } = await API.ObjectAdministration.Attribute.list({ productId, limit: 100, offset: 0 })
        dispatch({ type: ObjectTypes.GET_PRODUCT_ATTRIBUTES_SUCCESS, payload: { attributes: attributes || [] } })
        return true
      } catch (error) {
        handleErrorsWithAction(error, ObjectTypes.GET_PRODUCT_ATTRIBUTES_FAILURE, dispatch)
        return false
      }
    }
  },

  setAttributeType: (index: number, attributeId: number, newAttributeTemplate = true): SetAttributeType => ({
    type: ObjectTypes.SET_ATTRIBUTE_TYPE,
    payload: { index, attributeId, newAttributeTemplate }
  }),

  setAttributeValue: (index: number, value: string): SetAttributeValue => ({
    type: ObjectTypes.SET_ATTRIBUTE_VALUE,
    payload: { index, value }
  }),

  deleteAttribute: (index: number, newAttributeTemplate = false): DeleteAttribute => ({
    type: ObjectTypes.DELETE_ATTRIBUTE,
    payload: { index, newAttributeTemplate }
  }),

  getLocationByCode: (code: string): AppThunk => {
    return async (dispatch) => {
      dispatch({ type: ObjectTypes.GET_LOCATION_BY_CODE_REQUEST })
      try {
        const location = await API.CategoryCreation.LocationInfo.getByCode(code)
        dispatch({
          type: ObjectTypes.GET_LOCATION_BY_CODE_SUCCESS,
          payload: { location }
        })
      } catch (e) {
        handleErrorsWithAction(e, ObjectTypes.GET_LOCATION_BY_CODE_FAILURE, dispatch)
      }
    }
  }
}

export default actions
