import { Moment } from 'moment'
import { CamelCasedPropertiesDeep } from 'type-fest'
import { CountryIdCode } from '../../../components/CountrySelector/constants/constants'
import { Billing } from '../../../projectApi/TransactionHandler/Billings/common'
import { ObjectPhoto, ObjectXAttribute } from '../../../projectApi/TransactionHandler/DepositsOperations/getById'
import { DepositStatus } from '../../../projectApi/TransactionHandler/Operation/list'

export enum AttributesTypeEnum {
  PACKING = 253,
  STATUS = 254
}

export enum AttributesNameEnum {
  PACKING = 'Embalaje',
  STATUS = 'Estado'
}

export type ValidationViewObject = {
  objectId: number
  productId: number
  productName: string
  photos: CamelCasedPropertiesDeep<ObjectPhoto>[]
  storageCostInCents: number
  objectXAttributes?: ObjectXAttribute[]
  extras: {
    disassembly: {
      enabled: boolean
      disassemblyCost: number
    }
    stairs: {
      enabled: boolean
      floors: number
      floorsCostPerFloorInCents: number
    }
    packaging: {
      enabled: boolean
      packagingCostInCents: number
    }
  }
  deletedAt: string | null
}

export type ValidationViewDiscount = {
  deletingDiscount: boolean
  updateDiscountCode: string
  discountId: number | null
  loadingFetchDiscount: boolean
  errorFetch: string
  openModal: boolean
  isValid: boolean
}

export type ValidationViewState = {
  operationCountryCode: CountryIdCode
  objects: ValidationViewObject[]
  quotedTransportCost: number
  discountCode: string
  discountId: number | null | undefined
  loadingProvisionalBilling: boolean
  provisionalBilling: Billing | null
  baseInfo: {
    id: number
    fullAddress: string
    transactionId: number
    user: {
      id: number
      name: string
      lastName: string
    }
    createdAt: Moment | null
    datetime: Moment | null
    status: DepositStatus | null
  }
  extraPrices: {
    assistants: {
      enabled: boolean
      price: number
      count: number
    }
    kmsExtra: {
      enabled: boolean
      price: number
      count: number
    }
    transport: {
      enabled: boolean
      price: number
      tollCostInCents: number
    }
  }
  headerToggles: {
    disassembly: boolean
    stairs: boolean
    packaging: boolean
  }
  headerCounters: {
    stairs: number
  }
  loading: boolean
  validating: boolean
  error: string
  fetchedRecalculation: boolean
  loadingDepositOperation: boolean
  discount: ValidationViewDiscount
}

export const TOGGLE_TRANSPORT_EXTRA = 'DEPOSITS_VALIDATION/VALIDATION_VIEW/TOGGLE_TRANSPORT_EXTRA'

export interface ToggleTransportExtra {
  type: typeof TOGGLE_TRANSPORT_EXTRA
  payload: {
    type: 'assistants' | 'kmsExtra' | 'transport'
  }
}

export const SET_TRANSPORT_EXTRA_COUNT = 'DEPOSITS_VALIDATION/VALIDATION_VIEW/SET_TRANSPORT_EXTRA_COUNT'

export interface SetTransportExtraCount {
  type: typeof SET_TRANSPORT_EXTRA_COUNT
  payload: {
    type: 'assistants' | 'kmsExtra'
    count: number
  }
}

export const SET_TRANSPORT_EXTRA_PRICE = 'DEPOSITS_VALIDATION/VALIDATION_VIEW/SET_TRANSPORT_EXTRA_PRICE'

export interface SetTransportExtraPrice {
  type: typeof SET_TRANSPORT_EXTRA_PRICE
  payload: {
    type: 'transport'
    price: number
  }
}

export const TOGGLE_OBJECT_EXTRA = 'DEPOSITS_VALIDATION/VALIDATION_VIEW/TOGGLE_OBJECT_EXTRA'

export interface ToggleObjectExtra {
  type: typeof TOGGLE_OBJECT_EXTRA
  payload: {
    type: 'disassembly' | 'stairs' | 'packaging'
    objectId: number | 'all'
  }
}

export const SET_OBJECT_FLOORS = 'DEPOSITS_VALIDATION/VALIDATION_VIEW/SET_OBJECT_FLOORS'

export interface SetObjectFloors {
  type: typeof SET_OBJECT_FLOORS
  payload: {
    floors: number
    objectId: number | 'all'
  }
}

export const VALIDATE_OPERATION_REQUEST = 'DEPOSITS_VALIDATION/VALIDATION_VIEW/VALIDATE_OPERATION_REQUEST'
export const VALIDATE_OPERATION_SUCCESS = 'DEPOSITS_VALIDATION/VALIDATION_VIEW/VALIDATE_OPERATION_SUCCESS'
export const VALIDATE_OPERATION_FAILURE = 'DEPOSITS_VALIDATION/VALIDATION_VIEW/VALIDATE_OPERATION_FAILURE'

export interface ValidateOperationRequest {
  type: typeof VALIDATE_OPERATION_REQUEST
}

export interface ValidateOperationSuccess {
  type: typeof VALIDATE_OPERATION_SUCCESS
}

export interface ValidateOperationFailure {
  type: typeof VALIDATE_OPERATION_FAILURE
  payload: {
    error: string
  }
}

export type ValidateOperationAction = ValidateOperationRequest | ValidateOperationSuccess | ValidateOperationFailure

export const POPULATE_VIEW_REQUEST = 'DEPOSITS_VALIDATION/VALIDATION_VIEW/POPULATE_VIEW_REQUEST'
export const POPULATE_VIEW_SUCCESS = 'DEPOSITS_VALIDATION/VALIDATION_VIEW/POPULATE_VIEW_SUCCESS'
export const POPULATE_VIEW_FAILURE = 'DEPOSITS_VALIDATION/VALIDATION_VIEW/POPULATE_VIEW_FAILURE'

export interface PopulateViewRequest {
  type: typeof POPULATE_VIEW_REQUEST
}

export interface PopulateViewSuccess {
  type: typeof POPULATE_VIEW_SUCCESS
  payload: {
    fromRecalculation: boolean
    operationCountryCode: CountryIdCode
    objects: ValidationViewObject[]
    quotedTransportCost: number
    discountCode: string
    discountId: number | null | undefined
    baseInfo: {
      transactionId: number
      fullAddress: string
      id: number
      user: {
        id: number
        name: string
        lastName: string
      }
      createdAt: Moment | null
      datetime: Moment | null
      status: DepositStatus
    }
    extraKmPrice: number
    tollCostInCents: number
    extraAssistantPrice: number
    provisionalBilling: Billing | null
  }
}

export interface PopulateViewFailure {
  type: typeof POPULATE_VIEW_FAILURE
  payload: {
    error: string
  }
}

export type PopulateViewAction = PopulateViewRequest | PopulateViewSuccess | PopulateViewFailure

export const CLEAR_VALIDATION_VIEW_STATE = 'DEPOSITS_VALIDATION/VALIDATION_VIEW/CLEAR_VALIDATION_VIEW_STATE'

export interface ClearValidationViewState {
  type: typeof CLEAR_VALIDATION_VIEW_STATE
}

export const RECALCULATE_OPERATION_REQUEST = 'DEPOSITS_VALIDATION/VALIDATION_VIEW/RECALCULATE_OPERATION_REQUEST'
export const RECALCULATE_OPERATION_SUCCESS = 'DEPOSITS_VALIDATION/VALIDATION_VIEW/RECALCULATE_OPERATION_SUCCESS'
export const RECALCULATE_OPERATION_FAILURE = 'DEPOSITS_VALIDATION/VALIDATION_VIEW/RECALCULATE_OPERATION_FAILURE'

export interface RecalculateOperationRequest {
  type: typeof RECALCULATE_OPERATION_REQUEST
}

export interface RecalculateOperationSuccess {
  type: typeof RECALCULATE_OPERATION_SUCCESS
}

export interface RecalculateOperationFailure {
  type: typeof RECALCULATE_OPERATION_FAILURE
  payload: {
    error: string
  }
}

export const SET_FETCHED_RECALCULATION = 'DEPOSITS_VALIDATION/VALIDATION_VIEW/SET_FETCHED_RECALCULATION'

export interface SetFetchedRecalculation {
  type: typeof SET_FETCHED_RECALCULATION
  payload: {
    fetchedRecalculation: boolean
  }
}

export const GET_BILLING_BY_TRANSACTION_REQUEST =
  'DEPOSITS_VALIDATION/VALIDATION_VIEW/GET_BILLING_BY_TRANSACTION_REQUEST'
export const GET_BILLING_BY_TRANSACTION_SUCCESS =
  'DEPOSITS_VALIDATION/VALIDATION_VIEW/GET_BILLING_BY_TRANSACTION_SUCCESS'
export const GET_BILLING_BY_TRANSACTION_FAILURE =
  'DEPOSITS_VALIDATION/VALIDATION_VIEW/GET_BILLING_BY_TRANSACTION_FAILURE'

export interface GetBillingByTransactionRequest {
  type: typeof GET_BILLING_BY_TRANSACTION_REQUEST
}

export interface GetBillingByTransactionSuccess {
  type: typeof GET_BILLING_BY_TRANSACTION_SUCCESS
  payload: {
    provisionalBilling: Billing | null
  }
}

export interface GetBillingByTransactionFailure {
  type: typeof GET_BILLING_BY_TRANSACTION_FAILURE
  payload: {
    error: string
  }
}

export const GET_DEPOSIT_OPERATION_BY_ID_REQUEST =
  'DEPOSITS_VALIDATION/VALIDATION_VIEW/GET_DEPOSIT_OPERATION_BY_ID_REQUEST'
export const GET_DEPOSIT_OPERATION_BY_ID_SUCCESS =
  'DEPOSITS_VALIDATION/VALIDATION_VIEW/GET_DEPOSIT_OPERATION_BY_ID_SUCCESS'
export const GET_DEPOSIT_OPERATION_BY_ID_FAILURE =
  'DEPOSITS_VALIDATION/VALIDATION_VIEW/GET_DEPOSIT_OPERATION_BY_ID_FAILURE'

export interface GetDepositOperationByIdRequest {
  type: typeof GET_DEPOSIT_OPERATION_BY_ID_REQUEST
}

export interface GetDepositOperationByIdSuccess {
  type: typeof GET_DEPOSIT_OPERATION_BY_ID_SUCCESS
  payload: {
    objects: ValidationViewObject[]
    discountCode: string
  }
}

export interface GetDepositOperationByIdFailure {
  type: typeof GET_DEPOSIT_OPERATION_BY_ID_FAILURE
  payload: {
    error: string
  }
}

export enum UpdateDiscountTypes {
  UPDATE_DISCOUNT_REQUEST = 'DEPOSITS_VALIDATION/VALIDATION_VIEW/UPDATE_DISCOUNT_REQUEST',
  UPDATE_DISCOUNT_SUCCESS = 'DEPOSITS_VALIDATION/VALIDATION_VIEW/UPDATE_DISCOUNT_SUCCESS',
  UPDATE_DISCOUNT_FAILURE = 'DEPOSITS_VALIDATION/VALIDATION_VIEW/UPDATE_DISCOUNT_FAILURE'
}

export interface UpdateDiscountRequest {
  type: typeof UpdateDiscountTypes.UPDATE_DISCOUNT_REQUEST
}

export interface UpdateDiscountSuccess {
  type: typeof UpdateDiscountTypes.UPDATE_DISCOUNT_SUCCESS
}

export interface UpdateDiscountFailure {
  type: typeof UpdateDiscountTypes.UPDATE_DISCOUNT_FAILURE
}

type UpdateDiscountActions = UpdateDiscountRequest | UpdateDiscountSuccess | UpdateDiscountFailure

export enum SetDiscountTypes {
  SET_APPLY_DISCOUNT = 'DEPOSITS_VALIDATION/VALIDATION_VIEW/SET_APPLY_DISCOUNT',
  SET_DISCOUNT_CODE = 'DEPOSITS_VALIDATION/VALIDATION_VIEW/SET_DISCOUNT_CODE',
  HANDLE_CLEAR_DISCOUNT = 'DEPOSITS_VALIDATION/VALIDATION_VIEW/HANDLE_CLEAR',
  SET_OPEN_MODAL = 'DEPOSITS_VALIDATION/VALIDATION_VIEW/SET_OPEN_MODAL'
}
export interface SetOpenModalChangeDiscount {
  type: typeof SetDiscountTypes.SET_OPEN_MODAL
  payload: {
    open: boolean
  }
}
export interface SetDiscountCode {
  type: typeof SetDiscountTypes.SET_DISCOUNT_CODE
  payload: { updateDiscountCode: string }
}

export interface HandleClearDiscount {
  type: typeof SetDiscountTypes.HANDLE_CLEAR_DISCOUNT
}

export type SetDiscountActions = SetDiscountCode | HandleClearDiscount | SetOpenModalChangeDiscount

export enum FetchDiscountTypes {
  FETCH_DISCOUNT_REQUEST = 'DEPOSITS_VALIDATION/VALIDATION_VIEW/FETCH_DISCOUNT_REQUEST',
  FETCH_DISCOUNT_SUCCESS = 'DEPOSITS_VALIDATION/VALIDATION_VIEW/FETCH_DISCOUNT_SUCCESS',
  FETCH_DISCOUNT_FAILURE = 'DEPOSITS_VALIDATION/VALIDATION_VIEW/FETCH_DISCOUNT_FAILURE'
}

export interface FetchDiscountRequest {
  type: typeof FetchDiscountTypes.FETCH_DISCOUNT_REQUEST
}

export interface FetchDiscountSuccess {
  type: typeof FetchDiscountTypes.FETCH_DISCOUNT_SUCCESS
  payload: {
    discountId: number
  }
}

export interface FetchDiscountFailure {
  type: typeof FetchDiscountTypes.FETCH_DISCOUNT_FAILURE
}

export type FetchDiscountActions = FetchDiscountRequest | FetchDiscountSuccess | FetchDiscountFailure

export enum ValidateDiscountTypes {
  VALIDATE_DISCOUNT_REQUEST = 'DEPOSITS_VALIDATION/VALIDATION_VIEW/VALIDATE_DISCOUNT_REQUEST',
  VALIDATE_DISCOUNT_SUCCESS = 'DEPOSITS_VALIDATION/VALIDATION_VIEW/VALIDATE_DISCOUNT_SUCCESS',
  VALIDATE_DISCOUNT_FAILURE = 'DEPOSITS_VALIDATION/VALIDATION_VIEW/VALIDATE_DISCOUNT_FAILURE'
}

export interface ValidateDiscountRequest {
  type: typeof ValidateDiscountTypes.VALIDATE_DISCOUNT_REQUEST
}

export interface ValidateDiscountSuccess {
  type: typeof ValidateDiscountTypes.VALIDATE_DISCOUNT_SUCCESS
  payload: {
    isValid: boolean
  }
}

export interface ValidateDiscountFailure {
  type: typeof ValidateDiscountTypes.VALIDATE_DISCOUNT_FAILURE
}

export type ValidateDiscountActions = ValidateDiscountRequest | ValidateDiscountSuccess | ValidateDiscountFailure

export type ValidationViewAction =
  | PopulateViewAction
  | ValidateOperationAction
  | ToggleTransportExtra
  | SetTransportExtraCount
  | SetTransportExtraPrice
  | SetFetchedRecalculation
  | ToggleObjectExtra
  | SetObjectFloors
  | ClearValidationViewState
  | RecalculateOperationRequest
  | RecalculateOperationSuccess
  | RecalculateOperationFailure
  | GetBillingByTransactionRequest
  | GetBillingByTransactionSuccess
  | GetBillingByTransactionFailure
  | GetDepositOperationByIdRequest
  | GetDepositOperationByIdSuccess
  | GetDepositOperationByIdFailure
  | UpdateDiscountActions
  | SetDiscountActions
  | FetchDiscountActions
  | ValidateDiscountActions
