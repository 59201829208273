import { API } from '@/projectApi'
import {
  DiscountAssignmentsSearchFilter,
  GetVolumeDiscountAssignmentsRequest,
  GetVolumeDiscountAssignmentsSuccess,
  GetVolumeDiscountAssignmentsTypes
} from '@/sections/Discounts/types/volume/assignments/table'
import { AppThunk } from '@/store'
import { handleErrorsWithAction } from '@/utils/HandleErrors'
import { Pagination, searchFiltersToParams } from '@/utils/searchFilterUtils'

const volumeDiscountAssignmentsTableActionsCreator = {
  getDiscounts({
    pagination,
    searchFilters
  }: {
    pagination: Pagination
    searchFilters?: DiscountAssignmentsSearchFilter[]
  }): AppThunk {
    return async (dispatch) => {
      const request: GetVolumeDiscountAssignmentsRequest = {
        type: GetVolumeDiscountAssignmentsTypes.GET_VOLUME_DISCOUNT_ASSIGNMENTS_REQUEST,
        payload: {
          pageSize: pagination.pageSize,
          newPage: pagination.page,
          searchFilters
        }
      }
      dispatch(request)

      try {
        const { volumeDiscountAssignments, total } =
          await API.TransactionHandler.Discounts.VolumeDiscounts.assignments.list({
            limit: pagination.pageSize,
            offset: (pagination.page - 1) * pagination.pageSize,
            ...searchFiltersToParams(searchFilters)
          })
        const success: GetVolumeDiscountAssignmentsSuccess = {
          type: GetVolumeDiscountAssignmentsTypes.GET_VOLUME_DISCOUNT_ASSIGNMENTS_SUCCESS,
          payload: {
            volumeDiscountAssignments,
            total
          }
        }

        dispatch(success)
      } catch (error) {
        handleErrorsWithAction(
          error,
          GetVolumeDiscountAssignmentsTypes.GET_VOLUME_DISCOUNT_ASSIGNMENTS_FAILURE,
          dispatch
        )
      }
    }
  }
}

export default volumeDiscountAssignmentsTableActionsCreator
