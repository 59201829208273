import { AxiosResponse } from 'axios'
import { apiPrivate } from './../../api'
import { URL_BASE_SALES } from '../../endpoints'
interface Response {
  content: Content
}

export interface Content {
  total: number
  reasons: Reason[]
}

export type Reason = {
  id: number
  description: string
}

type ReasonsParams = {
  count: boolean
  entity: 'DONATION_REQUEST'
  action: 'CANCEL'
}

export function reasonsV2(params: ReasonsParams): Promise<Response> {
  return apiPrivate.get(`${URL_BASE_SALES}/reasons`, { params }).then((response: AxiosResponse<Response>) => {
    if (!response.data.content.reasons) throw new Error('Hubo un error al obtener las razones')
    return response.data
  })
}
