import React from 'react'
import VolumeDiscountAssignmentsTableContainer from './TableContainer'
import { TableActionBarContainer } from './TableActionBar'
import VolumeDiscountPoliciesModalsContainer from './Modals'

const VolumeDiscountsPage = () => {
  return (
    <>
      <TableActionBarContainer />
      <VolumeDiscountAssignmentsTableContainer />
      <VolumeDiscountPoliciesModalsContainer />
    </>
  )
}

export default VolumeDiscountsPage
