import { Reducer } from 'redux'
import {
  CancelDonationModalActions,
  CancelDonationModalStateType,
  CommonTypes,
  CancelDonationModalTypes,
  ReasonCancelDonationTypes
} from '../types/cancelDonationModal'

const initialState: CancelDonationModalStateType = {
  id: '',
  name: '',
  lastName: '',
  isOpen: false,
  isLoading: false,
  reasons: [],
  error: ''
}

const CancelDonationModalReducer: Reducer<CancelDonationModalStateType, CancelDonationModalActions> = (
  state: CancelDonationModalStateType = initialState,
  action: CancelDonationModalActions
): CancelDonationModalStateType => {
  switch (action.type) {
    case CommonTypes.CLEAN_STATE:
      return initialState
    case CommonTypes.SET_OPEN:
      return {
        ...state,
        isOpen: action.payload.isOpen,
        name: action.payload.name || '',
        lastName: action.payload.lastName || '',
        id: action.payload.id || ''
      }
    case CancelDonationModalTypes.CANCEL_DONATION_REQUEST:
      return {
        ...state,
        isLoading: true
      }
    case CancelDonationModalTypes.CANCEL_DONATION_SUCCESS:
      return {
        ...state,
        isLoading: false
      }
    case CancelDonationModalTypes.CANCEL_DONATION_FAILURE:
      return {
        ...state,
        isLoading: false,
        error: action.payload.error
      }
    case ReasonCancelDonationTypes.REASONS_CANCEL_DONATION_REQUEST:
      return {
        ...state,
        isLoading: true
      }
    case ReasonCancelDonationTypes.REASONS_CANCEL_DONATION_SUCCESS:
      return {
        ...state,
        isLoading: false,
        reasons: action.payload.reasons
      }
    case ReasonCancelDonationTypes.REASONS_CANCEL_DONATION_FAILURE:
      return {
        ...state,
        isLoading: false,
        error: action.payload.error
      }
    default:
      return state
  }
}

export default CancelDonationModalReducer
