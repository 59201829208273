import React from 'react'
import PolicyItemModal, { PolicyItemModalProps } from '@/sections/Discounts/components/volume/policies/ItemsModal'
import { useDispatch, useSelector } from 'react-redux'
import { bindActionCreators } from 'redux'
import policyItemsActionsCreator from '@/sections/Discounts/actions/volume/policies/items'
import { PolicyItem } from '@/sections/Discounts/types/volume/policies/items'
import volumeDiscountPoliciesTableActionsCreator from '@/sections/Discounts/actions/volume/policies/table'

const PolicyItemModalContainer = () => {
  const tableState = useSelector((state) => state.Discount.volumeDiscountPoliciesTableReducer)
  const { modalItemVisible, currentPolicyItem, currentPolicyId, creatingPolicyItem } = useSelector(
    (state) => state.Discount.policyItemsReducer
  )
  const dispatch = useDispatch()
  const { setModalItemVisibility, setMinRangeValue, setDiscountPercentageValue, createPolicyItem } = bindActionCreators(
    policyItemsActionsCreator,
    dispatch
  )

  const { getDiscounts } = bindActionCreators(volumeDiscountPoliciesTableActionsCreator, dispatch)

  const handleConfirmPolicyItem = (policy: PolicyItem) => {
    const { minRange, discountPercentage } = policy

    if (!minRange || !discountPercentage || !currentPolicyId) return

    const refetchTable = () =>
      getDiscounts({
        pagination: tableState.pagination
      })

    createPolicyItem({ m3Min: minRange, discountPercentage, policyId: currentPolicyId }, refetchTable)
  }

  const disabledActionButton = creatingPolicyItem

  const modalParams: PolicyItemModalProps = {
    modalItemVisible,
    disabledActionButton,
    currentPolicyItem,
    setModalItemVisibility,
    setMinRangeValue,
    setDiscountPercentageValue,
    handleConfirmPolicyItem
  }

  return modalItemVisible ? <PolicyItemModal {...modalParams} /> : null
}

export default PolicyItemModalContainer
