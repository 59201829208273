import { apiPrivate } from '@/api'
import { URL_BASE_TRANSACTIONS } from '@/endpoints'
import { APIResponseGetAndUpdate } from './common'

export interface Params {
  policyId: number
  active: boolean
}

export function update({ policyId, ...params }: Params): Promise<void> {
  return apiPrivate
    .put<APIResponseGetAndUpdate>(`${URL_BASE_TRANSACTIONS}/volume-discount-policies/${policyId}`, params)
    .then(() => {})
    .catch((error) => {
      throw error
    })
}
