import { Reducer } from 'redux'
import {
  CreatePolicyItemType,
  ModalPolicyItemTypes,
  PolicyItemActions,
  PolicyItemState
} from '@/sections/Discounts/types/volume/policies/items'

const initialState: PolicyItemState = {
  modalItemVisible: false,
  creatingPolicyItem: false,
  currentPolicyItem: {}
}

const policyItemsReducer: Reducer<PolicyItemState, PolicyItemActions> = (state = initialState, action) => {
  switch (action.type) {
    case ModalPolicyItemTypes.SET_MODAL_ITEM_VISIBILITY: {
      const { modalItemVisible } = action.payload

      return modalItemVisible
        ? {
            ...state,
            modalItemVisible
          }
        : initialState
    }
    case ModalPolicyItemTypes.SET_MIN_RANGE_VALUE:
      return {
        ...state,
        currentPolicyItem: {
          ...state.currentPolicyItem,
          minRange: action.payload.minRange
        }
      }
    case ModalPolicyItemTypes.SET_DISCOUNT_PERCENTAGE_VALUE:
      return {
        ...state,
        currentPolicyItem: {
          ...state.currentPolicyItem,
          discountPercentage: action.payload.discountPercentage
        }
      }
    case ModalPolicyItemTypes.SET_CURRENT_POLICY_ID:
      return {
        ...state,
        currentPolicyId: action.payload.currentPolicyId
      }
    case CreatePolicyItemType.CREATE_POLICY_ITEM_REQUEST:
      return {
        ...state,
        creatingPolicyItem: true
      }
    case CreatePolicyItemType.CREATE_POLICY_ITEM_SUCCESS:
      return initialState
    case CreatePolicyItemType.CREATE_POLICY_ITEM_FAILURE:
      return {
        ...state,
        creatingPolicyItem: false
      }
    default:
      return state
  }
}

export default policyItemsReducer
