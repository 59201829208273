import React from 'react'
import FlagCountry from '@/components/CountrySelector/component/FlagCountry'
import ClientIDLinkComponent from '@/components/ClientIDLink/ClientIDLink'
import { useSelector } from 'react-redux'
import { MenuAction } from '@/components/actionMenu/baseMenu'
import { getCurrentCountry } from '@/components/CountrySelector/utils/utils'
import { DynamicTable } from '@/components/DynamicTable'
import { Column, Pagination } from '@/components/DynamicTable/types/types'
import { getSearchProps } from '@/components/DynamicTable/utils'
import { useLocalization } from '@/packages/localization'
import { VolumeDiscountAssignment } from '@/projectApi/TransactionHandler/Discounts/volume/assignments/common'
import {
  DiscountAssignmentsSearchFilter,
  DiscountAssignmentsSearchKey
} from '@/sections/Discounts/types/volume/assignments/table'

export type VolumeDiscountsAssignmentsTableProps = {
  volumeDiscountAssignments: VolumeDiscountAssignment[]
  fetchingDiscounts: boolean
  pagination: Pagination
  searchFilters: DiscountAssignmentsSearchFilter[]
  actions: MenuAction<VolumeDiscountAssignment>[]
  handleSearch: (key: DiscountAssignmentsSearchKey, newValue: string) => void
}

export const VolumeDiscountsAssignmentsTable: React.FC<VolumeDiscountsAssignmentsTableProps> = ({
  volumeDiscountAssignments,
  fetchingDiscounts,
  pagination,
  searchFilters,
  actions,
  handleSearch
}) => {
  const { strings } = useLocalization()
  const { countries } = useSelector((state) => state.CountrySelector)

  const columns: Column<VolumeDiscountAssignment>[] = [
    {
      key: 'assignedUser.countryCode',
      label: strings.generic.country,
      renderDataCell: ({ assignedUser }) => {
        const flag = getCurrentCountry(countries, assignedUser.countryCode, 'fullName')

        if (!flag) return null

        return <FlagCountry country={flag} />
      }
    },
    {
      key: 'id',
      label: strings.generic.id,
      search: getSearchProps('id', strings.generic.id, handleSearch, searchFilters)
    },
    {
      key: 'assignedUser',
      label: strings.generic.client,
      search: getSearchProps('userId', strings.generic.searchs.clientId, handleSearch, searchFilters),
      renderDataCell: ({ assignedUser }) => {
        const userFullName = `${assignedUser.name} ${assignedUser.lastName}`

        return (
          <ClientIDLinkComponent id={assignedUser.id} noPadding>
            {assignedUser.id} - {userFullName}
          </ClientIDLinkComponent>
        )
      }
    },
    {
      key: 'assignedUser.email',
      label: strings.generic.email,
      renderDataCell: ({ assignedUser }) => assignedUser.email ?? '-'
    },
    {
      key: 'discountValues.volumeDiscountPolicy.name',
      label: strings.Discounts.Volume.Assignments.table.discountDescription,
      search: getSearchProps('policyId', strings.generic.id, handleSearch, searchFilters),
      renderDataCell: ({ discountValues }) => {
        const policy = discountValues.volumeDiscountPolicy

        if (!policy.id && !policy.name) return '-'

        return `${policy.id} - ${policy.name}`
      }
    },
    {
      key: 'discountValues.volumeDiscountPolicy.durationInDays',
      label: strings.Discounts.Volume.Policies.table.durationInDays,
      renderDataCell: ({ discountValues }) => discountValues.volumeDiscountPolicy.durationInDays ?? '-'
    },
    {
      key: 'discountValues.volumeDiscountPolicy.active',
      label: strings.generic.status,
      renderDataCell: ({ active }) => {
        return active ? strings.generic.active : strings.generic.inactive
      }
    }
  ]

  return (
    <DynamicTable
      columns={columns}
      loading={fetchingDiscounts}
      rows={volumeDiscountAssignments}
      keyExtractor={(entity) => entity.id}
      pagination={pagination}
      actions={actions}
    />
  )
}
