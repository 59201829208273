import { create } from './create'
import { metrics } from './metrics'
import { update } from './update'
import { updateDiscount } from './updateDiscount'

export const DepositAPI = {
  create,
  edit: update,
  metrics,
  updateDiscount
}
