import { CountryIdCode } from '../../../components/CountrySelector/constants/constants'

export type ResetPasswordModalState = {
  open: boolean
  userData: {
    id: string
    country: CountryIdCode
    name: string
    lastName: string
    email: string
  }
  error: string
  loading: boolean
}

export enum OpenResetPasswordModalTypes {
  OPEN_RESET_PASSWORD_MODAL = 'USERS/RESET_PASSWORD/OPEN_RESET_PASSWORD_MODAL'
}

export interface OpenResetPasswordModal {
  type: typeof OpenResetPasswordModalTypes.OPEN_RESET_PASSWORD_MODAL
  payload: {
    open: boolean
    userData?: {
      id: string
      name: string
      country: CountryIdCode
      lastName: string
      email: string
    }
  }
}

export enum ResetPasswordTypes {
  RESET_PASSWORD_REQUEST = 'USERS/RESET_PASSWORD/RESET_PASSWORD_REQUEST',
  RESET_PASSWORD_SUCCESS = 'USERS/RESET_PASSWORD/RESET_PASSWORD_SUCCESS',
  RESET_PASSWORD_FAILURE = 'USERS/RESET_PASSWORD/RESET_PASSWORD_FAILURE'
}

export interface ResetPasswordRequest {
  type: typeof ResetPasswordTypes.RESET_PASSWORD_REQUEST
}

export interface ResetPasswordSuccess {
  type: typeof ResetPasswordTypes.RESET_PASSWORD_SUCCESS
}

export interface ResetPasswordFailure {
  type: typeof ResetPasswordTypes.RESET_PASSWORD_FAILURE
  payload: {
    error: string
  }
}

export type resetPasswordTypesActions = ResetPasswordRequest | ResetPasswordSuccess | ResetPasswordFailure

export type ResetPasswordActions = OpenResetPasswordModal | resetPasswordTypesActions
