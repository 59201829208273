import {
  GetVolumeDiscountAssignmentsTypes,
  VolumeDiscountAssignmentsActions,
  VolumeDiscountAssignmentsState
} from '@/sections/Discounts/types/volume/assignments/table'
import { Reducer } from 'redux'

export const initialStateAssignments: VolumeDiscountAssignmentsState = {
  volumeDiscountAssignments: [],
  fetchingDiscounts: false,
  pagination: {
    pageSize: 10,
    page: 1,
    total: 0
  },
  searchFilters: [
    { key: 'id', text: '' },
    { key: 'userId', text: '' },
    { key: 'policyId', text: '' }
  ]
}

const volumeDiscountAssignmentsTableReducer: Reducer<
  VolumeDiscountAssignmentsState,
  VolumeDiscountAssignmentsActions
> = (state = initialStateAssignments, action): VolumeDiscountAssignmentsState => {
  switch (action.type) {
    case GetVolumeDiscountAssignmentsTypes.GET_VOLUME_DISCOUNT_ASSIGNMENTS_REQUEST:
      return {
        ...state,
        fetchingDiscounts: true,
        pagination: {
          ...state.pagination,
          page: action.payload.newPage,
          pageSize: action.payload.pageSize
        },
        searchFilters: action.payload.searchFilters || state.searchFilters
      }
    case GetVolumeDiscountAssignmentsTypes.GET_VOLUME_DISCOUNT_ASSIGNMENTS_SUCCESS:
      return {
        ...state,
        fetchingDiscounts: false,
        volumeDiscountAssignments: action.payload.volumeDiscountAssignments,
        pagination: {
          ...state.pagination,
          total: action.payload.total
        }
      }
    case GetVolumeDiscountAssignmentsTypes.GET_VOLUME_DISCOUNT_ASSIGNMENTS_FAILURE:
      return {
        ...state,
        fetchingDiscounts: false
      }
    default:
      return state
  }
}

export default volumeDiscountAssignmentsTableReducer
