import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { bindActionCreators } from 'redux'
import actions from '../actions/resetPassword'
import ResetPasswordModal, { ResetPasswordModalProps } from '../components/resetPasswordModal'

const Container = () => {
  const dispatch = useDispatch()
  const resetPasswordModalActions = bindActionCreators(actions, dispatch)
  const { resetPassword, setOpen } = resetPasswordModalActions

  const { open, userData, loading } = useSelector((state) => state.Users.resetPassword)

  const handleConfirm = () => {
    const { id } = userData
    resetPassword(id)
  }

  const handleCloseModal = () => {
    setOpen(false)
  }

  const props: ResetPasswordModalProps = {
    open,
    userData,
    loading,
    handleCloseModal,
    handleConfirm
  }

  return <ResetPasswordModal {...props} />
}

export default Container
