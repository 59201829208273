import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { bindActionCreators } from 'redux'
import CancelDonationModal from '../components/cancelDonationModal'
import CancelDonationModalActionCreator from '../actions/cancelDonationModal'

const Container = (): React.ReactElement => {
  const dispatch = useDispatch()
  const { CancelDonation, cleanState, setOpenModal, getReasons } = bindActionCreators(
    CancelDonationModalActionCreator,
    dispatch
  )
  const cancelDonationState = useSelector((state) => state.DonationsReducer.cancel)

  useEffect(() => {
    getReasons()
  }, [cancelDonationState.id])

  const handleCancelDonationRequest = (id: number, reason: { id: number; text: string }) => {
    if (id && reason) {
      CancelDonation(Number(cancelDonationState.id), reason)
    }
  }

  const handleCloseModal = () => {
    cleanState()
    setOpenModal(false)
  }

  const cancelDonationModalProps = {
    closeModal: handleCloseModal,
    cancelDonationRequestHandler: handleCancelDonationRequest,
    cancelDonationState
  }

  return <CancelDonationModal {...cancelDonationModalProps} />
}

export default Container
