import { apiPrivate } from '@/api'
import { URL_BASE_TRANSACTIONS } from '@/endpoints'

export type UpdateDiscountParams = {
  operationId: number
  discountId?: number | null
}

export async function updateDiscount({ operationId, discountId }: UpdateDiscountParams): Promise<void> {
  const formattedBody = {
    discount_id: discountId
  }

  return apiPrivate
    .put(`${URL_BASE_TRANSACTIONS}/operations/${operationId}/update-discount`, formattedBody)
    .then(() => {})
    .catch((err) => {
      throw err
    })
}
