import React, { useEffect } from 'react'
import { VolumeDiscountsAssignmentsTable } from '../../../components/volume/assignments/Table'
import { bindActionCreators } from 'redux'
import { useDispatch, useSelector } from 'react-redux'
import volumeDiscountAssignmentsTableActionsCreator from '../../../actions/volume/assignments/table'
import { Pagination, searchFiltersToParams } from '@/utils/searchFilterUtils'
import { MenuAction } from '@/components/actionMenu/baseMenu'
import { VolumeDiscountAssignment } from '@/projectApi/TransactionHandler/Discounts/volume/assignments/common'
import { Icon } from 'antd'
import { useLocalization } from '@/packages/localization'
import { NumberParam, StringParam, useQueryParams } from 'use-query-params'
import {
  DiscountAssignmentsSearchFilter,
  DiscountAssignmentsSearchKey
} from '@/sections/Discounts/types/volume/assignments/table'
import { removeNullishValues, updateMultipleSearchFilters } from '@/components/DynamicTable/queryParamsUtils'
import volumeDiscountAssignmentsModalsActionsCreator from '@/sections/Discounts/actions/volume/assignments/modals'

const queryParams = {
  id: StringParam,
  userId: StringParam,
  policyId: StringParam,
  page: NumberParam
}

const VolumeDiscountAssignmentsTableContainer = () => {
  const { strings } = useLocalization()

  const tableState = useSelector((state) => state.Discount.volumeDiscountAssignmentsTableReducer)
  const [query, setQuery] = useQueryParams(queryParams)

  const dispatch = useDispatch()
  const { getDiscounts } = bindActionCreators(volumeDiscountAssignmentsTableActionsCreator, dispatch)
  const { setActivationModalVisibility, setCurrentAssignment } = bindActionCreators(
    volumeDiscountAssignmentsModalsActionsCreator,
    dispatch
  )

  const emptyPagination = { ...tableState.pagination, page: 1 }

  const handleGetVolumeDiscounts = (params: {
    pagination?: Pagination
    searchFilters?: DiscountAssignmentsSearchFilter[]
  }) => {
    const newParams = {
      pagination: params.pagination || tableState.pagination,
      searchFilters: params.searchFilters || tableState.searchFilters
    }

    getDiscounts(newParams)
    setQuery(
      removeNullishValues({
        ...searchFiltersToParams(newParams.searchFilters, true)
      }),
      'push'
    )
  }

  useEffect(() => {
    handleGetVolumeDiscounts({
      pagination: {
        ...emptyPagination,
        page: query.page || emptyPagination.page
      },
      searchFilters: updateMultipleSearchFilters(tableState.searchFilters, [
        { key: 'id', text: query.id ?? '' },
        { key: 'userId', text: query.userId ?? '' },
        { key: 'policyId', text: query.policyId ?? '' }
      ])
    })
  }, [])

  const handlePageChange = (newPage: number) => {
    handleGetVolumeDiscounts({ pagination: { ...tableState.pagination, page: newPage } })
  }

  const handleSearch = (key: DiscountAssignmentsSearchKey, newValue: string) => {
    const newSearchFilters = tableState.searchFilters.map((filter) =>
      filter.key === key
        ? {
            ...filter,
            text: newValue
          }
        : filter
    )
    handleGetVolumeDiscounts({ searchFilters: newSearchFilters, pagination: emptyPagination })
  }

  const menuActions: MenuAction<VolumeDiscountAssignment>[] = [
    {
      label: (assignment) => (assignment?.active ? strings.generic.deactivate : strings.generic.activate),
      icon: (assignment) => <Icon type={assignment?.active ? 'stop' : 'check'} />,
      onClick: (assignment) => {
        setCurrentAssignment({
          currentAssignment: {
            id: assignment.id,
            name: assignment.discountValues.volumeDiscountPolicy.name,
            active: assignment.active,
            usuario: {
              id: assignment.assignedUser.id,
              fullName: `${assignment.assignedUser.name} ${assignment.assignedUser.lastName}`
            }
          }
        })
        setActivationModalVisibility({ activationModalVisible: true })
      }
    }
  ]

  const tableProps = {
    volumeDiscountAssignments: tableState.volumeDiscountAssignments,
    fetchingDiscounts: tableState.fetchingDiscounts,
    pagination: {
      currentPage: tableState.pagination.page,
      pageSize: tableState.pagination.pageSize,
      total: tableState.pagination.total,
      onPageChange: handlePageChange
    },
    searchFilters: tableState.searchFilters,
    actions: menuActions,
    handleSearch
  }

  return <VolumeDiscountsAssignmentsTable {...tableProps} />
}

export default VolumeDiscountAssignmentsTableContainer
