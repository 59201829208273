import React from 'react'
import { Modal } from 'antd'
import { Policy } from '@/sections/Discounts/types/volume/policies/modals'
import { useLocalization } from '@/packages/localization'

interface ActivationModalProps {
  currentPolicy: Policy
  modalVisible: boolean
  disabledUpdateButton: boolean
  setModalVisibility: ({ modalVisible }: { modalVisible: boolean }) => void
  handleUpdatePolicy: () => void
}

const ActivationModal: React.FC<ActivationModalProps> = ({
  currentPolicy,
  modalVisible,
  disabledUpdateButton,
  setModalVisibility,
  handleUpdatePolicy
}) => {
  const { strings } = useLocalization()

  const genericStrings = strings.generic

  const title = `${currentPolicy.active ? genericStrings.deactivate : genericStrings.activate} "${currentPolicy.name}"`
  const okText = currentPolicy.active ? genericStrings.deactivate : genericStrings.activate
  const toStateMessage = currentPolicy.active
    ? genericStrings.deactivate.toLowerCase()
    : genericStrings.activate.toLowerCase()

  return (
    <Modal
      title={title}
      okText={okText}
      okButtonProps={{ disabled: disabledUpdateButton }}
      onOk={handleUpdatePolicy}
      cancelText={genericStrings.cancel}
      visible={modalVisible}
      onCancel={() => setModalVisibility({ modalVisible: false })}>
      <p>{strings.Discounts.Volume.Policies.modal.activationMessage(toStateMessage)}</p>
    </Modal>
  )
}

export default ActivationModal
