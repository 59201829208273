import React, { useEffect } from 'react'
import { Modal } from 'antd'
import styles from './DonationModal.module.scss'
import ColInfoContainer from '@/components/ColInfoContainer/ColInfoContainer'
import ColInfo from '@/components/ColInfo/ColInfo'
import Select from '../../../components/Select'
import { BaseOption } from '@/components/Select/Select'
import { CancelDonationModalStateType } from '../types/cancelDonationModal'

type CancelDonationModalProps = {
  closeModal: () => void
  cancelDonationRequestHandler: (id: number, reason: { id: number; text: string }) => void
  cancelDonationState: CancelDonationModalStateType
}

const CancelDonationModal = (props: CancelDonationModalProps) => {
  const { closeModal, cancelDonationRequestHandler, cancelDonationState } = props
  const { error, id, isLoading, isOpen, lastName, name } = cancelDonationState
  const [reason, setReason] = React.useState<BaseOption<number> | null>(null)

  const reasonOptions: BaseOption<number>[] = cancelDonationState.reasons?.map((reason) => ({
    value: reason.id,
    label: reason.description
  }))

  useEffect(() => {
    setReason(null)
  }, [isOpen])

  const onOkClick = () => {
    if (reason) cancelDonationRequestHandler(Number(id), { id: reason.value, text: reason.label })
  }

  const FullNameInfo = {
    label: 'Nombre completo:',
    value: name + ' ' + lastName
  }

  const IdInfo = {
    label: 'ID:',
    value: id
  }

  return (
    <Modal
      width={697}
      title="Cancelar solicitud Donación"
      visible={isOpen}
      closable={true}
      keyboard={false}
      onOk={onOkClick}
      onCancel={closeModal}
      okButtonProps={{
        className: styles.modalButtonOk,
        disabled: isLoading || !reason
      }}
      okText={'Confirmar'}
      cancelButtonProps={{
        className: styles.modalButtonCancel,
        disabled: isLoading
      }}
      cancelText={'Cancelar'}
      className={styles.modal}>
      <ColInfoContainer blue>
        <ColInfo {...IdInfo} blue />
        <ColInfo {...FullNameInfo} blue />
        <div className={styles.containerModal}>
          <p className={styles.itemTitle}>Buscar un usuario por id:</p>
          <Select
            onSelect={(value) => setReason(value)}
            options={reasonOptions}
            selected={reason}
            placeholder="Ingrese la razón de la cancelación"
            className={styles.select}
            loading={false}
            disabled={isLoading}
          />
        </div>
      </ColInfoContainer>
      {error && (
        <div className={styles.errorContainer}>
          <span className={styles.error}>{error}</span>
        </div>
      )}
    </Modal>
  )
}

export default CancelDonationModal
